import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersSummaryByGrades from './components/OrdersSummaryByGrades';

const ordersSummaryByGrades = (props,{ t }) => (
  <Container>
    <OrdersSummaryByGrades {...props} />
  </Container>
);

ordersSummaryByGrades.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ordersSummaryByGrades);
