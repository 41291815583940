import React, { useEffect } from "react";
import { RadioButton } from 'primereact/radiobutton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoRadioButtons = (props) => {
    const id = props.id || props.name;
    const field = props.field || {};
    const value = props.defVal;
    const options = props.options || [];

    const markRequired = () => (
        <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
        />
    );

    useEffect(() => {
        onChange({ value: field.value });
    }, [field.value]);

    const onChange = (e) => {
        if (props.screenName === 'Schools' && props.item && props.item.dependentTextField && props.item.name === 'hasFoundation') {
            props.handleTexfields(e, props.item);
        }
    };
    return (
        <div className="flex flex-column">
            <div className="flex align-center">
                <label htmlFor={id} className="text-capitalize">{props.type === 'Setting' ? "" : props.name}</label>
                {props.markReq && markRequired()}
            </div>
            <div className="flex align-items-center">
                {options && options.length > 0 ? options.map((option) => (
                    <div key={option.name}>
                        <RadioButton
                            {...field}
                            inputId={option.label}
                            name={props.name}
                            value={option.value}
                            checked={field.value === option.value}
                            onChange={field.onChange}

                        />
                        <label htmlFor={option.label} className="ms-1 me-4">
                            {option.label}
                        </label>
                    </div>
                )) : <>
                    <RadioButton {...field} inputRef={field.ref} value={props.value} checked={field.value === value} />
                    <label className="ms-1 me-4">
                        {props.label}
                    </label>
                </>}

            </div>
            <small className="text-danger" style={{ marginTop: "5px" }}>
                {props.fieldState?.invalid ? props.errors[props.name]?.message : ''}
            </small>
        </div>
    );
};

export default DoRadioButtons;
