import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Orders = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Orders");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "order_id",
        type: "text",
        placeholder: "Email",
        label: "order_id",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Order ID",
        derivedValue: "order_id=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "order_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "order_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_name",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "School Name",
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "school_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        capitalizeTableText: true,
        tDisplay: true,
      },
      {
        name: "child_name",
        type: "text",
        placeholder: "Student Name",
        label: "Student Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Student Name",
        derivedValue: "child_name=undefined",
        sortable: true,
        filter: false,
        id: "child_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "child_name",
        capitalizeTableText: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "userEmail",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Email",
        derivedValue: "userEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "userEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "userEmail",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Ordered Date",
        derivedValue: "order_date=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        sortable: true,
        fieldType: "Date",
        dateFormat: "MMM DD YYYY",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "ordered_price",
        type: "text",
        placeholder: "total_price",
        label: "total_price",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Total",
        derivedValue: "total_price=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "ordered_price",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "ordered_price",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },


      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Actions",
        type: "dropDown",
        placeholder: "Actions",
        label: "Actions",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Actions",
        derivedValue: "Actions=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "Actions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Actions",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "Actions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "user_name",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Customer",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "user_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        dependent: [],
        textAlign: "left",
        show: true,
        field: "user_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        capitalizeTableText: true,
        tDisplay: true,
      },
      {
        name: "payment",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Payment",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "payment",
        showOrHideFields: [],
        capitalizeTableText: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "child_name",
        type: "text",
        placeholder: "Student Name",
        label: "Student Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "child_name=undefined",
        sortable: true,
        filter: true,
        id: "child_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "school_name",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "userEmail",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "userEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "userEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "child_name",
        type: "text",
        placeholder: "Student Name",
        label: "Student Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Student Name",
        derivedValue: "child_name=undefined",
        sortable: true,
        filter: true,
        id: "child_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "child_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_name",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "School Name",
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "school_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "userEmail",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Email",
        derivedValue: "userEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "userEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "userEmail",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();


  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }

    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.orders}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {

          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={false}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? false : false
          }

          printRequried={false}
          actionsTypes={[
            {
              name: "Cancelled",
              options: [{ label: "Cancelled", value: "Cancelled", show: true, multiple: true }],
            },
            {
              name: "Delivered",
              options: [{ label: "Delivered", value: "Delivered", show: true, multiple: true }],
            },
          ]}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.orders}
          globalSearch={
            "School Name / Student Name"
          }
          displayName="Orders"
          type="Orders"
          routeTo={apiCalls.orders}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.orders?.toLowerCase()}
          apiUrl={apiCalls.orders}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="orders"
          apiUrl={apiCalls.orders}
        />
      ) : null}
    </span>
  );
};

export default Orders;
