import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Students from './components/Students';

const students = (props,{ t }) => (
  <Container>
    <Students {...props} />
  </Container>
);

students.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(students);
