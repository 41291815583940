import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersByStores from './components/OrdersByStores';

const ordersByStores = (props,{ t }) => (
  <Container>
    <OrdersByStores {...props} />
  </Container>
);

ordersByStores.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ordersByStores);
