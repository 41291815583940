import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';


const PreviewModal = ({ type, emailTemplateData, closePreviewModal, isPreviewModal }) => {

 
    useEffect(() => {
        const data = emailTemplateData;
        const templateTextElement = document.getElementById('templateText');
        if (templateTextElement) {
            templateTextElement.innerHTML = data['templateText'];
        }
    }, []);

    return (
        <div style={{ display: 'flex' }}>
            <Modal isOpen={isPreviewModal}
                className={` modal-dialog--primary modal-dialog--header `}
            >
                <ModalHeader className="modal__header viewModalHeader" >
                    Email Preview
                    <button className="lnr lnr-cross modal__close-btn" type="button"
                        onClick={closePreviewModal} />
                </ModalHeader>
                <ModalBody id='templateText'>
                </ModalBody>
            </Modal>
        </div>
    );

}
export default PreviewModal;