
import React from 'react'
import { useState, useEffect, useRef } from "react";
import { useFieldArray } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import fetchMethodRequest from '../../config/service';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import Loader from '../App/Loader';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoInputField from './Fields/DoInputField';
import DoDateField from './Fields/DoDateField';
import DoSelectField from './Fields/DoSelectField';
import { Dialog } from 'primereact/dialog';
import DoTextareaField from './Fields/DoTextareaField';
import DeleteConfirmationModal from '../Cruds/CommonModals/DeleteConfirmationModal'
import DoCheckboxField from './Fields/DoCheckboxField';

function FamilyModal(props) {

    const [isLoading, setIsLoading] = useState(false);
    // let [schema, setSchema] = useState({});
    let [isUniProductName, setIsUniProductName] = useState(undefined)
    const [formType, setFormType] = useState(props.formType);
    const [editRowDataID, setEditRowDataID] = useState(props.tableRowData);
    let [sessionExpiryModal, setSessionExpiryModal] = useState(false);
    const [isCustomVolumeDes, setIsCustomVolumeDes] = useState(false);
    const [parentTypes, setParentTypes] = useState([])
    const [studentFormFields, setStudentFormFields] = useState([])
    const [students, setStudents] = useState([{}]); // Initializing with one student object
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);


    // const phoneValidation = yup.string()
    //     .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Please enter a valid phone number in the format (XXX) XXX-XXXX');

    const schema = yup.object().shape({
        family: yup.string().required('Please fill above field'),
        p1_firstname: yup.string().required('Please fill above field'),
        p1_lastname: yup.string().required('Please fill above field'),
        address1: yup.string().required('Please fill above field'),
        zipCode: yup.string().matches(/^[0-9]{5}$/, 'Please enter a valid 5 digit ZipCode').required('Please fill above field'),
        city: yup.string().required('Please fill above field'),
        state: yup.string().required('Please fill above field'),
        email1: yup.string().email('Please enter a valid email').required('Please fill above field'),
        // phone1_home: phoneValidation,
        // phone2_home: phoneValidation,
        // phone1_work: phoneValidation,
        // phone2_work: phoneValidation,
        // phone2_mobile: phoneValidation,
    });




    let {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });


    const familyName = watch('family');
    const lastName1AsFamily = watch('lastName1AsFamily');
    const lastName2AsFamily = watch('lastName2AsFamily')
    const showContactInfo = watch('showContactInfo');
    // const showFullAddress = watch('showFullAddress');
    // const showEmail2 = watch('showEmail2');
    // const showhome1 = watch('showhome1');
    // const showhome2 = watch('showhome2');
    // const showmobile1 = watch('showmobile1');
    // const showmobile2 = watch('showmobile2');
    // const showphone1 = watch('showphone1');
    // const showphone2 = watch('showphone2');

    useEffect(() => {
        if (showContactInfo) {
            setValue('showContactInfo', true);
            setValue('showFullAddress', true);
            setValue('showEmail2', true);
            setValue('showhome1', true);
            setValue('showhome2', true);
            setValue('showmobile1', true);
            setValue('showmobile2', true);
            setValue('showphone1', true);
            setValue('showphone2', true);
            setValue('showEmail1', true)
        } else {
            setValue('showContactInfo', false);
            // Optional: Reset the other checkboxes if showContactInfo is unchecked
            setValue('showFullAddress', false);
            setValue('showEmail2', false);
            setValue('showhome1', false);
            setValue('showhome2', false);
            setValue('showmobile1', false);
            setValue('showmobile2', false);
            setValue('showphone1', false);
            setValue('showphone2', false);
            setValue('showEmail1', false)

        }
    }, [showContactInfo, setValue]);



    // useEffect(() => {
    //     if (lastName1AsFamily || lastName2AsFamily ) {
    //         setValue('p1_lastName', familyName);
    //         setValue('p2_lastName',familyName)
    //     }
    // }, [familyName, lastName1AsFamily,lastName2AsFamily, setValue]);


    useEffect(() => {
        if (lastName1AsFamily) {
            setValue('p1_lastname', familyName);
        }
    }, [familyName, lastName1AsFamily, setValue]);

    useEffect(() => {
        if (lastName2AsFamily) {
            setValue('p2_lastname', familyName);
        }
    }, [familyName, lastName2AsFamily, setValue]);

    useEffect(() => {
        append({});
        getSettingsFromServer()
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
    }, []);


    useEffect(() => {
        if (formType === "edit" && editRowDataID) {
            getRowData(); // Fetch initial data
        }
    }, [formType, editRowDataID]);



    const { fields, append, remove } = useFieldArray({
        control,
        name: 'childrens',
    });


    const addStudents = () => {
        setStudents([...students, {}]);
    };



    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleDeleteIconClick = () => {
        // Handle click event of delete icon here
        openDeleteModal();
    };


    //get fields data from the settings call 
    let getSettingsFromServer = async () => {
        let filterCriteria = {},
            url;
        filterCriteria.sortfield = "sequenceNo";
        filterCriteria.direction = "asc";
        url = `settings`;
        fetchMethodRequest("GET", url)
            .then(async (res) => {
                if (res && res.settings[0]) {
                    let parentTypes = res.settings[0].parentTypes;
                    setParentTypes(parentTypes)

                }
            })
            .catch((err) => {
                return err;
            }
            );
    }

    const autoPopulateCityStateFromZipCode = async (zipCode, setValue) => {
        let filterCriteria = {};
        if (zipCode && zipCode.length === 5) {
            filterCriteria['criteria'] = [{
                key: 'zip_code',
                value: zipCode,
                type: 'eq'
            }];
            let url = `zipcodes?filter=${JSON.stringify(filterCriteria)}`;

            try {
                const resp = await fetchMethodRequest('GET', url);
                if (resp && resp.zipcodes && resp.zipcodes.length > 0) {
                    let data = resp.zipcodes[0];
                    // Update state with received data
                    setValue('zipCode', data.zip_code);
                    setValue('state', data.state);
                    setValue('city', data.city);
                } else {
                    // Clear state and city if no data found
                    setValue('state', '');
                    setValue('city', '');
                }
            } catch (err) {
                console.error("API Error:", err);
            }
        } else {
            // Clear state and city if zip code is invalid
            setValue('state', '');
            setValue('city', '');
        }
    };





    const getStudentData = async (e, studentIndex) => {
        let filterCriteria = { sortfield: "sequenceNo", direction: "asc" };
        let url = `schools`;

        try {
            let res = await fetchMethodRequest("GET", url);
            if (res && res.schools) {
                let matchingSchool = res.schools.find(school => school.Name === e.Name && school.districtObjId === e.districtObjId);
                if (matchingSchool && matchingSchool.studentFormFields) {
                    let studentFields = matchingSchool.studentFormFields;
                    console.log("studentFields>>>>>......", studentFields)
                    // Update the student form fields state
                    setStudentFormFields(prevFields => {
                        const newFields = [...prevFields];
                        newFields[studentIndex] = studentFields;
                        return newFields;
                    });
                }
            }
        } catch (err) {
            console.error(err);
        }
    };




    const handleDeleteClick = (index) => {
        setDeleteIndex(index);
        setDeleteModalOpen(true);
    };

    const handleDeleteConfirm = () => {
        // Perform the delete operation here
        remove(deleteIndex);
        setDeleteModalOpen(false);
    };

    const handleDeleteCancel = () => {
        setDeleteModalOpen(false);
    };



    const getRowData = async () => {
        let fields = editRowDataID;
        let keys = Object.keys(fields);

        // Store student data to be fetched
        const studentDataPromises = [];

        for (let item of keys) {
            if (item === 'childrens' && Array.isArray(fields[item])) {
                for (let index in fields[item]) {
                    let child = fields[item][index];

                    // Check if school and district data is available
                    if (child['school_name'] && child['district_name']) {
                        let eventKeys = {
                            Name: child['school_name'].Name,
                            districtObjId: child['district_name']._id
                        };

                        // Collect promises for fetching student data
                        studentDataPromises.push(getStudentData(eventKeys, index));
                    }

                    // Set values for predefined fields
                    for (let childItem in child) {
                        if (typeof child[childItem] === 'object' && child[childItem] !== null && 'name' in child[childItem]) {
                            setValue(`childrens[${index}].${childItem}`, child[childItem].name);
                        } else {
                            setValue(`childrens[${index}].${childItem}`, child[childItem]);
                        }
                    }
                }
            } else {
                setTimeout(() => {
                    setValue(item, fields[item]);
                }, 100);
            }
        }

        // Wait for all student data to be fetched
        await Promise.all(studentDataPromises);
    };


    const submit = (values) => {
        if (values && Object.keys(values).length > 0) {
            saveDataToServer(values);
        } else {
            return;
        }
    }
    const saveDataToServer = async (formValues) => {
        let userBody = Object.assign({}, formValues);
        let validationExists;
        setIsLoading(true);
        if (formValues) {

            let method, apiUrl;
            if (formType === 'edit') {
                delete userBody.email
                delete userBody.password;
                method = 'PUT';
                apiUrl = `${props.apiUrl}/${editRowDataID._id}`;
                console.log("userBody>>>>>>>>>>>>>>", userBody)
            }
            else {
                method = 'POST';
                apiUrl = props.apiUrl;
                if (userBody && userBody.childrens) {
                    console.log("add", userBody)
                    // Create an array to store modified child objects
                    const modifiedChildrens = [];
                    // Iterate over each object in userBody.childrens
                    userBody.childrens.forEach(child => {
                        // Extract district_name, school_name, and class_name
                        const districtName = child.district_name && child.district_name.name;
                        const districtId = child.district_name && child.district_name.district_id;
                        const schoolName = child.school_name && child.school_name.Name;
                        const schoolId = child.school_name && child.school_name.school_id;
                        const className = child.class_name && child.class_name.class_name;
                        const grade = child.class_name && child.class_name.grade

                        // Add the extracted properties to the child object
                        const modifiedChild = {
                            district_nameSearch: districtName,
                            district_id: districtId,
                            school_nameSearch: schoolName,
                            school_id: schoolId,
                            class_nameSearch: className,
                            grade_int: grade,
                            ...child // Include the remaining properties from the original child object
                        };
                        modifiedChildrens.push(modifiedChild);
                    });
                    userBody.childrens = modifiedChildrens;
                }

            }



            return fetchMethodRequest(method, apiUrl, userBody)
                .then(async (response) => {
                    let sessionexpired = await localStorage.getItem('sessionexpired')
                    if (sessionexpired === "true") {
                        setSessionExpiryModal(true);
                    }
                    if (response && response.respCode) {
                        await props.getDataFromServer(props.filterCriteria, props.categoryType);
                        showToasterMessage(response.respMessage, 'success');
                        if (props.displayViewOfForm === 'modal') {
                            props.closeFormModal('save', response.quantityId);
                        } else {
                            if (formType !== 'add') {
                                props.closeFormModal('save', response.quantityId);

                            } else {
                                props.closeFormModal('save', response.quantityId);
                            }

                        }
                        clearFormFields();
                        props.reset();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }

                    setIsLoading(false);
                }).catch((err) => {
                    return err;
                });

        } else {
            return
        }

    }

    const getFields = () => {
        return (
            <>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'family'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Family Name'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Family Name"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={"parent1"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoSelectField
                                input={field}
                                markReq={false}
                                id={field.id}
                                name={field.name}
                                field={field}
                                label={"Parent1 Type"}
                                fieldState={fieldState}
                                errors={errors}
                                options={parentTypes}
                                optionLabel={"value"}
                                placeholder={"Parent1 Type"}
                            />)}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'p1_suffix'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={false}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Parent1 Suffix'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Parent1 Suffix"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'p1_firstname'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Parent1 First Name'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Parent1 First Name"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'p1_middlename'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={false}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Parent1 Middle Name'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Parent1 Middle Name"}
                            />
                        )}
                    />
                </div>
                <div className='col-md-6 mt-2'>
                    <div className="form-group">
                        <div className="d-flex align-items-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3px' }}>
                            <label className="form__form-group-label mb-0 mr-2" style={{ marginBottom: 0, marginRight: '0.5rem' }}>
                                Parent1 Last Name
                            </label>
                            <Controller
                                name='lastName1AsFamily'
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoCheckboxField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        label={'Same as Family'}
                                        field={field}
                                        type={"checkbox"}
                                        defaultChecked={false}
                                        fieldState={fieldState}
                                        errors={errors}
                                    />
                                )}
                            />
                        </div>
                        <Controller
                            name={'p1_lastname'}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    label={''}
                                    field={field}
                                    type={"text"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    defVal={''}
                                    placeholder={"Parent1 Last Name"}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={"parent2"}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoSelectField
                                input={field}
                                markReq={true}
                                id={field.id}
                                name={field.name}
                                field={field}
                                label={"Parent2 Type"}
                                fieldState={fieldState}
                                errors={errors}
                                options={parentTypes}
                                optionLabel={"value"}
                                placeholder={"Parent2 Type"}
                            />)}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'p2_suffix'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Parent2 Suffix'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Parent2 Suffix"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'p2_firstname'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Parent2 First Name'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Parent2 First Name"}
                            />
                        )}
                    />
                </div>
                <div className="col-md-6 mt-2">
                    <Controller
                        name={'p2_middlename'}
                        control={control}
                        render={({ field, fieldState }) => (
                            <DoInputField
                                markReq={true}
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={'Parent2 Middle Name'}
                                field={field}
                                type={"text"}
                                fieldState={fieldState}
                                errors={errors}
                                defVal={''}
                                placeholder={"Parent2 Middle Name"}
                            />
                        )}
                    />
                </div>
                <div className='col-md-6 mt-2'>
                    <div className="form-group">
                        <div className="d-flex align-items-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '3px' }}>
                            <label className="form__form-group-label mb-0 mr-2">
                                Parent2 Last Name
                            </label>
                            <Controller
                                name='lastName2AsFamily'
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoCheckboxField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        label={'Same as Family'}
                                        field={field}
                                        type={"checkbox"}
                                        defaultChecked={false}
                                        fieldState={fieldState}
                                        errors={errors}
                                    />
                                )}
                            />
                        </div>
                        <Controller
                            name={'p2_lastname'}
                            control={control}
                            render={({ field, fieldState }) => (
                                <DoInputField
                                    // markReq={true}
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    label={''} // Remove label here
                                    field={field}
                                    type={"text"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    defVal={''}
                                    placeholder={"Parent2 Last Name"}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='col-sm-12 pl-0 d-flex pb-2'>
                    <h5 className='text-primary'><b>{'Contact Information'}</b>&nbsp;</h5>
                    <span className='pl-4'>
                        <Controller
                            name='showContactInfo'
                            control={control}
                            render={({ field, fieldState }) => {
                                field.value = field.value ? field.value : false;
                                return <DoCheckboxField
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    label={'Show All'}
                                    field={field}
                                    type={"text"}
                                    fieldState={fieldState}
                                    errors={errors}
                                    defaultChecked={false}
                                />
                            }}
                        />
                    </span>
                </div>
                <div className='cradBodres card pb-0' style={{ backgroundColor: '#fff' }}>
                    <div className="form__form-group-field my-2">
                        <h5 className='text-dark'><b>Address</b>&nbsp;</h5>
                        <span className='pl-2'>
                            <Controller
                                name='showFullAddress'
                                control={control}
                                render={({ field, fieldState }) => {
                                    field.value = field.value ? field.value : false;
                                    return <DoCheckboxField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        label={'Show'}
                                        field={field}
                                        type={"text"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        defaultChecked={false}
                                    />
                                }}
                            />
                        </span>
                    </div>
                    <div className='card'>
                        <div className='cradBodres p-2 pr-2 addressDetailsBg borderRadius5 d-flex flex-wrap'>
                            <div className="col-md-6 mt-2 align_fields">
                                <Controller
                                    name={'address1'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoTextareaField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            label={'Address Line 1'}
                                            // defVal={item.value}
                                            fieldState={fieldState}
                                            errors={errors}
                                            placeholder={'Address Line 1'}
                                        />)}
                                />
                            </div>
                            <div className="col-md-6 mt-2 align_field">
                                <Controller
                                    name={'address2'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoTextareaField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            label={'Address Line 2'}
                                            // defVal={item.value}
                                            fieldState={fieldState}
                                            errors={errors}
                                            placeholder={'Address Line 2'}
                                        />)}
                                />
                            </div>
                            <div className="col-md-6 mt-2 align_fields">
                                <Controller
                                    name={'zipCode'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={true}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Zip Code'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            placeholder={"Zip Code"}
                                            // onChange={e => autoPopulateCityStateFromZipCode(e)}
                                            // onChange={e => autoPopulateCityStateFromZipCode(e.target.value, setValue)}
                                            // onChange={(e) => {
                                            //     console.log("KKKKKKKKKKKKKKKKKKKKKKK")
                                            //     const value = e.target.value;
                                            //     console.log("value>>>>>>",value)
                                            //     field.onChange(e); // Ensure that react-hook-form is updated
                                            //     autoPopulateCityStateFromZipCode(value, setValue);
                                            // }}

                                            onChange={(value) => autoPopulateCityStateFromZipCode(value, setValue)}

                                        />
                                    )}
                                />
                            </div>
                            <div className="col-md-6 mt-2 align_field">
                                <Controller
                                    name={'city'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={true}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'City'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            placeholder={"City"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-md-6 mt-2 align_fields">
                                <Controller
                                    name={'state'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={true}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'State'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            placeholder={"State"}
                                        />
                                    )}
                                />
                            </div>

                        </div>
                    </div>
                    <div className='cradBodres p-2 pr-2 addressDetailsBg borderRadius5 d-flex flex-wrap'>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'email1'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={true}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent1 Email'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            placeholder={"Parent1 Email"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showEmail1'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'email2'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent2 Email'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            placeholder={"Parent2 Email"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showEmail2'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'phone1_home'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent1 Home Phone'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            formatPhoneNumber={true}
                                            defVal={''}
                                            placeholder={"Parent1 Home Phone"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showhome1'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'phone2_home'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent2 Home Phone'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            formatPhoneNumber={true}
                                            defVal={''}
                                            placeholder={"Parent1 Home Phone"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showhome2'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />

                                    }}

                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'phone1_mobile'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent1 Mobile Phone'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defVal={''}
                                            formatPhoneNumber={true}
                                            placeholder={"Parent1 Mobile Phone"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showmobile1'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'phone2_mobile'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent2 Mobile Phone'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            formatPhoneNumber={true}
                                            defVal={''}
                                            placeholder={"Parent2 Mobile Phone"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showmobile2'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'phone1_work'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent1 Work Phone'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            formatPhoneNumber={true}
                                            defVal={''}
                                            placeholder={"Parent1 Work Phone"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showphone1'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center gap-2">
                            <div className='flex-grow-1 mt-2'>
                                <Controller
                                    name={'phone2_work'}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoInputField
                                            markReq={false}
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Parent2 Work Phone'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            formatPhoneNumber={true}
                                            defVal={''}
                                            placeholder={"Parent2 Work Phone"}
                                        />
                                    )}
                                />
                            </div>
                            <div className="ml-4 mt-4 d-flex align-items-center gap-2">
                                <Controller
                                    name='showphone2'
                                    control={control}
                                    render={({ field, fieldState }) => {
                                        field.value = field.value ? field.value : false;
                                        return <DoCheckboxField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            label={'Show'}
                                            field={field}
                                            type={"text"}
                                            fieldState={fieldState}
                                            errors={errors}
                                            defaultChecked={false}
                                        />
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div>
                                {fields.map((childrens, index) => (
                                    <div key={childrens.id} className='cradBodres p-2 pt-2 addressDetailsBg borderRadius5 d-flex flex-wrap' style={{ marginTop: "2rem" }}>
                                        <div className='col-sm-12 pl-0 pt-3' key={index + 'studentFormHeader'}>
                                            <h5 className='text-primary'><b>{`Student ${index + 1} Information`}</b></h5>
                                        </div>
                                        <div className="students_data col-md-6 mt-2">
                                            <Controller
                                                name={`childrens[${index}].district_name`}
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <DoAutoCompleteField
                                                        markReq={true}
                                                        input={field}
                                                        id={field.id}
                                                        name={field.name}
                                                        field={field}
                                                        filterField={false}
                                                        filterValue={false}
                                                        filterType={false}
                                                        multiple={false}
                                                        fieldState={fieldState}
                                                        errors={errors}
                                                        type={props.type}
                                                        searchApi={'districts'}
                                                        formTyp={formType}
                                                        searchField={'name'}
                                                        allow={props.allowDuplicates}
                                                        placeholder={'District Name'}
                                                        label={'District Name'}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                        watch={watch}
                                                        isProductName={isUniProductName}
                                                        isDisable={formType === "edit" ? true : false}
                                                        style={{ marginRight: '20px' }}

                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <Controller
                                                name={`childrens[${index}].school_name`}
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <DoAutoCompleteField
                                                        markReq={true}
                                                        input={field}
                                                        id={field.id}
                                                        name={field.name}
                                                        field={field}
                                                        filterField={false}
                                                        filterValue={false}
                                                        filterType={false}
                                                        multiple={false}
                                                        fieldState={fieldState}
                                                        errors={errors}
                                                        type={props.type}
                                                        searchApi={'schools'}
                                                        formTyp={formType}
                                                        searchField={'Name'}
                                                        hasDependency={true}
                                                        watch={watch}
                                                        setValue={setValue}
                                                        onChange={(event) => {
                                                            field.onChange(event);
                                                            getStudentData(event, index);
                                                        }}
                                                        placeholder={'School Name'}
                                                        hasDependencyField={'district_name'}
                                                        label={'School Name'}
                                                        getValues={getValues}
                                                        isProductName={isUniProductName}
                                                        isDisable={formType === "edit" ? true : false}
                                                        style={{ marginRight: '20px' }}

                                                    />

                                                )}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <Controller
                                                name={`childrens[${index}].class_name`}
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <DoAutoCompleteField
                                                        markReq={true}
                                                        input={field}
                                                        id={field.id}
                                                        name={field.name}
                                                        formTyp={formType}
                                                        field={field}
                                                        filterField={false}
                                                        filterValue={false}
                                                        filterType={false}
                                                        multiple={false}
                                                        fieldState={fieldState}
                                                        errors={errors}
                                                        type={props.type}
                                                        searchApi={'classes'}
                                                        searchField={'class_name'}
                                                        allow={props.allowDuplicates}
                                                        placeholder={'Class Name'}
                                                        hasDependencyField={'school_name'}
                                                        label={'Class Name'}
                                                        watch={watch}
                                                        hasDependency={true}
                                                        getValues={getValues}
                                                        setValue={setValue}
                                                        isProductName={isUniProductName}
                                                        isDisable={formType === "edit" ? true : false}
                                                        style={{ marginRight: '20px' }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <Controller
                                                name={`childrens[${index}].student_firstname`}
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <DoInputField
                                                        markReq={false}
                                                        input={field}
                                                        id={field.id}
                                                        name={field.name}
                                                        label={'Student First Name'}
                                                        field={field}
                                                        type={"text"}
                                                        fieldState={fieldState}
                                                        errors={errors}
                                                        defVal={''}
                                                        placeholder={field.placeholder}
                                                        style={{ marginRight: '20px' }}

                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <Controller
                                                name={`childrens[${index}].student_lastname`}
                                                control={control}
                                                render={({ field, fieldState }) => (
                                                    <DoInputField
                                                        markReq={false}
                                                        input={field}
                                                        id={field.id}
                                                        name={field.name}
                                                        label={'Student Last Name'}
                                                        field={field}
                                                        type={"text"}
                                                        fieldState={fieldState}
                                                        errors={errors}
                                                        defVal={''}
                                                        placeholder={field.placeholder}
                                                        style={{ marginRight: '20px' }}

                                                    />
                                                )}
                                            />
                                        </div>
                                        {studentFormFields[index] && studentFormFields[index].map((field) => (
                                            <div key={field.id} className="col-md-6 mt-2">
                                                <Controller
                                                    name={`childrens[${index}].${field.name}`}
                                                    control={control}
                                                    render={({ field: controllerField, fieldState }) => {
                                                        if (field.type === "Dropdown") {
                                                            return (
                                                                <DoSelectField
                                                                    input={controllerField}
                                                                    markReq={false}
                                                                    id={field.id}
                                                                    name={field.name}
                                                                    field={controllerField}
                                                                    label={field.label}
                                                                    fieldState={fieldState}
                                                                    errors={errors}
                                                                    options={field.options}
                                                                    placeholder={field.placeholder}
                                                                    style={{ marginRight: '20px' }}

                                                                />
                                                            );
                                                        } else if (field.type === "text") {
                                                            return (
                                                                <DoInputField
                                                                    markReq={false}
                                                                    input={controllerField}
                                                                    id={field.id}
                                                                    name={field.name}
                                                                    label={field.label}
                                                                    field={controllerField}
                                                                    type={"text"}
                                                                    fieldState={fieldState}
                                                                    errors={errors}
                                                                    defVal={''}
                                                                    placeholder={field.placeholder}
                                                                    style={{ marginRight: '20px' }}

                                                                />
                                                            );
                                                        } else if (field.type === "date") {
                                                            return (
                                                                <DoDateField
                                                                    markReq={false}
                                                                    input={controllerField}
                                                                    id={field.id}
                                                                    name={field.name}
                                                                    label={field.label}
                                                                    field={controllerField}
                                                                    type={"date"}
                                                                    maxDate={null}
                                                                    fieldState={fieldState}
                                                                    errors={errors}
                                                                    defVal={''}
                                                                    placeholder={field.placeholder}
                                                                    style={{ marginRight: '20px' }}

                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    }}
                                                />
                                            </div>
                                        ))}
                                        <div className='col-sm-12'>
                                            <span style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px', marginTop: '20px', marginBottom: '5px' }}>
                                                <FontAwesomeIcon
                                                    className='deleteIcon'
                                                    color='brown'
                                                    icon='trash-alt'
                                                    data-toggle="tool-tip" title="Delete"
                                                    onClick={() => handleDeleteClick(index)}

                                                />
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-sm-12 pl-0'>
                            {props.type === 'Families' && (
                                <h2 className='d-flex my-2 justify-content-end align_AddStudent' >
                                    <span>
                                        <FontAwesomeIcon
                                            className='addIcon'
                                            color='#024a88'
                                            icon='plus-circle'
                                            data-toggle="tool-tip" title="Add"
                                            onClick={() => append({})}
                                        />
                                    </span>
                                    <span className='pl-2'>Add Students</span>
                                </h2>
                            )}
                        </div>
                        <div className='d-flex justify-content-center mt-2'>
                            <div className='col-12 px-0'>
                                <ButtonToolbar className='family_buttons'>
                                    <Button color='primary' type="button" className="outline-button formmodal me-2" onClick={() => closeFormModal()}>
                                        Cancel
                                    </Button>
                                    <Button color='primary' className="button formmodal ms-2" type="submit">
                                        {formType === 'add' ? 'Save' : 'Update'}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // modal Header
    let getModalHeader = () => {
        let { t } = props;
        return (
            <ModalHeader className="modal__header">
                {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.closeFormModal} /> */}
                <p className="bold-text  modal__title">
                    {formType &&
                        formType === 'edit' ?
                        'Edit' ? 'Edit' : 'Edit' :
                        formType &&
                            formType === 'view' ?
                            'View' ? 'View' : 'View' : 'Add' ? 'Add' : 'Add'
                    }{' '}
                    {props.displayName ? props.displayName : null}
                </p>
            </ModalHeader>
        )
    }

    let closeFormModal = async () => {

        // props.reset();
        props.closeFormModal();
        // clearFormFields();
    }

    {/* Button */ }
    const getButtonToolbar = () => {

        return (
            <div></div>
            // <div className='d-flex mt-2'>
            //     <div className='col-12 px-0'>
            //         <span className='float-end'>
            //             <ButtonToolbar>
            //                 <Button color='primary' type="button" className='btn custom-outline' outline onClick={() => closeFormModal()}>
            //                     Cancel
            //                 </Button>
            //                 <Button color='primary' className='btn custom-outline' outlined type="submit">
            //                     {formType === 'add' ? 'Save' : 'Update'}
            //                 </Button>
            //             </ButtonToolbar>
            //         </span>
            //     </div>
            // </div>
        )
    }



    // ModalBody
    let getModalBody = () => {
        return (

            <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
                <Loader loader={isLoading} />
                <div className="row form cradBodres p-2 pr-2" >
                    {getFields()}

                </div>
                {/* {getButtonToolbar()} */}
            </form>

        )
    }

    return (

        <div>
            <Dialog
                visible={props.isOpenFamily}
                onHide={closeFormModal}
                draggable={false}
                style={{ width: '50vw' }}
                header={getModalHeader()}
                closeOnEscape={false}

            >
                {
                    <ModalBody className="modal__body mb-0 pt-4">
                        <Card className='pb-0 cardForListMargin'>
                            <CardBody className='tableCardBody'>
                                {getModalBody()}
                            </CardBody>
                        </Card>
                    </ModalBody>
                }
            </Dialog>
            {/* Delete Confirmation Modal */}
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onConfirm={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                />
            )}
        </div>
    )
}

export default FamilyModal
