import React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'; // Adjust based on your modal library

const DeleteConfirmationModal = ({ isOpen, onConfirm, onCancel }) => {
    return (
        <Modal isOpen={isOpen} toggle={onCancel} className="delete-confirmation-modal" style={{marginTop:'20%',width:'30%'}}>
            <ModalBody>
                Are you sure you want to delete this item?
            </ModalBody>
            <div>
                <Button color="primary" outline onClick={onConfirm}>Delete</Button>{' '}
                <Button color="primary" outline onClick={onCancel}>Cancel</Button>
            </div>
        </Modal>
    );
};

export default DeleteConfirmationModal;
