import React from 'react';
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import DoSelectField from '../../Form/Fields/DoSelectField';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useState, useEffect } from "react";
import * as yup from "yup";
import fetchMethodRequest from '../../../config/service';
import DoMultiSelectField from '../../Form/Fields/DoMultiSelectField';
import TemplatePreviewModal from './TemplatePreviewModal';
import apiCalls from '../../../config/apiCalls';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
const GroupEmailTemplate = (props) => {
    let [schema, setSchema] = useState({});
    const [groupTypes, setGroupTypes] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
    const [showMultiSelect, setShowMultiSelect] = useState(false);
    const [openPreviewModal, setOpenPreviewModal] = useState(false)
    const [templateData, setTemplateData] = useState()
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [emailId, setEmailID] = useState()

    let {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        getGroup()
    }, []);



    const sendEmailToServer = async () => {
        closePreviewModal();
        let body = {},
            url = "";
        body["templateId"] = templateData?._id;
        body["selectedIds"] = selectedGroupMembers;
        url = `groups/sendGroupMail/${emailId}`;
        fetchMethodRequest("POST", url, body).then(async (res) => {
            if (res && res.respCode) {
                showToasterMessage(res.respMessage, "success");
            } else if (res && res.errorMessage) {
                showToasterMessage(res.errorMessage, "error");
            }
        });
    };

    const getGroup = async () => {
        let data = localStorage.getItem("templateData");
        data = JSON.parse(data);
        setTemplateData(data)
        if (data) {
            let url = "";
            let filterCriteria = {
                limit: 30,
                page: 1,
                sortfield: "created",
                direction: "desc",
                criteria: []
            };

            filterCriteria["criteria"].push({
                key: "school_id",
                value: data["school_id"],
                type: "eq",
            });
            url = `${'groups'}?filter=${JSON.stringify(filterCriteria)}`;
            fetchMethodRequest("GET", url)
                .then(async (res) => {
                    if (res && res["groups"].length > 0) {
                        let fetchedData = res["groups"];
                        setResponseData(fetchedData); // Store responseData in state
                        let groupTypes = fetchedData.map(group => ({
                            label: group.name,
                            value: group._id
                        }));
                        setGroupTypes(groupTypes);
                    } else {
                        setGroupTypes([]);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };


    const closePreviewModal = () => {
        setOpenPreviewModal(false)
    };

    const onActionsChange = async (event, type) => {
        const selectedGroupId = event.value;
        setEmailID(event.value)
        const selectedGroup = responseData.find(g => g._id === selectedGroupId);

        if (selectedGroup && selectedGroup.members) {
            const memberOptions = selectedGroup.members.map(member => ({
                label: member.email,
                value: member._id,
                email: member.email // Ensure each member has an email field

            }));
            setSelectedGroupMembers(memberOptions);
            setShowMultiSelect(true);
        } else {
            setShowMultiSelect(false);
            setSelectedGroupMembers([]);
        }
    };
    const handleMembersChange = (value) => {
        setSelectedMembers(value);
    };

    return (
        <div>
            {openPreviewModal && (
                <TemplatePreviewModal
                    // data={this.state.data}
                    templateData={templateData}
                    openPreviewModal={openPreviewModal}
                    closePreviewModal={closePreviewModal}
                    sendEmailToServer={sendEmailToServer}
                    selectedMembers={selectedMembers}
                // closePreviewModal={this.closePreviewModal}
                // sendEmailToServer={this.sendEmailToServer}
                // actions={this.state.actions}
                // filterValue={this.state.filterValue}
                />
            )}
            <Card style={{ margin: "7px" }} className={"table_data_Desktop"}>
                <CardBody className="tableCardBody">
                    <Row>
                        <Col sm={12}>
                            <h4>
                                <b>Send Email</b>
                            </h4>
                            <h5 className="my-4">
                                Choose desired group and email addresses to send the email
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <Controller
                                name={"actions"}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoSelectField
                                        style={{ minWidth: "100%", width: "100%", lineHeight: 1.3 }}
                                        className="mb-3"
                                        input={field}
                                        id={field.id}
                                        name={"actions"}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        value={field.value}
                                        options={groupTypes}
                                        optionLabel={"label"}
                                        placeholder={"Select Group"}
                                        onChange={(e) => {
                                            onActionsChange(e, "dropdownFilter");
                                        }}
                                    />
                                )}
                            />
                        </Col>
                        {showMultiSelect && (
                            <Col sm={4}>
                                <Controller
                                    name={"members"}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoMultiSelectField
                                            id="membersSelect"
                                            name="members"
                                            field={field}
                                            fieldState={fieldState}
                                            errors={errors}
                                            options={selectedGroupMembers}
                                            optionLabel="label"
                                            placeholder="Select Members"
                                            maxSelectedLabels={3}
                                            value={field.value}
                                            filter={true}
                                            onChange={(e) => {

                                                handleMembersChange(e, "demo");
                                            }}
                                        />
                                    )}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Button
                                color="primary"
                                onClick={() => setOpenPreviewModal(true)}
                                className="mt-3"
                            >
                                Preview Email
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export default GroupEmailTemplate;
