const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "https://schooldevapi.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://schooldevapi.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://schooldevapi.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://schooldevapi.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://schooldevapi.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://schooldevapi.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  districts:
    "https://schooldevapi.dosystemsinc.com/images/sample_districts_bulk_upload_file.csv",
  schools:
    "https://schooldevapi.dosystemsinc.com/images/sample_schools_bulk_upload_file.csv",
  classes:
    "https://schooldevapi.dosystemsinc.com/images/sample_classrooms_bulk_upload_file.csv",
  directories:
    "https://schooldevapi.dosystemsinc.com/images/sample_directories_bulk_upload_file.csv",
  departments:
    "https://schooldevapi.dosystemsinc.com/images/sample_departments_bulk_upload_file.csv",
  faculties:
    "https://schooldevapi.dosystemsinc.com/images/sample_faculties_bulk_upload_file.csv",
  groups:
    "https://schooldevapi.dosystemsinc.com/images/sample_groups_bulk_upload_file.csv",
  addmembers:
    "https://schooldevapi.dosystemsinc.com/images/sample_addmembers_bulk_upload_file.csv",
  groupemails:
    "https://schooldevapi.dosystemsinc.com/images/sample_groupemails_bulk_upload_file.csv",
  news: "https://schooldevapi.dosystemsinc.com/images/sample_news_bulk_upload_file.csv",
  payments:
    "https://schooldevapi.dosystemsinc.com/images/sample_payments_bulk_upload_file.csv",
  events:
    "https://schooldevapi.dosystemsinc.com/images/sample_events_bulk_upload_file.csv",
  stores:
    "https://schooldevapi.dosystemsinc.com/images/sample_stores_bulk_upload_file.csv",
  orders:
    "https://schooldevapi.dosystemsinc.com/images/sample_orders_bulk_upload_file.csv",
  products:
    "https://schooldevapi.dosystemsinc.com/images/sample_products_bulk_upload_file.csv",
  ordersummaryreports:
    "https://schooldevapi.dosystemsinc.com/images/sample_ordersummaryreports_bulk_upload_file.csv",
  refunddetails:
    "https://schooldevapi.dosystemsinc.com/images/sample_refunddetails_bulk_upload_file.csv",
  ordersbystores:
    "https://schooldevapi.dosystemsinc.com/images/sample_ordersbystores_bulk_upload_file.csv",
  ordersbyproducts:
    "https://schooldevapi.dosystemsinc.com/images/sample_ordersbyproducts_bulk_upload_file.csv",
  ordersbystoreandproducts:
    "https://schooldevapi.dosystemsinc.com/images/sample_ordersbystoreandproducts_bulk_upload_file.csv",
  ecommercepayments:
    "https://schooldevapi.dosystemsinc.com/images/sample_ecommercepayments_bulk_upload_file.csv",
  orderssummarybygrades:
    "https://schooldevapi.dosystemsinc.com/images/sample_orderssummarybygrades_bulk_upload_file.csv",
  orderssummarybyclasses:
    "https://schooldevapi.dosystemsinc.com/images/sample_orderssummarybyclasses_bulk_upload_file.csv",
  familydata:
    "https://schooldevapi.dosystemsinc.com/images/sample_familydata_bulk_upload_file.csv",
  registeredfamilies:
    "https://schooldevapi.dosystemsinc.com/images/sample_registeredfamilies_bulk_upload_file.csv",
  lastlogins:
    "https://schooldevapi.dosystemsinc.com/images/sample_lastlogins_bulk_upload_file.csv",
  auditlogs:
    "https://schooldevapi.dosystemsinc.com/images/sample_auditlogs_bulk_upload_file.csv",
  children:
    "https://schooldevapi.dosystemsinc.com/images/sample_children_bulk_upload_file.csv",
  vanddmembers:
    "https://schooldevapi.dosystemsinc.com/images/sample_vanddmembers_bulk_upload_file.csv",
  carts:
    "https://schooldevapi.dosystemsinc.com/images/sample_carts_bulk_upload_file.csv",
  advertisements:
    "https://schooldevapi.dosystemsinc.com/images/sample_advertisements_bulk_upload_file.csv",
  families:
    "https://schooldevapi.dosystemsinc.com/images/sample_families_bulk_upload_file.csv",
  students:
    "https://schooldevapi.dosystemsinc.com/images/sample_students_bulk_upload_file.csv",
  committees:
    "https://schooldevapi.dosystemsinc.com/images/sample_committees_bulk_upload_file.csv",
  eventsignups:
    "https://schooldevapi.dosystemsinc.com/images/sample_eventsignups_bulk_upload_file.csv",
  members:
    "https://schooldevapi.dosystemsinc.com/images/sample_members_bulk_upload_file.csv",
  paymentintents:
    "https://schooldevapi.dosystemsinc.com/images/sample_paymentintents_bulk_upload_file.csv",
  reports:
    "https://schooldevapi.dosystemsinc.com/images/sample_reports_bulk_upload_file.csv",
  states:
    "https://schooldevapi.dosystemsinc.com/images/sample_states_bulk_upload_file.csv",
  volunteers:
    "https://schooldevapi.dosystemsinc.com/images/sample_volunteers_bulk_upload_file.csv",
  wishlists:
    "https://schooldevapi.dosystemsinc.com/images/sample_wishlists_bulk_upload_file.csv",
  zipcodes:
    "https://schooldevapi.dosystemsinc.com/images/sample_zipcodes_bulk_upload_file.csv",
  users:
    "https://schooldevapi.dosystemsinc.com/images/sample_users_bulk_upload_file.csv",
  pendingcarts:
    "https://schooldevapi.dosystemsinc.com/images/sample_pendingcarts_bulk_upload_file.csv",
};
export default configSampleFiles;
