import { Badge, Col, Row, Card, CardBody, CardHeader, Collapse, ButtonGroup, ButtonToolbar } from 'reactstrap';
import moment from 'moment';

import { Link, Navigate } from 'react-router-dom';

import '../../../scss/dashboardStyles.css';
import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import FilterOperations from './FilterOperations';


import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';

//Modals
import ShowHideColumnsModal from '../CommonModals/ShowHideColumnsModal';
import FormModal from '../../Form/FormModal';
import DeleteRowModal from '../CommonModals/DeleteRowModal';
import ViewModal from '../CommonModals/viewModal';
import BulkUploadModal from '../CommonModals/BulkUploadModal';
import ConfirmationModal from '../CommonModals/ConfirmationModal';
import SessionExpiryModal from '../CommonModals/SessionexpiryModal';
import PreviewModal from '../CommonModals/PreviewModal';
import SendEmailModal from '../CommonModals/SendEmailModal';
import DeleteFilterModal from '../CommonModals/DeleteFilterModal';

import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';
import bulkSampleFiles from '../../../config/configSampleFiles'
import apiCall from '../../../config/apiCalls';
//Render Date picker
import RenderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
// Date Formate
import dateFormats from '../../UI/FormatDate/formatDate';
import PaymentApproveModal from '../CommonModals/PaymentApproveModal';
//Loader
import Loader from '../../App/Loader';
//store
import store from '../../App/store'
import validate from '../../Validations/validate'
import { reduxForm, } from 'redux-form'
import { connect } from 'react-redux'
import crypto from "crypto-js";

import PaginatorComponent from './PaginatorComponent';
import { element } from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog, faPrint, faGripHorizontal, faList } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'primereact/button';
import { formatPropType } from '../../../utils/utils';
import FunnelFilter from './FunnelFilter';
import UserPasswordResetModal from '../CommonModals/UserPasswordResetModal';
import FamilyModal from '../../Form/FamilyModal';
library.add(faCog, faPrint, faGripHorizontal, faList);

// tooltip styles
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
}))(Tooltip);

const AnotherLightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: config.templateColor,
    color: '#fff',
    boxShadow: theme.shadows[10],
    fontSize: 14,
    fontWeight: '500',
    margin: 0,
  },
}))(Tooltip);

function DataTables(props) {




  const [allUsersData, setAllUsersData] = useState([]);
  const [deleteRowDataId, setDeleteRowDataId] = useState({});
  const [tableFields, setTableFields] = useState(props.getTableFields());
  const [originalTableFields, setOriginalTableFields] = useState(props.getTableFields());
  const [tablefieldsToShow, setTablefieldsToShow] = useState(props.getTableFields());
  const [isOpenShowHideColumnsModal, setIsOpenShowHideColumnsModal] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(20);
  const [sortified, setSortified] = useState(false);
  const [direction, setDirection] = useState('asc');
  const [filterCriteria, setFilterCriteria] = useState({ limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState();
  const [totalRecords, setTotalRecords] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScreenPermission, setSelectedScreenPermission] = useState('');
  const [loginRole, isLoginRole] = useState('Admin');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [apiUrl, setApiUrl] = useState(props.apiUrl);
  const [totalRecordsLength, setTotalRecordsLength] = useState(0);
  const [tabType, setTabType] = useState(props.tabType);
  const [mobileListFields, setMobileListFields] = useState([]);
  const [dateFormat, setDateFormat] = useState();
  const [selectschool, setSelectschool] = useState();
  const [roleType, setRoleType] = useState();
  const [openId, setOpenId] = useState(1);
  const [redirecToLogin, setRedirecToLogin] = useState(false);
  const [blockFamily, setBlockFamily] = useState(false);
  const [selectActions, setSelectActions] = useState();
  const [viewType, setViewType] = useState('list');
  const [actions, setActions] = useState();
  const [displayBreadCrumbField, setDisplayBreadCrumbField] = useState();
  const [displayBreadCrumbValue, setDisplayBreadCrumbValue] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [globalSearch, setGlobalSearch] = useState();
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false);
  const [multiSelectTypes, setMultiSelectTypes] = useState();
  const [actionsTypes, setActionTypes] = useState(props.actionsTypes);
  const [userStatus, setUserStatus] = useState();
  const [selectedAutoCompleteValue, setSelectedAutoCompleteValue] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [confirmModalText, setConfirmModalText] = useState();
  const [confirmType, setConfirmType] = useState();
  const [emailTemplateData, setEmailTemplateData] = useState();
  const [redirectToRoute, setRedirectToRoute] = useState(false);
  const [searchInDateRangeField, setSearchInDateRangeField] = useState(props.searchInDateRangeField ? props.searchInDateRangeField : "created");
  const [fieldMap, setFieldMap] = useState({});
  const [formType, setFormType] = useState();
  const [page, setPage] = useState(1);
  const [sortCount, setSortCount] = useState();
  const [confirmText, setConfirmText] = useState();
  const [leaveStatus, setLeaveStatus] = useState();

  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const [isOpenConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openBulkUploadModal, setOpenBulkUploadModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [isOpenProductsModal, setIsOpenProductsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [displayViewOfForm, setDisplayViewOfForm] = useState(props.displayViewOfForm);
  const [openUserPasswordResetModal, setOpenUserPasswordResetModal] = useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [tableRowData, setTableRowData] = useState({});
  const [listPreferences, setListPreferences] = useState("");
  const [saveCriteria, setSaveCriteria] = useState({});
  const csvLinkRef = useRef();
  const [sortFieldForFunnel, setSortFieldForFunnel] = useState("");
  const [isOpenFamily, setIsOpenFamilyModal] = useState(false);
  const [foundation, setFoundation] = useState('Both')
  const [payment, setPayment] = useState('all')
  const [schoolType, setSchoolType] = useState('All')
  const [selectedStore, setSelectedStore] = useState()
  const [isDisplay, setIsDisplay] = useState(false)
  const [totalAmount, setTotalAmount] = useState()
  const [TotalPtaDues, setTotalPtaDues] = useState()
  const [TotalSupportingDonations, setTotalSupportingDonations] = useState()
  // const [schoolType, setSchoolType] = useState('');
  const [transformedSchools, setTransformedSchools] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [filteredPayment, setFilteredPayment] = useState({
    total_amount: 0,
    total_pta_dues: 0,
    total_suppporting_donations: 0,
  });
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState()
  useEffect(() => {
    readData();
    getFieldTypes();
    getschoolsData();

    // let roleOptions = setCommitteNames;
    // if (props.type === 'Groups'){
    //   getschoolsData();

    // }

    let employeeId = JSON.parse(localStorage.getItem('loginCredentials'));
    if (employeeId && employeeId.listPreferences && employeeId.listPreferences._id) {
      setListPreferences(employeeId.listPreferences._id);
      return fetchMethodRequest('GET', `listPreferences/${employeeId.listPreferences._id}`)
        .then(async (response) => {
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            await setState({ sessionExpiryModal: true })
            setSessionExpiryModal(true)
          }
          setTablefieldsToShow(response.details.columnOrder[formatPropType(props.type)]);
        });

    }

    updateColumnsOrder(null);


  }, []);



  // Fetch payment details
  const getschoolsData = async () => {
    try {
      const response = await fetchMethodRequest("GET", 'payments');
      if (response) {
        const data = response["totals"];
        if (data && data.length > 0) {
          setPaymentDetails(data);
          filterPaymentData(payment, data); // Filter based on initial payment state
        }
      }
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  };


  useEffect(() => {
    if (props.schools) {
      const transformedOptions = props.schools.map((school) => ({
        label: school,
        value: school
      }));
      if (transformedOptions) {
        transformedOptions.push(
          { label: 'All', value: 'All' }
        )
        setTransformedSchools(transformedOptions);

      }
    }
  }, [props.schools]);









  const readData = async () => {
    if (props.viewSelectedRecord || props.editSelectedRecord) {
      let filters = JSON.parse(localStorage.getItem('filters'))
      await getDataFromServer(filters);
    } else {
      await getDataFromServer(filterCriteria);
    }
    if (props.addSelectedRecord) {
      openFormModal('', 'add')
    } else if (props.viewSelectedRecord) {
      getActions();
      openFormModal('', 'view')
    } else if (props.editSelectedRecord) {
      openFormModal('', 'edit')
    }
  }



  const getFieldTypes = () => {
    let tableFields = props.getTableFields();
    let fieldMap = {};
    tableFields.forEach((col) => {
      fieldMap[col.field] = col.fieldType ? col.fieldType.toLowerCase() : col.type == "number" ? col.type : null;

    });
    setFieldMap(fieldMap)
  }




  const getActions = async (e) => {
    let selRows = e ? e : [];
    let actionsTypes = props.actionsTypes ? [...props.actionsTypes] : [];
    let result = [];
    if (actionsTypes && actionsTypes.length) {
      for (let i = 0; i < actionsTypes.length; i++) {
        if (actionsTypes[i]['options'] && actionsTypes[i]['options'].length > 0) {
          let options = actionsTypes[i]['options']
          for (let j = 0; j < options.length; j++) {
            if (options[j]['show'] === true) {
              if (selRows && selRows.length >= 2 && options[j]['multiple'] === true) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              } else if (selRows && selRows.length === 1) {
                result.push({ "label": options[j]['label'], "value": options[j]['value'], "field": options[j]['field'], "action": actionsTypes[i]['action'] })
              }
            }
          }
        }
      }
    }
    setActionTypes(result);
  }

  const handleClickOutside = (event) => {
    if (
      closeMultiSelectDropdown.current &&
      !closeMultiSelectDropdown.current.contains(event.target)
    ) {

      setShowMultiSelectDropDown(false);
    }
  };

  const updateDimensions = async () => {
    setWidth(window.innerWidth);
    setHeight(window.height);
  };
  const getTimeFormat = () => {

  }
  const setEncryptFields = async () => {
    let encryptFields = [];
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].fieldType == "encryptedField") {
        encryptFields.push(tablefieldsToShow[i]['field']);
      }
    }
    setEncryptFields(encryptFields)
  }
  // Handle Table fields to show in datatable
  const getTableFieldsOrder = () => {

    setTablefieldsToShow([]);
    setIsLoading(true);

    let columns = originalTableFields;
    let tempTableFields = [];
    let newTableFields = [];
    let staticTableFields = [...originalTableFields];

    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    } else {
      tempTableFields = originalTableFields
    }

    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      newTableFields.map(item => {
        // TableFieldsToShow.push(item)
        if (item.show && viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }


    setTablefieldsToShow(TableFieldsToShow);
    setIsLoading(false);
  }
  const changeCheckIcon = async (index, subIndex, key, value) => {
    let tablefieldsToShow = tablefieldsToShow;
    if (tablefieldsToShow[index]['options'][subIndex]['checkIcon'] === true) {
      let filterCriteria = Object.assign({}, filterCriteria);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj)
        let objValue = obj.value.find(y => y === value)
        let i = obj.value.indexOf(objValue);
        filterCriteria.criteria[objIndex]['value'].splice(i, 1);
        let length = filterCriteria.criteria[objIndex]['value'] ? filterCriteria.criteria[objIndex]['value'].length : 0;
        if (length === 0) {
          filterCriteria.criteria.splice(objIndex, 1)
        }
      }
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];

      setTablefieldsToShow(tablefieldsToShow);
      setFilterCriteria(filterCriteria);
      await onMultiSelectFilterChange(key, value, 'pop', index)
    } else {
      let filterCriteria = Object.assign({}, filterCriteria);
      tablefieldsToShow[index]['options'][subIndex]['checkIcon'] = !tablefieldsToShow[index]['options'][subIndex]['checkIcon'];

      setTablefieldsToShow(tablefieldsToShow);
      if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
        let obj = filterCriteria.criteria.find(x => x.key === key);
        let objIndex = filterCriteria.criteria.indexOf(obj);
        await onMultiSelectFilterChange(key, value, 'push', objIndex)
      } else {
        await onMultiSelectFilterChange(key, value, 'push', index)
      }
    }
  }
  const changeViewType = async (viewType) => {
    setViewType(viewType);
    await getTableFieldsOrder()
  }
  // Handle Table fields order of display in DataTable
  const updateColumnsOrder = (currentOrder) => {

    setIsLoading(true);
    let orgTableFields = [...originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...orgTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          orgTableFields.map(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile,
                  label: col.label ? col.label : col.header
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        unmatchedTableFields.map(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false,
              label: col.label ? col.label : col.header
            }
          )
        })
      }
    } else {
      orgTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });
    }

    /**@Fetch the listPreferences from loginCredentials */
    // let getListPreferences = JSON.parse(localStorage.getItem('loginCredentials'));
    // localStorage.setItem(`${formatPropType(props.type)}_column_order`, JSON.stringify(getListPreferences.listPreferences.columnOrder[`${formatPropType(props.type)}`]));
    getTableFieldsOrder(orgTableFields);
  }

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let dateFormat = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.dateFormat ? storeData.settingsData.settings.dateFormat : "DD-MM-YYYY"

    if (state.dateFormat != dateFormat) {
      return { dateFormat: dateFormat };
    }
    return { dateFormat: dateFormat };

  }

  const getLoginRole = () => {
    let loginData = localStorage.getItem('loginCredentials');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.role) {

        setLoginRole(loginData.role);
      }
    }
  }

  const updateSlNoToData = (itemdata, text) => {
    let modifiedData = [];
    let txt = text + 1;

    itemdata.forEach((item, index) => {
      if (props.flags) {
        let flags = props.flags
        if (item[flags['label']] == flags['value']) {
          modifiedData.push(item);
        }
      }

      else {

        item.Sno = txt;
        txt++;

        modifiedData.push(item);
      }
    });
    return modifiedData;
  }

  const updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat'] ? tablefieldsToShow[k]['dateFormat'] : dateFormat);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  //get table list data from server with filters if any
  const getDataFromServer = async (filterCriteria, type) => {
    let url = getAPIUrl();
    let dateFormat = props.dateFormat
    if (url) {
      let apiUrl;

      setIsLoading(true);
      setSelectedRows([]);
      if (type == 'refresh') {
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
          document.getElementById('globalSearch').value = '';
        }
        let fieldsToShow = tablefieldsToShow;
        if (fieldsToShow && fieldsToShow.length > 0) {
          for (let i = 0; i < fieldsToShow.length; i++) {
            let options = fieldsToShow[i].options;
            if (options && options.length) {
              for (let j = 0; j < options.length; j++) {
                options[j]['checkIcon'] = false
              }
            }
          }
        }

        setGlobalSearch('');
        setAllUsersData([]);
        setRoleType('');
        setTablefieldsToShow(fieldsToShow);

      }
      if (!filterCriteria || !filterCriteria['criteria']) {
        let filterCriteria = {};
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
      }

      if (type === 'refresh' && filterCriteria && Object.keys(saveCriteria).length <= 0) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = [];
        filterCriteria['direction'] = 'desc';
        filterCriteria['sortfield'] = 'created';
      }
      if (type === 'refresh' && filterCriteria && Object.keys(saveCriteria).length > 0) {
        delete filterCriteria.globalSearch;
        filterCriteria['criteria'] = saveCriteria.criteria;
        delete filterCriteria['direction'];
        filterCriteria['sortfield'] = 'created';
      }
      if (props.params) {
        let params = props.params
        for (let i in params) {
          if (i) {
            let obj = {}
            obj['key'] = i
            obj['value'] = params[i]
            obj['type'] = 'regexOr'
            filterCriteria['criteria'].push(obj)
          }
        }
      }
      //When a screen has a default filter to be applied
      if (props.defaultCriteria) {
        if (filterCriteria && filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          let newFilt = filterCriteria['criteria'];
          let dc = Array.from(props.defaultCriteria);
          for (let i = 0; i < newFilt.length; i++) {
            for (let j = 0; j < dc.length; j++) {
              if (newFilt[i].key == dc[j].key) {
                dc.splice(j, 1)
              }
            }
          }
          filterCriteria['criteria'] = filterCriteria['criteria'].concat(dc);
        }
        else {
          filterCriteria['criteria'] = props.defaultCriteria
        }
      }

      //applyng filter when a row item is deleted 
      if (filterCriteria == undefined) {
        filterCriteria = { limit: 20, page: 1, criteria: [], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (props.filterExtension) {
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${props.filterExtension}`
      }
      else if (props.hasQueryInCall) {
        apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
      }
      else {
        if (props.type === 'GroupEmails' && type !== 'search' && type !== 'pagination') {
          filterCriteria = { limit: 20, page: 1, criteria: [{ key: "isGroupEmail", value: true, type: "eq" }], direction: 'desc', softfield: 'created' };
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        } else if (props.type === 'Email Templates' && type !== 'search' && type !== 'pagination') {
          filterCriteria = { limit: 20, page: 1, criteria: [{ key: "isGroupEmail", value: true, type: "ne" }], direction: 'desc', softfield: 'created' };
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        } else if (props.type === 'LastLogins') {
          filterCriteria = { limit: 20, page: 1, criteria: [{ key: "contextType", value: 'LOGINSUCCESS', type: "eq" }], direction: 'desc', softfield: 'created' };
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
        }
        else {
          apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;

        }
      }


      if (foundation && foundation !== 'Both' && !actionsTypes) {
        apiUrl = `${url}?foundation_filter=${foundation}&filter=${JSON.stringify(filterCriteria)}`;
      }
      if (selectedStore && foundation && !actionsTypes) {
        apiUrl = `${url}?storeId=${selectedStore}&foundation_filter=${foundation === 'Both' ? null : foundation}&filter=${JSON.stringify(filterCriteria)}`;
      }

      if (payment && payment != 'all') {
        filterCriteria = { limit: 20, page: 1, criteria: [{ key: "payment_mode", value: payment, type: "eq" }], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }
      if (schoolType && schoolType != 'All') {
        filterCriteria = { limit: 20, page: 1, criteria: [{ key: "school_nameSearch", value: schoolType, type: "eq" }], direction: 'desc', softfield: 'created' };
        apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      }

      let limit = filterCriteria.limit;
      let page = filterCriteria.page;

      return fetchMethodRequest('GET', apiUrl)
        .then(async (response) => {
          let apiResponseKey = props.apiResponseKey;
          let frontScreenResponseKey = props.frontScreenResponseKey;
          let sessionexpired = await localStorage.getItem('sessionexpired')
          if (sessionexpired == "true") {
            setSessionExpiryModal(true);
          }
          let responseData = [];
          let recordsLength = 0;
          if (response && response.details && response.details.length > 0) {
            responseData = response.details;
            recordsLength = response.details.length;
          } else {
            if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
              if (response.pagination && response.pagination.totalCount) {
                recordsLength = response.pagination.totalCount;
                localStorage.setItem('totalRecords', recordsLength)
              }
              responseData = updateSlNoToData(response[apiResponseKey], limit * (page - 1));
              responseData = updateDateFormat(responseData, dateFormat);
            } else {
              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
                recordsLength = response.pagination.totalCount;
              }
            }
          }


          setAllUsersData(responseData);
          setIsLoading(false);
          if (filterCriteria.page === 1) {
            setTotalRecordsLength(recordsLength);
          }
          return responseData;
        }).catch((err) => {
          return err;
        });
    }
  }


  const getAPIUrl = () => {
    return props.apiUrl;
  }
  const getFilterUrl = (filterCriteria, type) => {
    return props.apiUrl;
  }

  //Get all data of current screen with filters applied from server to export to CSV
  const getDataToExport = async () => {
    setIsLoading(true);
    let filter = { ...filterCriteria };
    delete filter['limit'];
    delete filter['page'];
    let url = getAPIUrl();
    let apiUrl;
    apiUrl = `${url}?filter=${JSON.stringify(filter)}&type=exportToCsv`;
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        let sessionexpired = await localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true)
        }
        if (response && response[url]) {
          let exportData = response[url];
          exportData.forEach((item) => {
            for (let key in item) {
              let formFields = props.formFields();
              /**@Find the Corresponding @FormField based on @key in each iteration */
              let findFormFieldBasedOnEachKey = formFields.find(formField => formField.name === key)
              if (findFormFieldBasedOnEachKey) {

                /**@HandlingMultipleAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type == "relateAutoComplete" && findFormFieldBasedOnEachKey.isMultiple) {
                  if (item[key].length > 0) {
                    item[key] = item[key].map(arrItem => arrItem[findFormFieldBasedOnEachKey.searchField]).toString();
                  }
                }
                /**@HandlingAutoRelateFields */
                if (findFormFieldBasedOnEachKey.type === "relateAutoComplete" && !findFormFieldBasedOnEachKey.isMultiple) {
                  item[key] = item[key][findFormFieldBasedOnEachKey.searchField]

                  /**@Deletx`e the Key we are using @Search Ex {Project+Search} */
                  delete item[key + "Search"];
                }
                /**@HandlingDates */
                if (findFormFieldBasedOnEachKey.type === "date") {
                  // let date = item[key].split("T")[0].split("-").reverse().join("-");
                  let date = moment(item[key]).format(findFormFieldBasedOnEachKey?.dateFormat)
                  item[key] = date;
                }
              }

              /**@DeletingUnWantedInformation Like @_id @__V @createdBy @active {MONGO FIELDS USED IN BACKEND} */
              delete item._id;
              delete item.__v;
              delete item.createdBy;
              delete item.active;
            }
          });

          setExportData(exportData);
          setIsLoading(false);
          csvLinkRef.current.link.click();
        }
      }).catch((err) => {

        setIsLoading(false);
        return err;
      });
  }

  //open Form modal
  const openFormModal = async (rowData, type) => {
    setFormType(type ? type : 'add');
    setIsOpenFormModal(true);

    setSelectedRows([]);
    if (props.type === 'Families') {
      setIsOpenFamilyModal(true)

    }
    if (type === 'view') {
      if (isOpenFormModal && formModalRef) {
        await formModalRef.getViewData(rowData);
      }
    }
    if (type == 'edit') {
      setTableRowData(rowData);
      if (props.type === 'Stores') {
        setDisplayBreadCrumbValue(rowData.store_name ? rowData.store_name : '')
      }
      if (isOpenFormModal && formModalRef) {
        await formModalRef.getRowData(rowData, 'edit');
      }
    }
    if (type === "copy") {
      setTableRowData(rowData);
      if (isOpenFormModal && formModalRef) {
        await formModalRef.getRowData(rowData, "copy");
      }
    }
  }


  const sendGroupEmail = (item) => {
    localStorage.setItem("templateData", JSON.stringify(item));
    setIsDisplay(true)
  };

  //close form modal
  const closeFormModal = async () => {

    setIsOpenFormModal(false);
    setIsOpenFamilyModal(false)
    setRedirectToRoute(true);
  }

  const submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {
          setSessionExpiryModal(true);
        }

        setOpenDeleteModal(false);
        setActions('');
        setSelectedRows([]);
        setSelectedRowsId('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          getDataFromServer();
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }
  const handleActions = async (apiType) => {
    let ids = selectedRows?.map(id => id._id)
    // let apiUrl = apiUrl;
    let method, url, body = {}
    if (confirmType === 'Delete' || confirmType === 'Block' || confirmType === 'UnBlock' || confirmType === 'ResetFamily' || confirmType === 'ResetFaculty' || confirmType === 'Separated') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: ids
      }
    } else if (confirmType === "Delivered" || confirmType === "Cancelled" || confirmType === "Returned" || confirmType === "Exchanged") {
      url = `${apiUrl}/${apiType}`;
      method = "POST";
      body = {
        "order_id": selectedRowsId[0],
        "status": confirmType

      };
    } else if (confirmType === "Active" || confirmType === "Inactive") {
      url = `${apiUrl}/${apiType}`;
      method = "POST";
      body = {
        "selectedIds": ids,
        "status": confirmType

      };
    } else if (confirmType === "Invite") {
      url = `auth/invite`;
      method = "POST";
      body = {
        "selectedIds": ids,
        entityType: config.entityType,
      };
    }
    else if (confirmType === 'Archive') {
      url = `stores/archiveStore`;
      method = "POST";
      body = {
        "archiveStoreId": ids,
      };
    }
    submitActionsData(method, url, body)
  }
  // delete selected row
  const deleteSelectedRow = async () => {
    if (confirmType === 'Delete') {
      handleActions('multiDelete')
    } else if (confirmType === 'Block') {
      handleActions('multiblock?block=true')
    } else if (confirmType === 'UnBlock') {
      handleActions('multiblock?unblock=true')
    } else if (confirmType === 'ResetPassword') {
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    } else if (confirmType === 'ApprovePayment') {
      setOpenPaymentModal(true)
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    } else if (confirmType === 'ResetFamily') {
      handleActions('multiResetUsers')
      setSelectedRows(selectedRows);
    } else if (confirmType === "Delivered" || confirmType === "Cancelled" || confirmType === "Returned" || confirmType === "Exchanged") {
      handleActions("updateOrderStatus");
    }
    else if (confirmType === 'ResetFaculty') {
      handleActions('multiReset')
      setSelectedRows(selectedRows);
    } else if (confirmType === 'Separated') {
      handleActions('divorce')
      setSelectedRows(selectedRows);
    } else if (confirmType === "Active") {
      handleActions('updateStatus')
    } else if (confirmType === "Inactive") {
      handleActions('updateStatus')
    } else if (confirmType === "Archive") {
      handleActions('stores/archiveStore')
    }
    else if (confirmType === 'Invite') {
      handleActions('auth/invite')
    }
  }

  //close delete modal
  const closeDeleteModal = async () => {
    setOpenPaymentModal(false)
    setOpenDeleteModal(false);
    setOpenUserPasswordResetModal(false)
    setActions('');
  }
  const setDeleteRecords = async (rowData, selectActions) => {
    let selectedRowsId = [];
    selectedRowsId.push(rowData)
    setSelectedRows(selectedRowsId);
    setConfirmType("Delete");
    deleteConfirmAction(rowData, selectActions)
  }

  // conformation for delete item
  const deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })

      setSelectedRowsId(selectedRowsId);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
    if (rowData) {
      setDeleteRowDataId(rowData);
      setOpenDeleteModal(true);
      setSelectActions(selectActions);
    }
  }

  //change dropdown elememt
  const changeDropDownElement = (event) => {
    dt.filter(event.target.value, event.target.name, 'equals');
    //************ Check for solution


  }


  const getTableFieldItem = (field) => {
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
      //condition to match fieldType
      if (tablefieldsToShow[i].fieldType == field) {
        return tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  const getColorFromOptions = (options, name) => {
    console.log("options, name", options, name)
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if ((options[i].value == name) && options[i].color) {
          return options[i].color;
        }
      }
    }
    else if (name === 'Order Placed') {
      return 'primary'

    }
    else if (name === 'Inactive') {
      return 'warrm';

    } else if (name === '' || name === true) {
      return 'success';

    } else if (name === 'Pending' || name === false) {
      return 'danger';

    } else if (name === undefined) {
      return 'primary'

    } else {

      return 'success';
    }
  }
  const getUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  const getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  const getAUserData = (_id, type) => {
    let data = allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return data[i]
      }
    }
  }

  const onOpenViewModal = async (rowData, type, from) => {
    let rowDataIndex = getUserData(rowData['_id'], '_id');
    if (from == "route") { rowData = getAUserData(rowData['_id'], '_id') }
    let _id = rowData['_id'];
    let status = rowData['status'] ? rowData['status'] : ''
    let keys = Object.keys(rowData);

    let formFields = [];
    if (formFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, self = this, icon = false;

          // hari get all the labels from 
          keys.forEach(async function (key) {
            let labelKey = await self.getTableFieldItem(key);
            if (labelKey == null) {
              labelKey = key;
            } else {
              let val = rowData[key];
              if (labelKey.fieldType === 'icon') {
                val = self.getIconValue(rowData, labelKey);
                icon = true;
              }
              fieldType = labelKey.fieldType ? labelKey.fieldType : null
              searchField = labelKey.searchField ? labelKey.searchField : null
              let options = labelKey.options ? labelKey.options : []
              labelKey = labelKey.header
              if (val) {
                if (fieldType && searchField && fieldType == "relateAutoComplete") {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key][searchField],
                    fieldType: fieldType
                  }
                } else {
                  values = {
                    label: labelKey,
                    value: icon ? val : rowData[key],
                    fieldType: fieldType,
                    options: options
                  }
                }
                formFields.push(values);
                icon = false;
              }
            }

          });
        }
      }
      if (displayViewOfForm === 'modal') {
        console.log("LLLLLLLLLLLLLLLLLL")
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        // await viewModalRef.getRowData(formFields);
        await formModalRef.getViewData(formFields, 'view', rowDataIndex, rowData, _id);
      } else if (displayViewOfForm === 'screen') {
        let displayBreadCrumbField = getTableFieldItem('Link');
        setIsOpenFormModal(true);
        setUserStatus(status);
        setFormType(type);
        setDisplayBreadCrumbField(displayBreadCrumbField);
        setDisplayBreadCrumbValue(rowData[displayBreadCrumbField])

      }
    }
    await getDataFromServer();

  }

  const closeViewModal = async () => {

    setOpenViewModal(false);
  }
  //on changing pagination
  const onPageChange = async (event, type) => {
    let filter = { ...filterCriteria };
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filter['limit'] = event.rows;
      filter['page'] = currentPage;
      filter['criteria'] = saveCriteria.criteria;

      setRows(event.rows);
      setFirst(event.first);
      setPage(event.page);
    }
    setFilterCriteria(filter)
    let paginationItems = {
      rows: event.rows,
      first: event.first
    };

    localStorage.setItem('paginationItems', JSON.stringify(paginationItems))
    localStorage.setItem('filters', JSON.stringify(filter))
    getDataFromServer(filter, type)
  }

  const onPageChangeAccordion = async (event) => {
    let filterCriteria = filterCriteria;
    let id = openId;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;

    }
  }
  //sorting fields
  const sortChange = (event) => {
    setSelectedRows('');
    let sort = sortCount;
    let criteria = filterCriteria
    if (event && event['sortField']) {
      sort = sort == 0 ? sort + 1 : 0;
      let sortField = event['sortField'];
      criteria = {

        criteria: saveCriteria.criteria,
        direction: sort == 0 ? "desc" : 'asc',
        sortfield: sortField,
        limit: rows,
        page: page ? page : 1,
      }

      setIsLoading(true)
      setSortCount(sort);
      setFilterCriteria(criteria);
      getDataFromServer(criteria);
    }
  }

  const confirmActionType = async (type) => {
    if (type === 'Delete') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Delete ?')
      deleteConfirmAction()
    } else if (type === 'ApprovePayment') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Aprove Payment')
      deleteConfirmAction()
    } else if (type === 'ResetFamily') {
      setConfirmType(type);
      setConfirmModalText('Are you sure to reset family')
      deleteConfirmAction()
    } else if (type === 'ResetFaculty') {
      setConfirmType(type);
      setConfirmModalText('Are you sure to reset faculty')
      deleteConfirmAction()
    } else if (type === 'Active' || type === 'Inactive' || type === 'Invite' || type === 'Archive') {
      setConfirmType(type);
      setConfirmModalText(`Are you sure,you want to ${type} ?`)
      deleteConfirmAction()
    }
    else if (type === 'Separated') {
      setConfirmType(type);
      setConfirmModalText('Are you sure you want to create two households for this family?')
      deleteConfirmAction()
    } else if (type === "Delivered" || type === "Cancelled" || type === "Returned" || type === "Exchanged") {
      setConfirmType(type);
      setConfirmModalText(`${`Are you sure do you want to ${type} the order `}`)

      // await this.setState({
      //   confirmType: type,
      //   confirmModalText: this.state.actionsText[type] ? this.state.actionsText[type] : `${`Are you sure do you want to ${type} the order`}`,
      // // });
      deleteConfirmAction();
    }
    else if (type === 'Block') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block')
      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      setConfirmType(type);
      setConfirmModalText('Are you sure want to Block');
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
      setSelectedRows(selectedRows);
    } else if (type === 'Send Email') {

      setOpenSendEmailModal(true);
    }
  }

  //onActionsChange
  const onActionsChange = async (event, type) => {
    setFoundation(event.value)
    if (type == 'dropdownFilter') {
      for (let i in actionsTypes) {
        if (actionsTypes[i].value === event.target.value) {
          if (actionsTypes[i].action) {
            actionsTypes[i].action(selectedRows, actionsTypes[i].field, actionsTypes[i].value)
            return;
          }
        }
      }
      confirmActionType(event.target.value)
    }
  }


  const rowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

  const selectFilterTemplate = (options) => {
    return (
      <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
  };








  const onMultiSelectFilterChange = async (key, value, type, index) => {
    let filterCriteria = filterCriteria;
    if (filterCriteria && type === 'push') {
      let v = [], length = '';
      if (filterCriteria['criteria'] && filterCriteria['criteria'][index] && filterCriteria['criteria'][index]["value"]) {
        v = [...filterCriteria['criteria'][index]["value"]];
        length = v.length;
        v[length] = value;
        filterCriteria['criteria'][index]["value"] = v;
      } else {
        v[0] = value
        filterCriteria['criteria'].push({
          key: key,
          value: v,
          type: 'in'
        });
      }


      setFilterCriteria(filterCriteria)
      await getDataFromServer(filterCriteria)
    } else {
      getDataFromServer(filterCriteria)
    }
  }

  const convertToContextType = (str) => {
    const words = str.split(' ');
    words[0] = words[0].toLowerCase();
    const camelCased = words.join('');
    return camelCased;
  };

  const highlightFliter = (option) => {
    const filterInputFields = document.querySelectorAll('.p-column-filter-menu-button.p-link');
    filterInputFields.forEach(button => {
      const subjectText = button.closest('.p-column-header-content').querySelector('.p-column-title').textContent;
      const headerString = convertToContextType(subjectText)
      if (option == headerString) {
        return button.classList.add('p-column-filter-menu-button-active');
      }
    });
  }


  const onFilterColumns = (event) => {
    let res = FunnelFilter(event, fieldMap, "c", sortCount, sortFieldForFunnel, rows);
    setSaveCriteria(res);
    getDataFromServer(res);

    let filters = [];
    Object.keys(event.filters).forEach(key => {
      if (event.filters[key].constraints[0].value !== null && event.filters[key].constraints[0].value !== "" && event.filters[key].constraints[0].value.length !== 0) {
        filters.push(key);
        if (key == 'createdByName') {
          key = 'createdBy'
        } else if (key == 'updatedByName') {
          key = 'updatedBy'
        } else if (key == 'desc') {
          key = 'description'
        }
        highlightFliter(key)
      }
    });


  }

  const getOneDayQuery = (date) => {
    let todayDate = moment(new Date(date)).format('YYYY-MM-DD');
    return { $lte: new Date(todayDate + 'T23:59:59Z'), $gte: new Date(todayDate + 'T00:00:00Z') };
  }
  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };
  const getNextDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() + 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const formatDateFilters = (filterObj, field) => {
    if (filterObj.value && filterObj.value.length === 2) {
      if (filterObj.value[1] !== null) {
        const start = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const initialDate = getPreviousDate(start);
        const inputDateForNext = dateFormats.formatDate(filterObj.value[1], config.dayYearDateFormat);
        const nextDate = getNextDate(inputDateForNext);
        return [
          { key: field, value: initialDate, type: 'gte' },
          { key: field, value: nextDate, type: 'lt' }
        ];
      } else {
        const inputDate = dateFormats.formatDate(filterObj.value[0], config.dayYearDateFormat);
        const previousDate = getPreviousDate(inputDate);
        let date = getOneDayQuery(previousDate);
        return [{ key: field, value: previousDate, type: 'gte' }, { key: field, value: inputDate, type: 'lte' }]

      }
    } else {
      return [];
    }
  }




  // on search get data from server
  const onFilterChange = async (event, type) => {
    setIsLoading(true);
    setSelectedRows('');
    if (type === 'dropdownFilter' || type === 'date') {

    }
    let fieldName = '', filter = { ...filterCriteria },
      selectedFilterValue, selectedFilter, selecterFilterType, formattedTime, formattedDate, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        if (fieldName == 'date' || fieldName == 'created' || fieldName == 'updated') {
          isDateFilter = true;
          selectedFilterValue = selectedFilter && selectedFilter.value && selectedFilter.value.length == 10 ?
            selectedFilter.value : null;
          let date = dateFormats.addDaysToDate(selectedFilter.value, 1);
          formattedDate = dateFormats.formatDate(date, config.dayYearDateFormat);
          selecterFilterType = 'eq'
        } else {
          selecterFilterType = 'regexOr'
          selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : null;
        }
      } else {
        fieldName = event.target.name;
        selectedFilterValue = event && event.target && event.target.value ? event.target.value : null;
        setGlobalSearch(selectedFilterValue);
      }
      if (type == 'dropdownFilter') {
        selecterFilterType = 'in'
      }
      if ((type == 'dropdownFilter' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) || (type !== 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length >= 3)) {
        if (fieldName == 'createdBy') {
          fieldName = 'createdBy.name';
        }
        if (fieldName == 'phone') {
          fieldName = 'phone';
        }
        if (fieldName == 'globalSearch') {
          filter.globalSearch = {
            value: selectedFilterValue,
            type: 'user'
          }
          if (event.target.value.length == 0) {
            delete filter.globalSearch;
          }
        } else {
          if (selecterFilterType == 'gte') {
            let obj = filter.criteria.find(x => x.key == fieldName);
            let index = filter.criteria.indexOf(obj);
            if (index != -1) {
              filter['criteria'].splice(index, 1, {
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            } else {
              filter['criteria'].push({
                key: fieldName,
                value: formattedDate,
                type: 'eq'
              });
            }
          } else {
            if (fieldName == 'updated') {
              fieldName = 'updated.name'
            }
            if (filter['criteria'].length == 0 && selecterFilterType != 'lte') {
              filter['criteria'].push({
                key: fieldName,
                value: selectedFilterValue,
                type: selecterFilterType
              });
            } else {
              let obj = filter.criteria.find(x => x.key == fieldName);
              let index = filter.criteria.indexOf(obj);
              if (selecterFilterType == 'lte') {
                if (selectedFilterValue.length == 10) {
                  filter['criteria'].splice(0, 1, ({
                    key: fieldName,
                    value: selectedFilterValue.substring(6, 10) + '-' + selectedFilterValue.substring(3, 5) + '-' + selectedFilterValue.substring(0, 2) + 'T23:59:59Z',
                    type: selecterFilterType
                  }));
                }
              }
              if (index != -1 && selecterFilterType != 'lte') {
                filter['criteria'].splice(index, 1, {
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              } else if (selecterFilterType != 'lte') {
                filter['criteria'].push({
                  key: fieldName,
                  value: selectedFilterValue,
                  type: selecterFilterType
                });
              }
            }
          }

          setFilterCriteria(filter);
        }

        await getDataFromServer(filter, type)
      } else if (type === 'date' && selectedFilterValue && selectedFilterValue.length && selectedFilterValue.length > 0) {
        if (selectedFilterValue.length == 2) {
          let fieldIndex1 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          let fieldIndex2 = filter.criteria.findIndex((obj) => obj.key == fieldName)
          if (fieldIndex1 >= 0) filter.criteria.splice(fieldIndex1, 1)
          if (fieldIndex2 >= 0) filter.criteria.splice(fieldIndex2, 1)
          let startDate = (moment(selectedFilterValue[0]).format('YYYY-MM-DD'));
          let eDate = selectedFilterValue[1] ? JSON.parse(JSON.stringify(selectedFilterValue[1])) : JSON.parse(JSON.stringify(selectedFilterValue[0]))
          eDate = new Date(eDate)
          let endDate = eDate.setDate(eDate.getDate() + 1);
          endDate = (moment(endDate).format('YYYY-MM-DD'));
          filter['limit'] = 20;
          filter['page'] = 1;
          filter.criteria.push({ 'key': fieldName, 'value': startDate, 'type': 'gte' })
          filter.criteria.push({ 'key': fieldName, 'value': endDate, 'type': 'lte' })
        }

        setFilterCriteria(filter);
        await getDataFromServer(filter)
      } else {
        if ((selectedFilterValue == null || (type === 'dropdownFilter' && selectedFilterValue.length == 0)) && !isDateFilter) {
          let obj = filter.criteria.find(x => x.key == fieldName);
          let index = filter.criteria.indexOf(obj);
          filter.criteria.splice(index, 1);
          if (fieldName == 'globalSearch') {
            filter.globalSearch = {}
            delete filter.globalSearch
          }

          setFilterCriteria(filter);
          await getDataFromServer(filter)
        }
      }

      setIsLoading(false);
    }
  }

  //open Bulk Modal
  const bulkUploadMoadal = () => {
    // setState({ openBulkUploadModal: true })
    setOpenBulkUploadModal(true);
  }

  //close Bulk Modal
  const closeBulkModal = async () => {

    setOpenBulkUploadModal(false);
  }

  //select multiple rows to delete
  const onSelectRowsUpdate = async (event) => {
    if (isOpenFormModal && event.value.length > 0) {
      setSelectedRows([event.value[event.value.length - 1]])
    }
    else {
      setSelectedRows(event.value);
      await getActions(event.value)
    }
  }

  //openConfirmationModal
  const openConfirmationModal = async (rowData, status, type) => {

    setIsOpenFormModal(false);
    setOpenConfirmationModal(true);
    setItem(rowData);
    setConfirmText(type);
    setLeaveStatus(status);
  }

  const closeConfirmationModal = () => {

    setOpenConfirmationModal(false);
  }
  const setFilterCriteriaForActivities = async (editRowDataID) => {
    let filterCriteria = filterCriteria;
    filterCriteria['criteria'].push(
      { key: 'contextId', value: editRowDataID, type: 'eq' }
    )

    setFilterCriteria(filterCriteria);
  }
  const getTooltipFromOtptions = (options, name) => {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  const getArrayBadges = (badge_values) => {
    let str = [...badge_values].join(',');
    return str;
  }

  //change table body values//
  // hari need to move to derived class or controller
  const changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = getTableFieldItem(column.field);
    if (props.type === 'OrderSummaryReports' && tableItem && tableItem.name === 'store_name') {
      console.log("first if")
      if (item && item.store_name && item.store_name.store_name) {
        // console.log("item.store_name.store_name,,,,,,", item.store_name.store_name)
        return (
          // console.log("item....", item)
          <span>{item.store_name.store_name ? item.store_name.store_name : ''}</span>
        )
      }
    }
    if (props.type === 'RefundDetails') {
      if (tableItem && tableItem.name === 'qty' || tableItem.name === 'initialOrderedCost' || tableItem.name === "refundedAmount") {
        return (
          <span>{item[column.field] ? `$${item[column.field]}` : '$0'}</span>
        )
      }
    }
    if (tableItem && tableItem.name === "payment" && props.type === 'Schools') {
      if (item && item.hasFoundation) {
        return (
          <span>{item.hasFoundation ? item.hasFoundation : ''}</span>
        )
      }
    }
    if (tableItem && tableItem.fieldType === 'BoldText') {
      return (
        <span className='fw-bold'>{item[column.field]}</span>
      )
    }
    if (props.type === 'Orders' && tableItem && tableItem?.field === 'payment') {
      if (item && item?.ecommercePaymentId?.payment_method) {
        return (
          <span className='fw-bold'>{item?.ecommercePaymentId?.payment_method ? item?.ecommercePaymentId?.payment_method : ''}</span>

        )
      }
    }
    if (tableItem && tableItem.field === 'supporting_donations' && props.type === 'Payments') {
      return (
        <span>{item.supporting_donations ? item.supporting_donations : '0'}</span>
      )
    } if (tableItem && tableItem.field === 'isOtpVerified' && props.type === 'Users') {
      const value = item[column.field]
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      if (value && value === true) {
        return (
          <Badge color={mcolor} pill class="text-success fw-bold">{'Verfied'}</Badge>
        )
      } else {
        return (
          <Badge color={mcolor} pill class="text-danger fw-bold">{'Not Verified'}</Badge>
        )
      }
    }

    if (tableItem && tableItem.name === "product_name" && props.type === 'PendingCarts') {
      if (item && item.products) {
        const productNames = item.products.map(product => product.product_name).join(", ");
        return (
          <span>{productNames ? productNames : ''}</span>
        );
      }
    }

    if (tableItem && tableItem.name === 'District_name' && props.type === 'Families') {
      if (item && item?.childrens?.[0]?.district_nameSearch) {
        return (
          <span>{item?.childrens?.[0]?.district_nameSearch ? item?.childrens?.[0]?.district_nameSearch : ''}</span>
        )
      }
    }

    if (tableItem.fieldType == 'profile') {
      return <div className='textElipses'>
        {item && item[column.field] && item[column.field].length > 0 ?
          <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${item[column.field][0]}`} target="_blank" style={{ textDecoration: "none" }}>{item[column.field][0]}</Link> : null
        }
      </div>
    }

    if (tableItem.name && props.type === 'OrderSummaryReports') {
      if (item && item.child_details && item.child_details[0]) {
        if (tableItem.name === 'address1') {
          return (
            <span>{item.child_details[0].commonaddress ? item.child_details[0].commonaddress : ''}</span>
          )
        } else if (tableItem.name === 'phone') {
          return (
            <span>{item.child_details[0].phone1_mobile ? item.child_details[0].phone1_mobile : ''}</span>
          )
        } else if (tableItem.name === 'family') {
          return (
            <span>{item.child_details[0].lastname ? item.child_details[0].lastname : ''}</span>
          )
        } else if (tableItem.name === 'child_class_name') {
          return (
            <span>{item.child_details[0].class_nameSearch ? item.child_details[0].class_nameSearch : ''}</span>
          )
        } else if (tableItem.name === 'date') {
          return (
            <span>{item.child_details[0].created ? item.child_details[0].created : ''}</span>
          )
        }
      } if (item && item.pay_details && item.pay_details[0]) {
        if (tableItem && tableItem.name === "approvalCode") {
          return (
            <span>{item.pay_details[0].transactionId ? item.pay_details[0].transactionId : ''}</span>
          )
        } else if (tableItem && tableItem.name === "total") {
          return (
            <span>{item.pay_details[0].payment_amount ? item.pay_details[0].payment_amount : ''}</span>
          )
        } else if (tableItem && tableItem.name === "payment") {
          return (
            <b >{item.pay_details[0].payment_method ? item.pay_details[0].payment_method : ''}</b>
          )
        }
      } if (item && item.products && item.products[0]) {
        if (tableItem && tableItem.name === "comments") {
          return (
            <span>{item.products[0].comments ? item.products[0].comments : ''}</span>
          )
        }
      }
    }
    if (tableItem && tableItem.fieldType && tableItem.fieldName === "payment_types") {
      const value = item[column.field]
      const displayValue = Array.isArray(value) ? value.map((obj) => obj.name).join(',') : value;
      return (
        <span style={{ color: '#212529', fontWeight: 'bold' }}>{displayValue ? displayValue : ''}</span>
      )
    }
    if (tableItem && tableItem.field && tableItem.name === "preload" || tableItem.name === 'payments' || tableItem.name === 'isDuplicateStore' || tableItem.name === 'isDuplicateProduct') {
      const value = item[column.field]
      if (value && (value === 'true' || value === 'True')) {
        return (
          <span class="text-success fw-bold">{value}</span>
        )
      } else if (value && value === 'false') {
        return (
          <span class="text-danger fw-bold">{value}</span>
        )
      } else {
        return (
          <span>{item[column.field]}</span>
        )
      }
    }
    if (tableItem.fieldType == "Link") {
      return (
        <div className='textElipses'>
          <span title={item[column.field]}>
            {props.type === 'News' && column.field === 'School_Name' ? (
              <a href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`}>
                {item.school_name?.Name ? item.school_name?.Name : ''}
              </a>
            ) : (
              <a className='ViewModal_Font' href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`}>
                {item[column.field]}
              </a>
            )}
          </span>
        </div>
      );
    }
    if (tableItem.isClickable && tableItem.isClickable == true) {
      return <div className='textElipses'>
        <span
          title={item[column.field]}>
          <a href={`/view_${props.routeTo}/${JSON.stringify(item._id)}`} >  {item[column.field]}</a>
        </span>
      </div >
    }
    else if (tableItem.type === "time") {
      console.log("KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK")
      const timestamp = item[column.field];
      if (timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        let period = 'AM';
        if (hours >= 12) {
          period = 'PM';
          if (hours > 12) {
            hours -= 12;
          }
        }
        // Format the time as "hh:mm AM/PM"
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
        return <span style={tableItem.style} title={formattedTime}>
          {formattedTime}
        </span>;
      }
    }
    else if (tableItem.fieldType == "WebSite") {
      return <div className='textElipses'>
        <a href={item[column.field]} title={item[column.field]} target="_blank">{item[column.field]}</a>
      </div >
    }
    else if (tableItem.fieldType === "Download") {
      if (item[column.field]) {
        return (
          <a
            href={`${config.imgUrl}/${item[column.field]}`}
            download
            style={{ textDecoration: "underline" }}
          >
            <FontAwesomeIcon
              icon='download'
              size='sm'
              style={{ marginRight: 5 }}
            />
            {item[column.field]}
          </a>
        );
      }
    }
    else if (!tableItem.isMultiSelect && tableItem.fieldType == "dropDown") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      if (props.type === 'Districts' || props.type === 'ClassRooms') {
        return (
          <span>{item[column.field]}</span>
        );
      } else {
        return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>;
      }
    } else if (tableItem.isMultiSelect && tableItem.fieldType === "dropDown" && item[column.field]) {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      if (props.type === 'Districts') {
        return (
          <span>{item[column.field]}</span>
        );
      } else {
        return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>;
      }
    }
    else if (tableItem.fieldType == "Badge") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} pill style={tableItem.style}>{item[column.field]}</Badge>
    } else if (tableItem.fieldType == "Role") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
    } else if (tableItem.fieldType === "icon") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      let tooltip = getTooltipFromOtptions(tableItem.options, item[column.field]);
      return <FontAwesomeIcon
        style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
        color='white'
        icon={tableItem.iconName}
        data-toggle="tool-tip"
        title={tooltip}
      />
    } else if (tableItem.fieldType === 'isDateFormatRequired') {
      const val = item[column.field];
      if (val) {
        const date = new Date(val);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return <span>{formattedDate}</span>;
      } else {
        return <span>-</span>;
      }
    } else if (tableItem && tableItem.fieldType && tableItem.fieldName === 'preload') {
      const value = item[column.field]
      if (value && value === 'true' || value === 'True') {
        return (
          <span class="text-success fw-bold">{value}</span>
        )
      } else if (value && value === 'false') {
        return (
          <span class="text-success fw-bold">{value}</span>
        )
      } else {
        return (
          <span>{item[column.field]}</span>
        )
      }
    }

    else if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else if (tableItem.fieldType === "Complex") {
      let data = ObjectbyString(item, column.field)
      return <span>
        {data}
      </span>
    }
    else if ((props.type === 'PendingCarts' || props.type === 'Orders' || props.type === 'RefundDetails') && tableItem.fieldType == "Actions") {
      let mcolor = getColorFromOptions(tableItem.options, item[column.field]);
      return (
        <div>
          {(props.deleteRequired && props.type === 'PendingCarts') ?
            <FontAwesomeIcon
              className='genderIconAlignment'
              color='white'
              icon='trash-alt'
              data-toggle="tool-tip"
              title="Delete"
              style={{ color: '#bf1725', width: '13', marginRight: 25, cursor: 'pointer' }}
              onClick={() => setDeleteRecords(item, "Delete")} />
            : null}
          <Badge color={mcolor} onClick={() => openFormModal(item, 'view')}>
            <span>View Details</span>
          </Badge>
        </div>
      )
    }
    else if (tableItem.fieldType == "Actions") {
      return (
        <div className='row'
          style={{ justifyContent: 'center' }}>
          <div>
            {(props.preview) ? <FontAwesomeIcon
              className='genderIconAlignment'
              color='#17a2b8'
              icon='file'
              data-toggle="tool-tip"
              title='Preview'
              style={{ color: '#17a2b8', width: '15', cursor: 'pointer', marginRight: 9 }}
              onClick={() => openPreviewModal(item)} /> : ''}
            {(props.editRequired) ? <FontAwesomeIcon
              className='genderIconAlignment'
              color='white'
              icon='edit'
              data-toggle="tool-tip" title="Edit"
              style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
              onClick={() => openFormModal(item, 'edit')} /> : ''}
            {(props.deleteRequired) ?
              <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='trash-alt'
                data-toggle="tool-tip"
                title="Delete"
                style={{ color: '#bf1725', width: '13', marginLeft: 20, cursor: 'pointer' }}
                onClick={() => setDeleteRecords(item, "Delete")} />
              : null}
            {/* //resetRequied */}
            {props.copyRequired && (
              <FontAwesomeIcon
                color="#22c2ad"
                style={{ width: '13', marginLeft: 20, cursor: 'pointer' }}
                icon="copy"
                data-toggle="tool-tip"
                title="Copy"
                onClick={() => openFormModal(item, "copy")}
              />
            )}
            {props.sendRequired && (
              <FontAwesomeIcon
                color="yash"
                icon="share"
                style={{ width: '13', marginLeft: 20, cursor: 'pointer' }}
                data-toggle="tool-tip"
                title="Share"
                onClick={() => sendGroupEmail(item)}
              />
            )}
          </div>
          {/* } */}
        </div >
      )
    } else if (tableItem.fieldType == "relateAutoComplete") {
      if (tableItem.isMultiple) {
        let data = ""
        if (tableItem.searchField && item[column.field] && item[column.field].length > 0) {
          for (let obj of item[column.field]) {
            data = obj[tableItem.searchField] + "," + data
          }
        }
        return <span style={tableItem.style} title={data} >
          {data}
        </span >
      } else {
        return tableItem.searchField && item[column.field] && item[column.field][tableItem.searchField] ?
          <span style={tableItem.style} title={item[column.field][tableItem.searchField]} >
            {item[column.field][tableItem.searchField]}
          </span >
          : null;
      }

    } else if (tableItem.fieldType == "RACSubField") {

      return tableItem.parentField && item[tableItem.parentField] && item[tableItem.parentField][tableItem.subFieldName] ?
        <span style={tableItem.style} title={item[tableItem.parentField][tableItem.subFieldName]} >
          {item[tableItem.parentField][tableItem.subFieldName]}
        </span >
        : null;
    } else {

      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return <span style={tableItem.style} title={item[column.field]} >
          {item[column.field]}
        </span >
      }
    }
  }
  const clickConfirm = () => {
    closeConfirmationModal();
  }
  const ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  const rowClassName = (item) => {
    if (item.status === 'Blocked') {
      return (
        { 'p-highlightRow': (item.status === 'Blocked') }
      )
    }
  }
  const openPreviewModal = async (item) => {

    setIsPreviewModal(true);
    setEmailTemplateData(item ? item : item);
  }
  const closePreviewModal = () => {

    setIsPreviewModal(false);
    setEmailTemplateData('');
  }
  const closeSendEmailModal = () => {

    setOpenSendEmailModal(false);
    setActions('');
  }
  const handleDateInterval = (startDate, endDate) => {
    if (startDate) {

      setStartDate(startDate);
    }
    if (endDate) {

      setEndDate(endDate);
    }
  }


  const onPaymentSelection = (e, type) => {
    if (type === 'paymentType') {
      const selectedValue = e ? e.value : 'all';
      setPayment(selectedValue);
      filterPaymentData(selectedValue, paymentDetails);
    }
    if (type === 'schoolType') {
      const selectedValue = e ? e.value : 'All';
      setSchoolType(selectedValue);


    }
  };


  const filterPaymentData = (value, data) => {
    if (value === 'All') {
      const aggregated = data.reduce((acc, record) => ({
        total_amount: acc.total_amount + record.total_amount,
        total_pta_dues: acc.total_pta_dues + record.total_pta_dues,
        total_suppporting_donations: acc.total_suppporting_donations + record.total_suppporting_donations
      }), { total_amount: 0, total_pta_dues: 0, total_suppporting_donations: 0 });
      setFilteredPayment(aggregated);
    } else {
      const filtered = data.find(record => record._id === value) || {
        total_amount: 0,
        total_pta_dues: 0,
        total_suppporting_donations: 0
      };
      setFilteredPayment(filtered);
    }
  };

  // ShowHideColumns Button Click Handler
  //Settings Modal open
  const openShowHideColumnsModal = () => {

    setIsOpenShowHideColumnsModal(true);
  }
  const getSettings = async () => {
    props.getSettings();
  }

  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal
  const closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];


      setIsOpenShowHideColumnsModal(false);
      if (viewType === 'grid') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.mobile = value;
            }
          });
        })
      }
      if (viewType === 'list') {
        fields.map(async item => {
          Object.entries(columnFields).forEach(async ([key, value]) => {
            if (item.field === key) {
              return item.show = value;
            }
          });
        })
      }
      fields.map(async col => {
        updatedOrder.push(
          {
            ...col,
            field: col.field,
            show: col.show,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
            label: col.label ? col.label : col.header
          }
        )
      });

      /**GetListPreferenceId */
      // let employeeId = JSON.parse(localStorage.getItem('loginCredentials'));
      let setPreferenceValue = {};
      setPreferenceValue[formatPropType(props.type)] = updatedOrder;
      fetchMethodRequest("PUT", `listPreferences/${listPreferences}`, setPreferenceValue)
      setOriginalTableFields(updatedOrder);
      setTimeout(() => {
        setTablefieldsToShow(updatedOrder);
      }, 500)
      await getTableFieldsOrder(originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];

      setIsOpenShowHideColumnsModal(false);

      await getTableFieldsOrder(originalTableFields);
    } else {

      setIsOpenShowHideColumnsModal(false);
    }
  }
  const searchInDateRange = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format(config.dateDayMonthFormat));
      let endDate = (moment(endDate).format(config.dateDayMonthFormat));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        {
          'key': 'date',
          'value': startDate,
          'type': 'gte'
        },
        {
          'key': 'date',
          'value': endDate,
          'type': 'eq'
        }];
      setFilterCriteria(filterCriteria);
      getDataFromServer(filterCriteria);
    } else {
      alert('no dates selected');
    }
  }

  const toggle = async (id) => {
    let opId = openId === id ? '' : id;
    setOpenId(opId);
  }

  const print = () => {
    window.print();
  }
  const toogleMultiSelect = async () => {

    setShowMultiSelectDropDown(!showMultiSelectDropDown);
  }
  const onGlobalSearchChange = async (e) => {
    let suggestions = suggestions;
    suggestions[0] = { "label": `Search By First Name ${e.query}` }
    setSuggestions(suggestions);
  }


  useEffect(() => {
    let criteria = {
      limit: 20,
      page: 1,
      criteria: [],
      sortfield: 'created',
      direction: 'desc',
    };
    setFilterCriteria(criteria);
    getDataFromServer(criteria);
    setIsLoading(false);
  }, [foundation, selectedStore, payment, schoolType]);


  const categoryFilterValues = (e, type) => {
    if (type === 'dropdownFilter') {
      const selectedValue = e ? e.value : 'Both';
      setFoundation(selectedValue);
    }
  };


  const onFilterStore = (e, type) => {
    if (type === 'Store') {
      const storevalue = e.value;
      setSelectedStore(storevalue);
    }
  };






  const getScreenHeader = () => {
    const { t } = props;
    return (
      <div className='pb-4'>
        <div className='d-flex justify-content-between align-items-center'>
          <h4>
            <span className='postionRelative pt-2'>
              <a onClick={() => closeFormModal} className='heading'>
                {t(props.displayName)}
              </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
            </span>
          </h4>
          <div className="row">
            {/* global Search */}
            <div className='col-8'>
              {(props.dateSearchRequired) ?
                <div className='d-flex gap-2'>
                  <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                  </RenderIntervalDatePickerField>&nbsp;
                  <Button className="date-search col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
                </div>
                : null}
            </div>
            <div className='col-md-12 d-flex justify-content-end' style={{ height: '80%' }}>
              {props.filterRequired ? <span className='mr-3' ref={closeMultiSelectDropdown}>
                <span className='multiSelectDropDownCard '>
                  <span onClick={toogleMultiSelect}>
                    {t('Filters')}
                  </span>
                  <span onClick={toogleMultiSelect}>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className='ml-1'
                      color='grey'
                    />
                  </span>
                  {
                    showMultiSelectDropDown ?
                      <Card className=' itemsWarperCard' >
                        {
                          tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                            return (
                              item.displayInFilter && item.show && < div >
                                <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                <ul className='pl-0'>
                                  {
                                    item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                      return (
                                        <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                          <span className='chekcIconAdjust'>
                                            <Checkbox
                                              checked={optionsItem.checkIcon}>
                                            </Checkbox>

                                          </span>
                                          <span className='chekcIconAdjustLabel'>
                                            {t(optionsItem.label)}
                                          </span>
                                        </div>
                                      )
                                    }) : null
                                  }
                                </ul>
                                <hr className='my-0'></hr>
                              </div>
                            )
                          }) : null
                        }
                      </Card>
                      : null}
                </span>

              </span> : null}
              {props.isPayment ? <Dropdown
                style={{ minWidth: '10%', lineHeight: 1, marginTop: '2px', marginRight: '12px' }}
                className='mr-3'
                name='actions'
                value={payment}
                options={[
                  { label: "All", value: "all" },
                  { label: "Online", value: "online" },
                  { label: "Offline", value: "offline" },
                ]}
                onChange={(e) => onPaymentSelection(e, 'paymentType')}
              /> : null}
              {actionsTypes && actionsTypes.length > 0 ? <Dropdown
                style={{ minWidth: '10%', lineHeight: 1, marginTop: '2px', marginRight: '12px' }}
                className='mr-3'
                // appendTo={document.body}
                name='actions'
                value={actions}
                disabled={selectedRows && selectedRows.length > 0 ? false : true}
                options={actionsTypes}
                placeholder={t('Actions')}
                onChange={(e) => onActionsChange(e, 'dropdownFilter')}
              /> : null}
              {props.showSchoolDropdown ? (
                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1, marginTop: '2px', marginRight: '12px' }}
                  className='mr-3'
                  name="school_name"
                  value={schoolType}
                  // options={props.schools}
                  options={transformedSchools}
                  onChange={(e) => onPaymentSelection(e, 'schoolType')}
                />
              ) : null}
              <div className='d-flex justify-content-between align-items-center gap-2'>
                {props.printRequried ?
                  <Button
                    raised
                    size={"sm"}
                    style={{ height: '2rem' }}
                    onClick={() => print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      // className='pl-1'
                      size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                    />
                  </Button>
                  : null}
                {props.exportRequired &&
                  <Button
                    size={"sm"}
                    style={{ height: '2rem' }}
                    onClick={getDataToExport}>
                    <FontAwesomeIcon
                      icon='file'
                      data-toggle="tool-tip" title={t("Export To CSV")}
                      // className='pl-1'

                      size='lg' />
                  </Button>
                }
                {props.exportRequired &&
                  <CSVLink
                    data={exportData}
                    filename={`${props.type}.csv`}
                    className="hidden text-white"
                    ref={csvLinkRef}
                    target="_blank" >
                  </CSVLink>}
                {props.sample ?
                  <Button
                    size={"sm"}
                    style={{ height: '2rem' }}
                    onClick={() => bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => bulkUploadMoadal()} />
                  </Button>
                  : null}
                {props.settingsRequired ?
                  <Button
                    size={"sm"}
                    style={{ height: '2rem' }}
                    onClick={() => openShowHideColumnsModal()}
                  >
                    <FontAwesomeIcon
                      icon='cog'
                      // className='pl-1' 
                      size='lg'
                      data-toggle="tool-tip" title={t("Settings")}
                      onClick={() => openShowHideColumnsModal()}
                    />
                  </Button> : null}

                {/* Add button1 */}
                {props.addRequired ?
                  // <a href={`/create_${props.routeTo}`}  >
                  <Button
                    size={"sm"}
                    className='button-add'
                    style={{ height: '2rem' }}
                    onClick={() => openFormModal({}, 'add')}
                  >
                    <FontAwesomeIcon
                      icon='plus'
                      style={{ marginBottom: '5px' }}
                      size='lg'
                      data-toggle="tool-tip" title={t("Add")}
                    // onClick={() =>   openFormModal('add')}
                    />
                  </Button>
                  // </a>
                  : null}

                {/* priority  in Issues*/}
                {!isOpenFormModal &&

                  <Button
                    color="secondary"
                    size={"sm"}
                    style={{ height: '2rem' }}

                    // className="p-1 ml-auto"

                    onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                    <FontAwesomeIcon
                      icon='sync-alt'
                      size='lg'
                      data-toggle="tool-tip" title={t("Refresh")}
                      color={config.templateColor}
                      className='refreshIcon pl-1' />
                  </Button>}
                {
                  // !isOpenFormModal && 
                  props.globalSearch ?
                    <div className='serachAlignment'>
                      <div className="p-inputgroup float-right" style={{ width: '30rem' }}>
                        <span className="p-inputgroup-addon">
                          < i className="pi pi-search" ></i >
                        </span>
                        <InputText
                          type="search"
                          name='globalSearch'
                          id='globalSearch'
                          value={globalSearch}
                          onChange={(e) => onFilterChange(e, "search")}
                          placeholder={props.globalSearch ? (props.globalSearch) : 'search'}
                          size="25" />
                      </div>
                    </div>
                    : null
                }
                {['Schools', 'Stores', 'Orders', 'Products', 'OrderSummaryReports',
                  'RefundDetails', 'OrdersByProducts', 'OrdersByStores',
                  'OrdersByStoreAndProducts', 'PendingCarts', 'EcommercePayments', 'OrdersSummaryByGrades', 'OrdersSummaryByClasses'].includes(props.type) && (
                    <Dropdown
                      style={{ minWidth: '10%', lineHeight: 1 }}
                      className='mr-3'
                      value={foundation}
                      options={[
                        { label: "Foundation", value: "Foundation" },
                        { label: "PTA", value: "Non-Foundation" },
                        { label: "Both", value: "Both" }
                      ]}
                      placeholder={"Select Foundation"}
                      onChange={(e) => categoryFilterValues(e, 'dropdownFilter')}
                    />
                  )}
                {(props.type === 'OrdersSummaryByGrades' || props.type === 'OrdersSummaryByClasses') && (
                  <Dropdown
                    style={{ minWidth: '10%', lineHeight: 1 }}
                    className='mr-3'
                    name='Select Store'
                    value={selectedStore}
                    options={props.schoolOptions}
                    placeholder={'Select Store'}
                    onChange={(e) => onFilterStore(e, 'Store')}
                  />
                )}
              </div>
            </div>
          </div >

          {config.paginationPosition == 'top' ?
            <div className='row mt-3'>
              <span className='col-md-7'></span>
              <span className='col-md-5'>{config.paginationPosition == 'top' ? getPaginator() : null}</span>
            </div>
            : null}
        </div>
        {props.type && props.type === 'Payments' && (
          <div>
            <div className="row">
              <h5 className="paymentColor">
                Total Amount :
                <span className="blackColor pl-2">
                  {filteredPayment.total_amount ? filteredPayment.total_amount : '0'}
                </span>
              </h5>
            </div>
            <div className="row">
              <h5 className="paymentColor">
                Total Pta Dues :
                <span className="blackColor pl-2">
                  {filteredPayment.total_pta_dues ? filteredPayment.total_pta_dues : '0'}
                </span>
              </h5>
            </div>
            <div className="row">
              <h5 className="paymentColor">
                Total Supporting Donations :
                <span className="blackColor pl-2">
                  {filteredPayment.total_suppporting_donations ? filteredPayment.total_suppporting_donations : '0'}
                </span>
              </h5>
            </div>
          </div>
        )}

      </div >
    )
  }
  const onGlobalSerachFilterChange = async (event) => {
    if (event) {

      await onFilterChange(event)
    }

  }
  const getHeader = () => {
    const { t } = props;
    return (


      <div>
        <h4><span className='postionRelative pt-2'>
          <b
          ><a onClick={() => closeFormModal} >
              {t(props.type)}
            </a>{isOpenFormModal && displayViewOfForm === 'screen' ? ` / ${formType} ` : null}
          </b>
        </span>
        </h4>
        <div className="row">
          {/* global Search */}
          <div className={(props.dateSearchRequired) ? "col-9" : 'col-6'}>
            {(props.dateSearchRequired) ?
              <div className='row'>
                <RenderIntervalDatePickerField handleDateValueInParent={handleSearchDateInterval}>
                </RenderIntervalDatePickerField>
                <Button className="col-sm-2 mb-0" color="primary" onClick={searchInDateRanges}>{t('dataTable.table_head.search')}</Button>
              </div>
              : null}
          </div>
          {/**@modalFix */}

        </div >
        <div className='row'>
          {/* global Search */}
          <div className='col-sm-12'>

          </div>
          <div className='col-12  pb-1'>
            <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {config.paginationPosition == 'top' ? getPaginator() : null}
              </div>
            </span>
            <span className='pt-2'>
              {/* Export to csv */}
              <span className='float-left pt-2' >
                {props.filterRequired ?
                  <span className='mr-3' ref={closeMultiSelectDropdown}>
                    <span className='multiSelectDropDownCard '>
                      <span onClick={toogleMultiSelect}>
                        {t('Filters')}
                      </span>
                      <span onClick={toogleMultiSelect}>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className='ml-1'
                          color='grey'
                        />
                      </span>
                      {
                        showMultiSelectDropDown ?
                          <Card className=' itemsWarperCard' >
                            {
                              tablefieldsToShow && tablefieldsToShow.length > 0 ? tablefieldsToShow.map((item, index) => {
                                return (
                                  item.displayInFilter && item.show && < div >
                                    <p className='multiSelectDropDownCardUl'>{t(item.header)}</p>
                                    <ul className='pl-0'>
                                      {
                                        item.options && item.options.length > 0 ? item.options.map((optionsItem, optinsIndex) => {
                                          return (
                                            <div className='multiSelectWrapperItems' onClick={() => changeCheckIcon(index, optinsIndex, item.field, optionsItem.value)}>
                                              <span className='chekcIconAdjust'>
                                                <Checkbox
                                                  checked={optionsItem.checkIcon}>
                                                </Checkbox>

                                              </span>
                                              <span className='chekcIconAdjustLabel'>
                                                {t(optionsItem.label)}
                                              </span>
                                            </div>
                                          )
                                        }) : null
                                      }
                                    </ul>
                                    <hr className='my-0'></hr>
                                  </div>
                                )
                              }) : null
                            }
                          </Card>
                          : null}
                    </span>

                  </span>
                  : null}




                <Dropdown
                  style={{ minWidth: '10%', lineHeight: 1.3, marginTop: '2px' }}
                  className='mr-3'
                  // appendTo={document.body}
                  name='actions'
                  value={actions}
                  disabled={selectedRows && selectedRows.length > 0 ? false : true}
                  options={actionsTypes}
                  placeholder={t('Actions')}
                  onChange={(e) => onActionsChange(e, 'dropdownFilter')}
                />

                {props.printRequried ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 ml-auto  mb-0 mt-1"
                    onClick={() => print()}
                  >
                    <FontAwesomeIcon
                      icon='print'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Print")}
                      onClick={() => print()}
                    />
                  </Button>
                  : null}
                {props.exportRequired && <Button color="primary"
                  className='p-1 ml-auto mb-0 mt-1'
                  size={'sm'} onClick={getDataToExport}>
                  <FontAwesomeIcon
                    icon='file'
                    data-toggle="tool-tip" title={t("Export To CSV")}
                    className='pl-1' size='lg' />
                </Button>}

                {props.sample ?
                  <Button color="primary"
                    size="sm"
                    className="p-1 mt-1 mb-0"
                    onClick={() => bulkUploadMoadal()}>
                    <FontAwesomeIcon
                      icon='upload'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Bulk Upload")}
                      onClick={() => bulkUploadMoadal()} />
                  </Button>
                  : null}

                {props.gridRequried && <ButtonGroup className='mb-0 mr-3'>
                  <Button color="primary"
                    outline={viewType === 'grid' ? false : true}
                    size="sm"
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => changeViewType('grid')}
                  >
                    <FontAwesomeIcon
                      icon='grip-horizontal'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("Grid")}
                      onClick={() => changeViewType('grid')}
                    />
                  </Button>
                  <Button color="primary"
                    size="sm"
                    outline={viewType === 'list' ? false : true}
                    className="p-1 ml-auto mt-1 mb-0"
                    onClick={() => changeViewType('list')}
                  >
                    <FontAwesomeIcon
                      icon='list'
                      className='pl-1' size='lg'
                      data-toggle="tool-tip" title={t("List")}
                      onClick={() => changeViewType('list')}
                    />
                  </Button>
                </ButtonGroup>}
                {props.settingsRequired && <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title={t("Settings")}
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>}

                {/* Add button */}
                {props.addRequired ?
                  <a href={`/create_${props.routeTo}`} >
                    <Button color="primary"
                      size="sm"
                      className="p-1 ml-auto mt-1 mb-0"
                    // onClick={() => openFormModal('add')}
                    >
                      <FontAwesomeIcon
                        icon='plus'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title={t("Add")}
                      // onClick={() => openFormModal('add')}
                      />
                    </Button></a>
                  : null}

                {/* priority  in Issues*/}
                <Button
                  color="secondary"
                  size="sm"
                  // className="p-1 ml-auto"
                  className={props.addRequired ? "p-1 mt-1 mb-0" : 'p-1 ml-auto mt-1 mb-0'}
                  onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
                  <FontAwesomeIcon
                    icon='sync-alt'
                    size='lg'
                    data-toggle="tool-tip" title={t("Refresh")}
                    color={config.templateColor}
                    className='refreshIcon pl-1' />
                </Button>

              </span>
            </span>
          </div>
        </div>

      </div >
    )
  }
  const getCardHeader = () => {
    return (
      // <CardHeader className='cardHeader'>
      <div className="row m-0">
        {/* table name */}
        <div className='col-3 pl-0 pt-2'>
          <h4><b>{props.type}{props.type == 'Activiti' ? 'es' : ''}</b></h4>
        </div>
        <span className='col-9 text-right'>
          <Row>

            {props.settingsRequired ?
              <Button
                raised
                size="sm"
                className="p-1 ml-auto mt-1"
                onClick={() => openShowHideColumnsModal()}
              >
                <FontAwesomeIcon
                  icon='cog'
                  className='pl-1' size='lg'
                  data-toggle="tool-tip" title="Settings"
                  onClick={() => openShowHideColumnsModal()}
                />
              </Button>
              : null}
            {/* Add button */}
            {props.addRequired ?
              <a href={`/create_${props.routeTo}`} >
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto"
                >
                  <FontAwesomeIcon
                    icon='plus'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Add"
                  />
                </Button></a>
              : null}
            {/* priority  in Issues*/}
            <Button
              color="secondary"
              size="sm"
              className={props.addRequired ? "p-1" : 'p-1 ml-auto'}
              onClick={() => getDataFromServer(filterCriteria, 'refresh')}>
              <FontAwesomeIcon
                icon='sync-alt'
                size='lg'
                data-toggle="tool-tip" title="Refresh"
                color={config.templateColor}
                className='refreshIcon pl-1' />
            </Button>
          </Row>
        </span>

      </div>
    )
  }
  const cancelUserPwdResetModal = async () => {

    setOpenUserPasswordResetModal(false);
    setActions('');
  }
  const handleSearchDateInterval = (startDate, endDate) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  }

  const searchInDateRanges = () => {
    if (startDate && endDate) {
      let filterCriteria = {};
      let startDate = (moment(startDate).format('YYYY-MM-DD'));
      let endDate = (moment(endDate).format('YYYY-MM-DD'));
      filterCriteria['limit'] = 20;
      filterCriteria['page'] = 1;
      filterCriteria['criteria'] = [
        { 'key': searchInDateRangeField, 'value': startDate, 'type': 'gte' },
        { 'key': searchInDateRangeField, 'value': endDate, 'type': 'lte' }];
      getDataFromServer(filterCriteria);
    }
    else {
      alert('no dates selected');
    }
  }

  const getColumns = (e, d) => {
    const { t } = props

    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        if (item.type == "relateAutoComplete") item.filter = true;
        let column = (item.show &&
          // return(
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,

            }}
            bodyStyle={item.capitalizeTableText ? {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            } : {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            editRequired={e}
            deleteRequired={d}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
            }}
            filter={item.filter ? item.filter : false}
            dataType={item.type == "date" ? "date" : item.type == "number" ? "numeric" : "text"}
            showFilterMatchModes={item.type == "dropDown" || item.type == "radio" ? false : true}
            showClearButton={false}
            showApplyButton={(item.type == "dropDown" || item.type == 'date' || item.type == 'radio') ? false : true}

            sortable={item.sortable ? true : false}
            filterElement={item.filterElement ?
              multiSelectFilterTemplate


              : item["type"] == "date" ?
                dateFilterTemplate

                : null
            }
            selectionMode={item.selectionMode}
          />)
        // )

        return column;
      })
    }

  }

  const multiSelectFilterTemplate = (options) => {
    let filterOptions = [];
    filterOptions = getMultiSelectOptions(options);
    return <MultiSelect value={options.value} options={filterOptions} maxSelectedLabels={1} optionallabel="label"
      onChange={(e) => options.filterApplyCallback(e.value, options.index)} placeholder="Any"
      onHide={() => {
        if (options.value && options.value.length > 0) {
          highlightFliter(options.field)
        }
      }} />;
  };

  const getMultiSelectOptions = (options) => {
    let tableFields = props.getTableFields();
    let i = tableFields.findIndex((col) => col.field === options.field);
    if (i > -1 && tableFields[i].filterElement) {
      return tableFields[i].filterElement;
    } else {
      return [];
    }
  }

  const dateFilterTemplate = (options) => {
    return <Calendar value={options.value} showButtonBar selectionMode="range" onChange={(e) => options.filterApplyCallback(e.value, options.index)} />;
  };

  const getDataTable = () => {


    return (
      <DataTable
        rowClassName={rowClassName}

        // ref={(el) => dt = el}
        value={allUsersData}
        // header={getHeader()}
        totalRecords={totalRecordsLength}
        paginator={false}
        lazy={true}
        size="small"
        resizableColumns={true}
        // columnResizeMode="fit"
        columnResizeMode="expand"
        stripedRows
        onSort={sortChange}
        onFilter={onFilterColumns}
        scrollable={true}
        onSelectionChange={e => onSelectRowsUpdate(e)}
        scrollHeight='660px'
        emptyMessage={configMessages.noRecords}
        selection={selectedRows}
        selectionMode={'multiple'}
        metaKeySelection={false}
        loading={isLoading}
        style={allUsersData && allUsersData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        {getColumns(props.editRequried, props.deleteRequried)}
      </DataTable>
    )
  }

  const getTabInfo = () => {
    return null;
  }

  const getPaginator = () => {
    let recordsLength = localStorage.getItem('totalRecords')
    let firstRec = JSON.parse(localStorage.getItem('paginationItems'))
    return (
      <PaginatorComponent
        totalRecords={recordsLength ? recordsLength : totalRecordsLength}
        first={firstRec?.first ? firstRec.first : first}
        rows={rows}
        // onPageChange={}
        onPageChange={(e) => onPageChange(e, "pagination")}

        isWeb={true}
      />
    )
  }

  //getGridView
  const getGridView = () => {
    return (
      <div className='row   ml-lg-0 pr-1'>
        {
          allUsersData && allUsersData.length > 0 ?
            allUsersData.map((item, i) => {
              return (
                <div className='col-sm-6 col-md-4 col-lg-3 px-1' key={i}>
                  <Card className='pb-2' >
                    <CardBody className='tableCardBody'>
                      {
                        tablefieldsToShow && tablefieldsToShow.length && tablefieldsToShow.length > 0 ?
                          tablefieldsToShow.map((elememt, index) => {
                            return (
                              <div className={(elememt.displayInSettings === true) ? `col-12  ` : 'd-none'} key={index}>
                                <div >
                                  {
                                    elememt.fieldType === 'Link' ?
                                      <div className='d-flex'>
                                        <span
                                          style={elememt.style}
                                          onClick={() => openViewModal(item, 'view')}>
                                          <b> {item[elememt.field]}</b>
                                        </span>
                                      </div>
                                      :
                                      elememt.fieldType === 'Badge' ?
                                        <div style={elememt.style}>
                                          <Badge color={item.status == '' ? 'success' : item.status == 'Inactive' ? 'warning' : item.status == 'Pending' ? 'danger' : item.status == 'Reject' ? 'error' : item.status == 'Completed' ? 'primary' : 'info'}>
                                            {item[elememt.field]}
                                          </Badge>
                                        </div> :
                                        elememt.fieldType === 'Role' ?
                                          <div style={elememt.style}>
                                            <Badge pill
                                              color='success'
                                            >
                                              {item[elememt.field]}
                                            </Badge>
                                          </div>
                                          : elememt.fieldType === 'Date' ?
                                            <div>
                                              {dateFormats.formatDate(item[elememt.field], config.dateDayMonthFormat)}
                                            </div>
                                            : elememt.fieldType === 'Time' ?

                                              <div>

                                                {dateFormats.formatDate(item[elememt.field], config.timeFormat)} - ${dateFormats.formatDate(item['toTime'], config.timeFormat)}
                                              </div>
                                              : elememt.fieldType === 'Array' ?
                                                <span style={element.style}>
                                                  {flattenArray(item[elememt.field])}
                                                </span>
                                                : !elememt.isMultiSelect && elememt.fieldType === "dropDown" ?
                                                  <div style={elememt.style}>
                                                    {getBadgeData(elememt, item[elememt.field])}
                                                  </div>
                                                  : elememt.isMultiSelect && elememt.fieldType === "dropDown" ?
                                                    <div style={elememt.style}>
                                                      {getMultiSelectBadge(elememt, item[elememt.field])}
                                                    </div>
                                                    : elememt.fieldType === "relateAutoComplete" ?
                                                      < span style={elememt.style}>
                                                        {item[elememt.field] && elememt.searchField && item[elememt.field][elememt.searchField] ? item[elememt.field][elememt.searchField] : null}
                                                      </span>
                                                      : <div style={elememt.style}>
                                                        <span
                                                        >{item[elememt.field]}</span></div>
                                  }
                                </div>
                              </div>
                            )
                          }) : null
                      }
                    </CardBody>
                  </Card>
                </div>

              )
            }) : null
        }
      </div>
    )
  }
  const getBadgeData = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }

  const getMultiSelectBadge = (element, value) => {
    let mcolor = getColorFromOptions(element.options, value);
    return (
      <div>
        {value.map((badge, index) => (
          <Badge key={index} color={mcolor} pill style={{ margin: '1px' }}>
            {badge}
          </Badge>
        ))}
      </div>
    )
  }

  const getTotalCard = () => {
    if (viewType === 'list' && displayViewOfForm != "sideForm") {
      return (
        <Card className='cardForListMargin' >
          <CardBody className='tableCardBody'>
            {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
            {getTabInfo()}
            {!isOpenFormModal && displayViewOfForm === 'screen'
              ? getDataTable() : displayViewOfForm === 'modal' ? getDataTable() : null}

            {isOpenFormModal ? props.type == "Families" ? getFamilyData() : getFamilyData() : null}
            {config.paginationPosition == 'bottom' ? getPaginator() : null}
          </CardBody>
        </Card>
      )
    }
    if (viewType === 'list') {
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "right") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}
              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}

                  </div>
                  <div className='col-3' style={{ borderLeftStyle: 'solid', borderLeftColor: 'lightgrey' }}>
                    {getFamilyData()}
                  </div>
                </div>)
                : null
              }
              {config.paginationPosition == 'bottom' ? getPaginator() : null}
            </CardBody>
          </Card>
        )
      }
      if (displayViewOfForm == "sideForm" && props.sideFormLeftOrRight == "left") {
        return (
          <Card className='cardForListMargin' >
            <CardBody className='tableCardBody'>
              {!isOpenFormModal ? getScreenHeader() : null}
              {getTabInfo()}
              {!isOpenFormModal ? getDataTable() : null}

              {isOpenFormModal ? (
                <div className='row'>
                  <div className='col-3' style={{ borderRightStyle: 'solid', borderRightColor: 'lightgrey' }}>
                    {getFamilyData()}
                  </div>
                  <div className='col-9'>
                    {getScreenHeader()}
                    {getTabInfo()}
                    {getDataTable()}
                    {/* {getFamilyData()} */}
                    {/* {config.paginationPosition == 'bottom' ? getPaginator() : null} */}
                  </div>
                </div>)
                : null
              }

              {config.paginationPosition == 'bottom' ? getPaginator() : null}
            </CardBody>
          </Card>
        )
      }
    }
    if (viewType === 'grid') {
      if (displayViewOfForm == "sideForm") {
        return (
          <div>
            <Card className={' pb-2 cardForGridMargin'} >
              <CardBody className='tableCardBody'>
                {!isOpenFormModal ? getScreenHeader() : null}
                {getTabInfo()}
              </CardBody>
            </Card>
            {!isOpenFormModal ? getGridView() : null}
          </div>
        )
      }
    }
    if (viewType === 'grid' && displayViewOfForm != "sideForm") {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0'>
              {!isOpenFormModal && displayViewOfForm === 'screen' ? getScreenHeader() : displayViewOfForm === 'modal' ? getScreenHeader() : null}
              {getTabInfo()}
              {isOpenFormModal ? getFamilyData() : null}
            </CardBody>
          </Card>
          {!isOpenFormModal && displayViewOfForm === 'screen' ? getGridView() : displayViewOfForm === 'modal' ? getGridView() : null}
        </div>
      )
    }
  }

  const getMobileCard = () => {
    return (
      <div>
        <Loader loader={isLoading} />
        {isOpenFormModal ? null : <div className='row mr-0 ml-0'>
          <div className='col-sm-12 px-0 pb-2'>
            <h4><span className='mobileScreenHaederView'><b >{props.type}</b></span>
              <span className='float-right'>
                <Button color="primary"
                  size="sm"
                  className="p-1 ml-auto mt-1 mb-0"
                  onClick={() => openShowHideColumnsModal()}
                >
                  <FontAwesomeIcon
                    icon='cog'
                    className='pl-1' size='lg'
                    data-toggle="tool-tip" title="Settings"
                    onClick={() => openShowHideColumnsModal()}
                  />
                </Button>
              </span>
            </h4>
          </div>
        </div>}
        {isOpenFormModal ? getMobileForm() : getGridView()}
        {!isOpenFormModal ? <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={false}
        /> : null}
      </div >
    )
  }

  const flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  const getMobileForm = () => {
    return (
      <div className='row'>
        <div className='col-sm-12 px-1'>
          <Card className='pb-0'>
            <CardBody className='tableCardBody pb-0'>
              {/* {getScreenHeader()} */}
              {getFamilyData()}
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
  const getFormFields = () => {
    formModalRef.getFormFields()
  }
  const getFormModal = () => {

    return (
      <FormModal
        onRef={(ref) => formModalRef = ref}
        openFormModal={isOpenFormModal}
        tableRowData={tableRowData}
        allUsersData={allUsersData}
        totalRecords={totalRecordsLength}
        first={first}
        rows={rows}
        page={page}
        closeFormModal={closeFormModal}
        type={props.type}
        displayName={props.displayName}
        tablefieldsToShow={tablefieldsToShow}
        originalTableFields={originalTableFields}
        formType={formType}
        formFields={props.formFields}
        getDataFromServer={getDataFromServer}
        editRequired={props.editRequired}
        getEditInfoKeys={props.getEditInfoKeys}
        filterExtension={props.filterExtension}
        idNotRequired={props.idNotRequired}
        apiUrl={props.formUrl ? props.formUrl : apiUrl}
        role={loginRole}
        tabType={tabType}
        getDoctorPostingFields={props.getDoctorPostingFields}
        categoryNames={props.categoryNames}
        companiesList={props.companiesList}
        getSettings={getSettings}
        filterCriteria={filterCriteria}
        menuList={props.menuList}
        routeTo={props.routeTo}
        displayViewOfForm={displayViewOfForm}
        displayBreadCrumbValue={displayBreadCrumbValue}
        displayBreadCrumbField={displayBreadCrumbField}
        userStatus={userStatus}
        addSelectedRecord={props.addSelectedRecord}
        actionsTypes={actionsTypes}
        entityType={props.entityType}
        setFilterCriteriaForActivities={setFilterCriteriaForActivities}
        getColorFromOptions={getColorFromOptions}
        selectedId={props.selectedId}
        screenName={props.type}
      />
    )
  }


  const getFamilyData = () => {
    if (isOpenFamily) {
      return (
        <FamilyModal
          isOpenFamily={isOpenFamily}
          closeFormModal={closeFormModal}
          type={props.type}
          formType={formType}
          apiUrl={props.apiUrl}
          tableRowData={tableRowData}
          displayName={props.displayName}
          tablefieldsToShow={tablefieldsToShow}
          originalTableFields={originalTableFields}
          allUsersData={allUsersData}
          filterCriteria={filterCriteria}
          getDataFromServer={getDataFromServer}

        />
      )
    }
    return getFormModal()
  }

  const getViewModal = () => {
    return (
      <ViewModal
        type={props.type}
        openViewModal={openViewModal}
        displayViewOfForm={displayViewOfForm}
        rowData={rowData}

        formFields={props.formFields}
        onRef={(ref) => (viewModalRef = ref)}
        closeViewModal={closeViewModal}
        locationId={locationId}
        drawers={drawers}
      />
    )
  }

  const getOpenShowHideColumnsModal = () => {
    return (
      <ShowHideColumnsModal
        viewType={viewType}
        isOpenShowHideColumnsModal={isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={closeShowHideColumnsModal}
        tableFields={originalTableFields}
        setOriginalTableFields={setOriginalTableFields}
        type={props.type}
      />
    )
  }

  const getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={deleteSelectedRow}
        confirmModalText={confirmModalText}
      />
    )
  }


  const getPaymentApproveModal = () => {
    return (
      <PaymentApproveModal
        openPaymentModal={openPaymentModal}
        selectedRowsId={selectedRowsId}
        closeDeleteModal={closeDeleteModal}

      />
    )
  }

  const getPreviewModal = () => {
    return (
      <PreviewModal
        isPreviewModal={isPreviewModal}
        closePreviewModal={closePreviewModal}
        emailTemplateData={emailTemplateData}
        type={props.type}
      />
    )
  }
  const getSendEMailFields = () => {
    return ([{
      required: true,
      value: '',
      type: 'text',
      name: 'email',
      label: 'To',
      id: 'email',
      placeholder: 'Email'
    },
    {
      required: true,
      value: '',
      type: 'text',
      name: 'subject',
      label: 'Subject',
      id: 'subject',
      placeholder: 'Subject'
    },

    {
      required: true,
      value: '',
      type: 'ckeditor',
      name: 'templateText',
      label: 'Body',
      id: 'emailTemplate',
      placeholder: 'name'
    }])
  }
  const getSendEmailModal = () => {
    return (
      <SendEmailModal
        onRef={(ref) => SendEmailModalRef = ref}
        openSendEmailModal={openSendEmailModal}
        closeSendEmailModal={closeSendEmailModal}
        type={props.type}
        item={selectedRows}
        formFields={getSendEMailFields}
        parentFormFields={props.formFields()}
        apiUrl={props.routeTo}
      />
    )
  }


  const getUserPasswordResetModal = () => {
    return (
      <UserPasswordResetModal
        openUserPasswordResetModal={openUserPasswordResetModal}
        // openUserPasswordResetModal={this.state.openUserPasswordResetModal}
        selectedRowsId={selectedRowsId}
        selectedRows={selectedRows}
        closeDeleteModal={closeDeleteModal}

        // user={this.state.recordToConfirm || this.state.selectedRows[0]}
        // cancelReset={this.cancelUserPwdResetModal}
        entityType={config.entityType}
      />
    );
  };


  const getBulkUploadModal = () => {
    return (
      <BulkUploadModal
        bulkApi={props.bulkApi}
        type={props.type}
        sample={props.sample}
        sampleFilePath={`${bulkSampleFiles[props.apiUrl]}`}
        openBulkUploadModal={openBulkUploadModal}
        closeBulkModal={closeBulkModal}
        reload={getDataFromServer}
      />
    )
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        formType={formType}
        onRef={(ref) => (confirmRef = ref)}
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={clickConfirm}
        text={confirmText}
      />
    )
  }

  const getSessionExpiryModal = () => {
    return (
      <SessionExpiryModal
        SOpen={sessionExpiryModal}
      />
    )
  }


  return (
    <div >
      {width <= 576 && getMobileCard()}
      {width >= 577 && getTotalCard()}
      {isDisplay ? <Navigate to="/addMembers" /> : null}
      {isOpenShowHideColumnsModal ? getOpenShowHideColumnsModal() : null}
      {openDeleteModal ? getDeleteRowModal() : null}
      {openPaymentModal ? getPaymentApproveModal() : null}
      {openViewModal ? getViewModal() : null}
      {props.sample ? getBulkUploadModal() : null}
      {isOpenConfirmationModal ? getConfirmatioinModal() : null}
      {sessionExpiryModal ? getSessionExpiryModal() : null}
      {isPreviewModal ? getPreviewModal() : null}
      {redirectToRoute ? <Navigate to={`/${props.routeTo}`} /> : null}
      {openSendEmailModal ? getSendEmailModal() : null}
      {openUserPasswordResetModal ? getUserPasswordResetModal() : null}
    </div >
  );

}

const mapStateToProps = state => {
  return { articles: state.articles };
};
const List = connect(mapStateToProps)(DataTables);

export default withTranslation('common')(DataTables);
