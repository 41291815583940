
import React, { useState, useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';

// fecth method from service.js file
import fetch from '../../../config/service';
import config from '../../../config/config';
import configMessage from '../../../config/configMessages';
// show message 
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import validate from '../../Validations/validate';
import Loader from '../../App/Loader';
import EyeIcon from 'mdi-react/EyeIcon';
import { withTranslation } from 'react-i18next';
import DoPasswordField from '../../Form/Fields/DoPasswordField';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import fetchMethodRequest from '../../../config/service';

//validations
let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

const UserPasswordResetModal = (props) => {


    const schema = yup.object().shape({
        newPassword: yup.string()
            .required('Password is required')
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{16,}$/,
                'Password must be at least 16 characters long, with one uppercase letter, one lowercase letter, one number, and one special character'
            ),
        confirmPassword: yup.string()
            .required('Confirm Password is required')
            .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    });

    // export default schema;



    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });
    let buttonActionType = null;
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedId, setSelectedID] = useState()


    useEffect(() => {
        const userId = props.selectedRows?.[0]._id
        setSelectedID(userId)
    }, []);


    // on value change in input
    const handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    const showPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    }

    const showConfirmPassword = (e) => {
        e.preventDefault();
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    }
    // handle login user data
    const handleUserPassword = async (values) => {

        let url = `auth/createPassword?adminReset=true&_id=${selectedId}&fromAdmin=true`;
        values.entityType = props.entityType;

        fetchMethodRequest('POST', url, values).then(async response => {
            if (response && response.respCode && response.respMessage) {
                showToasterMessage(response.respMessage, 'success');
                props.closeDeleteModal()
            } else if (response && response.errorMessage) {
                showToasterMessage(response.errorMessage, 'error');
            }

        })
    }

    const clearInputFields = () => {
        props.reset();
    }

    const cancelPayment = () => {
        props.closeDeleteModal()
    }

    const submit = (values) => {
        if (values && values.newPassword === values.confirmPassword) {
            handleUserPassword(values)
        }
    }

    const cancelReset = async () => {
        await props.reset();
        await props.cancelReset();
    }


    return (
        <Modal isOpen={props.openUserPasswordResetModal} centered className='userPwdResetModal'>
            <CardBody className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button"
                    onClick={cancelPayment} />
                {props.entityType === "user" ?
                    <h4 className='pb-3'>Reset User Password</h4> :
                    <h4 className='pb-3'>Reset Administrator Password</h4>}
                <Loader loader={isLoading} />

                <form className="form " onSubmit={handleSubmit(submit)}>
                    <div className='row mx-1 mt-3'>
                        <div className='col-sm-12 text-left'>
                            <div className="form__form-group pb-2">
                                <div className=' form__form-group-field'>
                                    <Controller
                                        name="newPassword"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoPasswordField
                                                input={field}
                                                name={field.name}
                                                field={field}
                                                fieldState={fieldState}
                                                style={{ width: '25rem' }}
                                                label={"New Password"}
                                                errors={errors}
                                                placeholder="Confirm Password"
                                            />)}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 text-left'>
                            <div className="form__form-group pb-1">
                                <div className='form__form-group-field'>
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoPasswordField
                                                input={field}
                                                name={field.name}
                                                label={"Confirm Password"}
                                                field={field}
                                                fieldState={fieldState}
                                                style={{ width: '25rem' }}
                                                errors={errors}
                                                placeholder="Confirm Password"
                                            />)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 text-center pt-3'>
                            <div >
                                <Button outline color="primary" type='buttom' onClick={cancelPayment}>Cancel</Button>

                                <Button color="primary" type='submit'>Submit</Button>
                            </div>
                        </div>
                    </div>

                </form>
            </CardBody>
        </Modal>
    );
}
export default UserPasswordResetModal;
