import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

// config file
const TemplatePreviewModal = (props) => {


    // const deleteSelectedRow = () => {
    //     props.deleteSelectedRow();
    // }


    const stripHtmlTags = (html) => {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    };

    // Extract templateText and remove HTML tags
    const plainText = props.templateData?.templateText
        ? stripHtmlTags(props.templateData.templateText)
        : "";



    return (
        
        <div>
            <div style={{ display: 'flex' }}>
                <Modal isOpen={props.openPreviewModal}
                    className={` modal-dialog--primary modal-dialog--header`}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Email Preview
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={() => props.closePreviewModal()}
                             />
                    </ModalHeader>
                    <ModalBody id='templateData'>
                        <span>{plainText ? plainText : 'hhhhhhh'}</span>
                    </ModalBody>
                    <div className='col-sm-12'>
                        <Button color='primary'
                            type="button"
                            style={{marginLeft:'2rem'}}
                            disabled={props.selectedMembers && props.selectedMembers?.length > 0 ?  false  : true}
                            onClick={() => props.sendEmailToServer()}
                            >
                            Send Email
                        </Button>
                        <Button color='primary'
                            type="button"
                            outline
                            onClick={() => props.closePreviewModal()}
                            >
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>
    );

}


export default TemplatePreviewModal;