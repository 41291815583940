export const getPasswordRegex = () => {
    const passwordRegexp = /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;
    return passwordRegexp;
}

export const getEmailRegex = () => {
    const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegexp;
}

export const pinCodeRegex = () =>{
    const pincodeRegex = /^[0-9]{5}$/;
    return pincodeRegex
}