import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Directories from './components/Directories';

const directories = (props,{ t }) => (
  <Container>
    <Directories {...props} />
  </Container>
);

directories.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(directories);
