import React from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoInputField = (props) => {
  const id = props.id ? props.id : props.name;
  const label = props.label ? props.label : '';
  const name = props.name;
  const type = props.type ? props.type : 'text';
  let field = props.field;
  const placeholder = props.placeholder;

  let markRequired = () => {
    return (
      <FontAwesomeIcon
        color='red'
        icon={faAsterisk}
        style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
      />
    );
  };



  let NormalizeZipCode = (value) => {
    if (typeof value !== 'undefined' && value !== null) {
      // Convert value to string and then remove any non-digit characters
      let digitsOnly = String(value).replace(/\D/g, '');

      // Take only the first 5 digits
      let normalizedValue = digitsOnly.slice(0, 5);
      return normalizedValue;
    } else {
      return null;
    }
  };
  const normalizePhoneNumber = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/\D/g, "");
    let formattedPhoneNumber = "";

    // Format the first three digits
    if (onlyNums.length > 0) {
      formattedPhoneNumber = "(" + onlyNums.substring(0, 3);
    }

    // Format the next three digits if available
    if (onlyNums.length > 3) {
      formattedPhoneNumber += ") " + onlyNums.substring(3, 6);
    }

    // Format the remaining digits
    if (onlyNums.length > 6) {
      formattedPhoneNumber += "-" + onlyNums.substring(6, 10);
    }

    return formattedPhoneNumber;
  };


  const onChange = (e) => {
    const value = e.target.value;
    field.onChange(value);
    if (name === 'zipCode') {
      props.onChange(value);  // Call the function passed via props
    }
  };


  const normalizeNumber = (value) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
  }



  return (
    <div className="flex flex-column doInput" style={props.style}>
      <div className="flex align-center"> {/* Added a wrapper div with flex class */}
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && props.markReq === true && markRequired()}
      </div>
      <InputText
        type={type}
        onKeyDown={(e) => {
          // Check if the type is 'number' before preventing the 'e' key
          if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
            e.preventDefault();
          }
        }}
        value={
          name === 'pincodes' ? NormalizeZipCode(field.value) :
            (props.item && props.item.formatPhoneNumber === true) || props.formatPhoneNumber === true ? normalizePhoneNumber(field.value) :
              props.name === 'phone' ? normalizeNumber(field.value) :
                field.value
        }

        // value={name === 'pincode' ? NormalizeZipCode(field.value) : props.item && props.item.formatPhoneNumber === true || props.formatPhoneNumber === true ? normalizePhoneNumber(field.value) || props.name === 'phone' ? normalizeNumber(field.value) : field.value}
        id={id}
        name={name}
        disabled={props.isDisable}
        validate={props.validate}
        placeholder={placeholder}
        aria-describedby="username-help"
        // onChange={(e) => field.onChange(e.target.value)} />
        onChange={onChange}
        style={props.style}
      />
      <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>

    </div>
  )

}

export default DoInputField;