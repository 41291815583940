import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersByProducts from './components/OrdersByProducts';

const ordersByProducts = (props,{ t }) => (
  <Container>
    <OrdersByProducts {...props} />
  </Container>
);

ordersByProducts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ordersByProducts);
