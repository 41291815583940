import React, { useEffect, useState } from 'react';
import {Scrollbar} from 'smooth-scrollbar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

import fetch from "../../../config/service";
import apiCalls from '../../../config/apiCalls';

const Sidebar = (props) => {
  
  const [menuList, setMenuList] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({});

  

  useEffect(() => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      setLoginCredentials(loginCredentials);
    }
    getMenuListFromServer()  
    return () => {
      
    };
  }, []);

  const getMenuListFromServer1 = async () => {
    //   let loginCredentials = this.state.loginCredentials;
    //   if (loginCredentials) {
    //     let filterCriteria = {}, url;
    //     filterCriteria.sortfield = 'sequenceNo';
    //     filterCriteria.direction = 'asc';
    //     // url = `menus?filter=${JSON.stringify(filterCriteria)}`;
    //     // return fetch('GET', url)
    //     //   .then(async (res) => {
    //     //     if (res && res.menus && res.menus.length > 0) {
   let menuList = [{
  "icon": "employee",
  "displayTitle": "Employees",
  "title": "Employee",
  "route": "/employee"
},{
  "icon": "activities",
  "displayTitle": "Activitiess",
  "title": "Activities",
  "route": "/activities"
},{
  "icon": "email templates",
  "displayTitle": "Email Templatess",
  "title": "Email Templates",
  "route": "/email templates"
},{
  "icon": "roles",
  "displayTitle": "Roless",
  "title": "Roles",
  "route": "/roles"
},{
  "icon": "upload history",
  "displayTitle": "Upload Historys",
  "title": "Upload History",
  "route": "/upload history"
},{
  "icon": "email status",
  "displayTitle": "Email Statuss",
  "title": "Email Status",
  "route": "/email status"
},{
  "icon": "district",
  "displayTitle": "Districts",
  "title": "District",
  "route": "/district"
},{
  "icon": "school",
  "displayTitle": "Schools",
  "title": "School",
  "route": "/school"
},{
  "icon": "class room",
  "displayTitle": "Class Rooms",
  "title": "Class Room",
  "route": "/class room"
},{
  "icon": "directory",
  "displayTitle": "Directorys",
  "title": "Directory",
  "route": "/directory"
},{
  "icon": "departments",
  "displayTitle": "Departmentss",
  "title": "Departments",
  "route": "/departments"
},{
  "icon": "faculty",
  "displayTitle": "Facultys",
  "title": "Faculty",
  "route": "/faculty"
},{
  "icon": "groups",
  "displayTitle": "Groupss",
  "title": "Groups",
  "route": "/groups"
},{
  "icon": "add member",
  "displayTitle": "Add Members",
  "title": "Add Member",
  "route": "/add member"
},{
  "icon": "group email",
  "displayTitle": "Group Emails",
  "title": "Group Email",
  "route": "/group email"
},{
  "icon": "news",
  "displayTitle": "Newss",
  "title": "News",
  "route": "/news"
},{
  "icon": "payments",
  "displayTitle": "Paymentss",
  "title": "Payments",
  "route": "/payments"
},{
  "icon": "events",
  "displayTitle": "Eventss",
  "title": "Events",
  "route": "/events"
},{
  "icon": "store",
  "displayTitle": "Stores",
  "title": "Store",
  "route": "/store"
},{
  "icon": "orders",
  "displayTitle": "Orderss",
  "title": "Orders",
  "route": "/orders"
},{
  "icon": "products",
  "displayTitle": "Productss",
  "title": "Products",
  "route": "/products"
},{
  "icon": "order summary report",
  "displayTitle": "Order Summary Reports",
  "title": "Order Summary Report",
  "route": "/order summary report"
},{
  "icon": "refund details",
  "displayTitle": "Refund Detailss",
  "title": "Refund Details",
  "route": "/refund details"
},{
  "icon": "orders by store",
  "displayTitle": "Orders by Stores",
  "title": "Orders by Store",
  "route": "/orders by store"
},{
  "icon": "orders by product",
  "displayTitle": "Orders by Products",
  "title": "Orders by Product",
  "route": "/orders by product"
},{
  "icon": "orders by store and product",
  "displayTitle": "Orders by Store and Products",
  "title": "Orders by Store and Product",
  "route": "/orders by store and product"
},{
  "icon": "ecommerce payments",
  "displayTitle": "Ecommerce Paymentss",
  "title": "Ecommerce Payments",
  "route": "/ecommerce payments"
},{
  "icon": "orders summary by grade",
  "displayTitle": "Orders Summary By Grades",
  "title": "Orders Summary By Grade",
  "route": "/orders summary by grade"
},{
  "icon": "orders summary by class",
  "displayTitle": "Orders Summary By Classs",
  "title": "Orders Summary By Class",
  "route": "/orders summary by class"
},{
  "icon": "family data",
  "displayTitle": "Family Datas",
  "title": "Family Data",
  "route": "/family data"
},{
  "icon": "registered families",
  "displayTitle": "Registered Familiess",
  "title": "Registered Families",
  "route": "/registered families"
},{
  "icon": "last login",
  "displayTitle": "Last Logins",
  "title": "Last Login",
  "route": "/last login"
},{
  "icon": "audit logs",
  "displayTitle": "Audit Logss",
  "title": "Audit Logs",
  "route": "/audit logs"
},{
  "icon": "child",
  "displayTitle": "Childs",
  "title": "Child",
  "route": "/child"
},{
  "icon": "v and d member",
  "displayTitle": "V And D Members",
  "title": "V And D Member",
  "route": "/v and d member"
},{
  "icon": "cart",
  "displayTitle": "Carts",
  "title": "Cart",
  "route": "/cart"
},{
  "icon": "advertisement",
  "displayTitle": "Advertisements",
  "title": "Advertisement",
  "route": "/advertisement"
},{
  "icon": "family",
  "displayTitle": "Familys",
  "title": "Family",
  "route": "/family"
},{
  "icon": "students",
  "displayTitle": "Studentss",
  "title": "Students",
  "route": "/students"
},{
  "icon": "committee",
  "displayTitle": "Committees",
  "title": "Committee",
  "route": "/committee"
},{
  "icon": "eventsignup",
  "displayTitle": "Eventsignups",
  "title": "Eventsignup",
  "route": "/eventsignup"
},{
  "icon": "member",
  "displayTitle": "Members",
  "title": "Member",
  "route": "/member"
},{
  "icon": "paymentintent",
  "displayTitle": "PaymentIntents",
  "title": "PaymentIntent",
  "route": "/paymentintent"
},{
  "icon": "reports",
  "displayTitle": "Reportss",
  "title": "Reports",
  "route": "/reports"
},{
  "icon": "state",
  "displayTitle": "States",
  "title": "State",
  "route": "/state"
},{
  "icon": "volunteer",
  "displayTitle": "Volunteers",
  "title": "Volunteer",
  "route": "/volunteer"
},{
  "icon": "wishlist",
  "displayTitle": "Wishlists",
  "title": "Wishlist",
  "route": "/wishlist"
},{
  "icon": "zipcode",
  "displayTitle": "Zipcodes",
  "title": "Zipcode",
  "route": "/zipcode"
},{
  "icon": "users",
  "displayTitle": "Userss",
  "title": "Users",
  "route": "/users"
},{
  "icon": "pending carts",
  "displayTitle": "Pending Cartss",
  "title": "Pending Carts",
  "route": "/pending carts"
}];
    //     // menuList = res.menus;
    //     await this.setState({
    //       menuList: menuList
    //     })
    //     //     }
    //     //   })
    //   }
  }

  const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }

  const getMenuListFromServer = async () => {    
    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `menus?filter=${JSON.stringify(filterCriteria)}`;
      console.log("urllllll", url)
      return fetch('GET', url)
        .then(async (res) => {
          console.log(res)
          if (res && res.menulists && res.menulists.length > 0) {
            let menuList = res.menulists;
            let rolePermissions = localStorage.getItem("rolePermissions");
            rolePermissions = JSON.parse(rolePermissions);
            if (rolePermissions) {
              let neWmenuList = [];
              let keys = Object.keys(rolePermissions);
              if (menuList) {
                menuList.forEach((item) => {
                  if (item.submenus && item.submenus.length > 0) {
                    let newSubmenus = [];
                    item.submenus.map(sitem => {
                      keys.forEach(element => {
                        if (camelizedString(sitem.displayTitle) === camelizedString(element)) {
                          if (rolePermissions[element] !== "NoView") {
                            newSubmenus.push(sitem);
                          }
                        }
                      });
                    })
                    if (newSubmenus && newSubmenus.length > 0) {
                      item.submenus = newSubmenus;
                      neWmenuList.push(item);
                    }
                  } else {
                    keys.forEach(element => {
                      if (camelizedString(item.displayTitle) === camelizedString(element)) {
                        if (rolePermissions[element] !== "NoView") {
                          neWmenuList.push(item);
                        }
                      }
                    });
                  }
                });
              }
              
              setMenuList(neWmenuList);
            }
          }
        })

    }
  }

  
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={menuList}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={menuList}
            />
          </div>
        </Scrollbar>
      </div>
    );
  };


Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;