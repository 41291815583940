import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrdersSummaryByClasses from './components/OrdersSummaryByClasses';

const ordersSummaryByClasses = (props,{ t }) => (
  <Container>
    <OrdersSummaryByClasses {...props} />
  </Container>
);

ordersSummaryByClasses.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ordersSummaryByClasses);
