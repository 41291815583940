import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const ClassRooms = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [schoolGrades, setSchoolGrades] = useState([])

  const params = useParams();
  useEffect(() => {
    getSettingsFromServer();
    let screenPermissions = RolePermissions.screenPermissions("Class");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  let getSettingsFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    url = `settings`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.settings[0]) {
          let schoolGrades = res.settings[0].schoolGrades;
          setSchoolGrades(schoolGrades)

        }
      })
      .catch((err) => {
        return err;
      }
      );
  }

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: true,
        sortable: false,
        placeholder: "Search",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_nameSearch",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "School Name",
        derivedValue: "school_name=undefined",
        sortable: true,
        filter: true,
        id: "school_nameSearch",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "school_nameSearch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "class_name",
        type: "text",
        placeholder: "Class Name",
        label: "Class Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Class",
        derivedValue: "class_name=undefined",
        sortable: true,
        filter: true,
        id: "class_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        capitalizeTableText: true,
        show: true,
        field: "class_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    
      {
        name: "class_display_order",
        type: "number",
        placeholder: "Class  Order",
        label: "Class Order",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Class Order",
        derivedValue: "class_display_order=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class_display_order",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "class_display_order",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "grade",
        type: "text",
        placeholder: "Grade",
        label: "Grade",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Grade",
        derivedValue: "grade=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "grade",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "grade",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_display_name",
        type: "text",
        placeholder: "First Name",
        label: "Teacher Name",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Teacher Name",
        derivedValue: "teacher_display_name=teacher_first_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_display_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        capitalizeTableText: true,
        show: true,
        field: "teacher_display_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_email",
        type: "email",
        placeholder: "Teacher Email",
        label: "Teacher Email",
        width: "150px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Teacher Email",
        derivedValue: "teacher_email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "teacher_email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_website",
        type: "WebSite",
        placeholder: "Teacher Website",
        label: "Teacher Website",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Teacher Website",
        derivedValue: "teacher_website=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_website",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "teacher_website",
        capitalizeTableText: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
     
      {
        name: "district_name",
        type: "relateAutoComplete",
        placeholder: "School District",
        label: "School District",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "district_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "District",
        searchApi: "districts",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "school_name",
        type: "relateAutoComplete",
        placeholder: "School Name",
        label: "School Name",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        hasDependency: true,
        fieldForKey: "district_id",
        hasDependencyField: "district_name",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "grade",
        type: "text",
        placeholder: "Grade",
        label: "Grade",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "grade=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "grade",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        options: schoolGrades,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "class_display_order",
        type: "number",
        placeholder: "Class  Order",
        label: "Class Display Order",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "class_display_order=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class_display_order",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "class_name",
        type: "text",
        placeholder: "Class Name",
        label: "Class Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "class_name=undefined",
        sortable: true,
        filter: true,
        id: "class_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "teacher_first_name",
        type: "text",
        placeholder: "First Name",
        label: "Teacher First Name",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "teacher_first_name=teacher_first_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_first_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "teacher_last_name",
        type: "text",
        placeholder: "Last Name",
        label: "Teacher Last Name",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "teacher_last_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_last_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "teacher_email",
        type: "email",
        placeholder: "Teacher Email",
        label: "Teacher Email",
        width: "150px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "teacher_email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "teacher_website",
        type: "WebSite",
        placeholder: "Teacher Website",
        label: "Teacher Website",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "teacher_website=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_website",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "WebSite",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);

  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: true,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "class_name",
        type: "text",
        placeholder: "Class Name",
        label: "Class Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Class Name",
        derivedValue: "class_name=undefined",
        sortable: true,
        filter: true,
        id: "class_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "class_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "district_name",
        type: "relateAutoComplete",
        placeholder: "School District",
        label: "School District",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "School District",
        derivedValue: "district_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "District",
        searchApi: "districts",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "district_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "District_name",
        type: "text",
        placeholder: "District_name",
        label: "District_name",
        header: "District name",
        width: 110,
        parentField: "district_name",
        id: "District_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "District_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_name",
        type: "relateAutoComplete",
        placeholder: "School Name",
        label: "School Name",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "School Name",
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "school_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "School_Name",
        type: "text",
        placeholder: "School_Name",
        label: "School_Name",
        header: "School Name",
        width: 110,
        parentField: "school_name",
        id: "School_Name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "Name",
        show: true,
        field: "School_Name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "grade",
        type: "dropDown",
        placeholder: "Grade",
        label: "Grade",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Grade",
        derivedValue: "grade=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "grade",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "grade",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "class_display_order",
        type: "number",
        placeholder: "Class  Order",
        label: "Class Display Order",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Class Display Order",
        derivedValue: "class_display_order=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class_display_order",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "class_display_order",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_first_name",
        type: "text",
        placeholder: "First Name",
        label: "Teacher First Name",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Teacher First Name",
        derivedValue: "teacher_first_name=teacher_first_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_first_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "teacher_first_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_last_name",
        type: "text",
        placeholder: "Last Name",
        label: "Teacher Last Name",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Teacher Last Name",
        derivedValue: "teacher_last_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_last_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "teacher_last_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_email",
        type: "email",
        placeholder: "Teacher Email",
        label: "Teacher Email",
        width: "150px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Teacher Email",
        derivedValue: "teacher_email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "teacher_email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "teacher_website",
        type: "WebSite",
        placeholder: "Teacher Website",
        label: "Teacher Website",
        width: "150px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Teacher Website",
        derivedValue: "teacher_website=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "teacher_website",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "WebSite",
        textAlign: "Center",
        show: true,
        field: "teacher_website",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: true,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
 

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

   
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
   
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.classRooms}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          
          printRequried={false}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },

           
          ]}
          
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={'classes'}
          globalSearch={
            "School Name / Teacher Name / Email"
          }
          displayName="Class"
          type="ClassRooms"
          routeTo={'classRooms'}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.classes?.toLowerCase()}
          apiUrl={apiCalls.classes}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="classRooms"
          apiUrl={apiCalls.classRooms}
        />
      ) : null}
    </span>
  );
};

export default ClassRooms;
