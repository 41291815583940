import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Families from './components/Families';

const families = (props,{ t }) => (
  <Container>
    <Families {...props} />
  </Container>
);

families.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(families);
