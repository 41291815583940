import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Groups from './components/Groups';

const groups = (props,{ t }) => (
  <Container>
    <Groups {...props} />
  </Container>
);

groups.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(groups);
