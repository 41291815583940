import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const DoEditor = ({ field, label, placeholder }) => {
    // const label = props.label ? props.label : props.name;

    return (
        <div className="flex flex-column doInput">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label className="text-capitalize">{label}</label>
                {/* {props.markReq && props.markReq === true && markRequired()} */}
            </div>
            <CKEditor
                editor={ClassicEditor}
                data={field.value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    field.onChange(data);
                }}
                placeholder={placeholder}
            />
        </div>
    );
};

export default DoEditor;
