
const config = {
  // employee

  // apiUrl: 'http://api.school_new.dosystemsinc.com/api/',
  // imgUrl: 'http://api.school_new.dosystemsinc.com/images/attachment/',
  // profileUrl: 'http://api.school_new.dosystemsinc.com/images/employees/',
  // socketUrl: 'https://api.school.dosystemsinc.com',
  // apiUrl: 'http://localhost:3418/api/',
  // imgUrl: 'http://localhost:3418/images/',
  // employee
  // apiUrl: 'http://api.schoollatest1.dosystemsinc.com/api/',
  // imgUrl: 'http://api.schoollatest1.dosystemsinc.com/images/attachment/',
  // apiUrl: 'https://schooldevapi.dosystemsinc.com/api/',
  // imgUrl: 'https://schooldevapi.dosystemsinc.com/images/',
  apiUrl: 'https://schooltestapi.dosystemsinc.com/api/',
  imgUrl: 'https://schooltestapi.dosystemsinc.com/images/',
  janusUrl: 'wss://janus.dosystemsinc.com:8989/janus',
  serverErrMessage: 'Could Not reach server',

  //regex
  borderValidation: false,
  messages: true,
  entityType: 'employee',
  appName: 'School_new',
  defaultScreen: "employees",
  displayProjectName: true,
  displayRecaptcha: false,
  displayGoogleLogin: false,
  loginName: 'Employee',
  selectedLoginScreenName: "1",
  emailRegex: /^(?=.{1,50}$)[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
  aadharcardNumberRegex: /^([0-9]){12}$/,
  pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  phoneNumberRegex: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  userNameRegex: /^[a-zA-Z\s]{1,30}$/,
  lastNameRegex: /^[a-zA-Z\s]{1,30}$/,
  subjectRegex: /^[a-zA-Z\s]{1,50}$/,
  companyRegex: /^([A-Za-z0-9\s@.,]){1,30}$/,
  roomIdRegex: /^([0-9]){4,10}$/,
  // server response codes
  updateResCode: 205,
  deleteResCode: 206,

  datePlaceholder: '--/--/----',
  dateFormat: 'MM/DD/YYYY',
  dateTabularFormat: 'MMM DD YYYY',
  dateDisplayModalFormat: 'DD MMM YYYY',
  dateDBFormat: 'MM-DD-YYYY',
  dateDayMonthFormat: 'DD-MM-YYYY',
  dateYearMonthFormat: 'YYYY-MM-DD',
  dayYearDateFormat: 'YYYY-MM-DD',
  basicDateFromat: 'MM/DD/YYYY HH:mm A',
  descDateFromat: 'MMM DD YYYY HH:mm A',
  timeFormat: 'HH:mm',
  syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
  lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
  dateTimeFormat: 'MM/DD/YYYY hh:mm',
  fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
  fullDateTimeFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSZ',
  dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSZ',
  dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00Z',
  ESTTimezone: "America/New_York",
  formFieldStatusTypes: [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Inactive", value: "Inactive" }
  ],
  noView: 'noView',
  edit: 'edit',
  view: 'view',
  // templateColor: '#0e4768',
  whiteColor: '#ffffff',
  darkTemplateColor: '#00000045',
  avatarBgColor: 'green',
  blackColor: 'black',
  sourceKey: "qVtYv2x5A7CaFcHeMh",
  paginationPosition: 'bottom',
  displaySettings: true,
  selectionLimit: 30,
  imageFormats: ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg', '.tiff', '.webp'],
  colorTypes: [
    { label: "Black", value: "#000000" },
    { label: "White", value: "#ffffff" },
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
  ],
  sizeTypes: [
    { label: "Small", value: "S" },
    { label: "Medium", value: "M" },
    { label: "Large", value: "L" },
    { label: "Xtra Large", value: "XL" },
  ],
  tabfieldsStatusTypes: [
    {
      label: "Active",
      value: "Active",
      color: "success",
    },
    {
      label: "Inactive",
      value: "Inactive",
      color: "warning",
    },
    {
      label: "Pending",
      value: "Pending",
      color: "danger",
    },
    {
      label: "Blocked",
      value: "Blocked",
      color: "danger",
    },
  ],

  employeeTableRolesOptions: [
    { label: "Super Admin", value: "superAdmin", color: "primary" },
    { label: "School Admin", value: "schooladmin", color: "secondary" },
    { label: "District Admin", value: "districtadmin", color: "info" },
    { label: "Manager", value: "manager", color: "warning" },
    { label: "Admin", value: "Admin", color: "success" }

  ],

};
export default config;
