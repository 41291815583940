import React, { useEffect, useState } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import DoRadioButtons from "./DoRadioButtons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate, setIsValidate] = useState(false);
    const [noData, setNoData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dependencyFieldError, setDependencyFieldError] = useState(false)
    let message;
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const [hasMounted, setHasMounted] = useState(false);
    const parent = props.watch && props.hasDependencyField && props.hasDependency ? props.watch(props.hasDependencyField) : null;
    // useEffect(() => {
    //     if (hasMounted && props.setValue && props.hasDependencyField && props.hasDependency) {

    //         // Reset fields when the dependency field changes
    //         if (props.hasDependencyField === 'district_name') {
    //             props.setValue('school_name', null);
    //             props.setValue('class_name', null);
    //         }
    //         props.setValue(props.name, null);
    //     } else {
    //         setHasMounted(true);
    //     }
    // }, [parent, props.setValue, props.hasDependencyField, props.hasDependency]);


    const onSelect = (e) => {
        field.onChange(e);
        if (props.onChange) {
            props.onChange(e.value)
        }
    }

    const onSelectRecord = (e) => {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.onChange) {
            props.onChange(e.value);
        }
    }



    const getAllSuggestions = (event, type) => {
        let url;
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };
        if (props?.type === 'Families' && props?.hasDependencyField && props?.getValues()?.childrens) {
            const childrens = props.getValues().childrens || [];
            let filterCriteria = {
                limit: 30,
                page: 1,
                sortfield: "created",
                direction: "desc",
                criteria: []
            };
            let hasError = false;

            childrens.forEach(child => {
                const district_id = child?.district_name?.district_id;
                const school_id = child?.school_name?.school_id;

                // Check for dependency errors
                if (props?.hasDependencyField === 'district_name' && !district_id) {
                    setDependencyFieldError(true);
                    hasError = true;
                } else if (props?.hasDependencyField === 'school_name' && !school_id) {
                    setDependencyFieldError(true);
                    hasError = true;
                } else {
                    setDependencyFieldError(false);
                }

                // Build filter criteria based on the dependency field
                if (props?.hasDependencyField === 'district_name' && district_id) {
                    filterCriteria.criteria.push({
                        "key": 'district_id',
                        "value": district_id,
                        "type": "eq",
                    });
                } else if (props?.hasDependencyField === 'school_name' && school_id) {
                    filterCriteria.criteria.push({
                        "key": 'school_id',
                        "value": school_id,
                        "type": "eq",
                    });
                }
            });
            if (!hasError) {
                let apiUrl = props.searchApi?.toLowerCase();
                const url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
                setDependencyFieldError(false);

                return fetch('GET', url)
                    .then((response) => {
                        if (response) {
                            let dropdownData = [];
                            if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                                dropdownData = response[apiUrl];
                            }
                            if (props.type === 'Groups' && field.name === 'members') {
                                dropdownData = response['members'];
                            }
                            if (dropdownData && dropdownData.length == 0) {
                                setFilteredSuggestions([]);
                                setNoData(true);
                            } else {
                                setSuggestions(dropdownData);
                            }
                        }
                    }).catch((err) => {
                        return err;
                    });
            }
        }
        else {
            setDependencyFieldError(true);
        }
        if (!props?.name.match(/childrens\[\d+\]\.(school_name|class_name)/)) {
            if (props.type !== 'Families' && props.type === 'Departments' || props.type === 'ClassRooms' && props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)['district_id']) {
                filterCriteria['criteria'].push({
                    "key": props.item.fieldForKey,
                    "value": props.getValues(props.item.hasDependencyField)['district_id'],
                    "type": "eq"
                })
            }
            else if (props.type === 'Products' || props.type === 'Groups' && props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)['school_id']) {
                filterCriteria['criteria'].push({
                    "key": props.item.fieldForKey,
                    "value": props.getValues(props.item.hasDependencyField)['school_id'],
                    "type": "eq"
                })
            } else if (props.type === 'Faculties' && props.item.hasDependencyField && props.getValues(props.item.hasDependencyField)) {
                if (props.item.hasDependencyField && props.item.hasDependencyField === 'district_name') {
                    filterCriteria['criteria'].push({
                        "key": props.item.fieldForKey,
                        "value": props.getValues(props.item.hasDependencyField)['district_id'],
                        "type": "eq"
                    })
                } if (props.item.hasDependencyField && props.item.hasDependencyField === 'school_name') {
                    filterCriteria['criteria'].push({
                        "key": props.item.fieldForKey,
                        "value": props.getValues(props.item.hasDependencyField)['Name'],
                        "type": "eq"
                    })
                }
            }

            let apiUrl = props.searchApi?.toLowerCase();
            if (props.type === 'Groups' && field.name === 'members' && props.item.hasDependencyField) {
                props.item.hasDependencyField.forEach((dependencyField) => {
                    let value = props.getValues(dependencyField);
                    if (value) {
                        url = `${apiUrl}?searchFrom=autoComplete&type=class&school_id=${value['school_id']}&class_id=${value['_id']}&filter=${JSON.stringify(filterCriteria)}`;
                    } else {
                        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
                        setNoData(true);
                    }
                });
            } else {
                url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
            }
            return fetch('GET', url)
                .then((response) => {
                    if (response) {
                        let dropdownData = [];
                        if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                            dropdownData = response[apiUrl];
                        }
                        if (props.type === 'Groups' && field.name === 'members') {
                            dropdownData = response['members'];
                        }
                        if (dropdownData && dropdownData.length == 0) {
                            setFilteredSuggestions([]);
                            setNoData(true);
                        } else {
                            setSuggestions(dropdownData);
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }

    }

    const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    if (props.fieldState.invalid) {
        message = props.errors[props.name]?.message;
        if (props.errors[props.name]?.message.includes("must be a `object` type")) {
            message = "Select a valid option";

        }
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column doInput">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                inputId={field.name}
                value={field.value}
                onChange={onSelect}
                onSelect={onSelectRecord}
                // disabled={disable}
                style={props.style}
                suggestions={filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={field.value == undefined || field.value.length == 0 ? placeholder : ''}
                field={props.searchField}
                dropdown={true}
                multiple={props.multiple}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
            />
            <small className="text-danger ">{props.fieldState.invalid ? message : ""}</small>
            {noData && <span className="form__form-group-error" style={{ marginTop: '5px' }}>
                {props.searchApi == 'schools' ? 'No Data Found' : props.searchApi == 'districts' ? 'No Data Found' : props.searchApi == 'departments' ? 'No Data Found' : 'No Data Found'}

            </span>}
            {props.hasDependency && dependencyFieldError ?
                <span className="form__form-group-error" style={{ marginTop: '5px' }}>{`No data found`}</span>
                : null
            }
        </div>
    )

}

export default DoAutoCompleteField;