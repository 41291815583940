import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Wishlists from './components/Wishlists';

const wishlists = (props,{ t }) => (
  <Container>
    <Wishlists {...props} />
  </Container>
);

wishlists.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(wishlists);
