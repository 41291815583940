import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { Options } from "smooth-scrollbar/options";
// config file

const Schools = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [ptaDues, setPtaDues] = useState([])
  const [schoolGrades, setSchoolGrades] = useState([])
  const [studentFields, setStudentFields] = useState([])
  const [supportingDonations, setSupportingDonations] = useState([])

  const params = useParams();
  useEffect(() => {
    getSettingsFromServer();
    let screenPermissions = RolePermissions.screenPermissions("School");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };



  let getSettingsFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    url = `settings`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.settings[0]) {
          let pta_dues = res.settings[0].pta_dues;
          let schoolGrades = res.settings[0].schoolGrades;
          let studentFields = res.settings[0].studentFormFields;
          let supportingDonations = res.settings[0].supporting_donations;
          await setPtaDues(pta_dues)
          setSchoolGrades(schoolGrades)
          setStudentFields(studentFields)
          setSupportingDonations(supportingDonations)
        }
      })
      .catch((err) => {
        return err;
      }
      );
  }

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [

      {
        name: "Name",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "School Name",
        derivedValue: "Name=Name=Name=undefined",
        sortable: true,
        isClickable: true,
        filter: true,
        id: "Name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "Name",
        showOrHideFields: [],
        mobile: true,
        capitalizeTableText: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "school_id",
        type: "text",
        placeholder: "School Code",
        label: "School Code",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "School Code",
        derivedValue: "school_id=Name=Name=undefined",
        sortable: true,
        filter: true,
        id: "school_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "school_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "text",

      },

      {
        name: "district_name",
        type: "text",
        placeholder: "district_name",
        label: "district_name",
        header: "District",
        width: 110,
        parentField: "district_name",
        id: "district_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "left",
        subFieldName: "name",
        show: true,
        field: "district_name",
        mobile: true,
        sortable: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preload",
        type: "text",
        placeholder: "School Code",
        label: "Preload",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Preload",
        derivedValue: "preload=Name=Name=undefined",
        sortable: true,
        isClickable: true,
        filter: true,
        id: "preload",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "preload",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        capitalizeTableText: true,
        tDisplay: true,
      },
      {
        name: "payments",
        type: "text",
        placeholder: "School Code",
        label: "Payment",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Payment",
        derivedValue: "payment=Name=Name=undefined",
        sortable: true,
        isClickable: true,
        filter: true,
        id: "payments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "payments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        capitalizeTableText: true,
        tDisplay: true,
      },
      {
        name: "Address",
        type: "text",
        placeholder: "School Code",
        label: "Address Line1",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Address Line1",
        derivedValue: "Address=Name=Name=undefined",
        sortable: true,
        filter: true,
        id: "Address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "Address",
        capitalizeTableText: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "School Code",
        label: "City",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "City",
        derivedValue: "city=Name=Name=undefined",
        sortable: true,
        filter: true,
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        capitalizeTableText: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,

        tDisplay: true,
      },

      {
        name: "state",
        type: "textarea",
        placeholder: "State",
        label: "State",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "State",
        derivedValue: "state=state=State=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        capitalizeTableText: true,
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pincode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Zip Code",
        derivedValue: "pincode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pincode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "pincode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "total_amount",
        type: "text",
        placeholder: "Website",
        label: "Amount Recived",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Amount Recived",
        derivedValue: "total_amount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "total_amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "total_amount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Main_Number",
        type: "text",
        placeholder: "Website",
        label: "Phone Number",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Phone Number",
        derivedValue: "website=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Main_Number",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Main_Number",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Attendance_Line",
        type: "number",
        placeholder: "Attendance Line",
        label: "Attendance Line",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Attendance Line",
        derivedValue: "Attendance_Line=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Attendance_Line",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Attendance_Line",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Nurse",
        type: "text",
        placeholder: "Nurse",
        label: "Nurse",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Nurse",
        derivedValue: "Nurse=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Nurse",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "Nurse",
        capitalizeTableText: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "website",
        type: "WebSite",
        placeholder: "Website",
        label: "Web Address",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Web Address",
        derivedValue: "website=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "website",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "WebSite",
        textAlign: "left",
        show: true,
        field: "website",
        capitalizeTableText: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Transportation",
        type: "number",
        placeholder: "Transportation Line",
        label: "Transportation Line",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Transportation Line",
        derivedValue: "Transportation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Transportation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "Transportation",
        showOrHideFields: [],
        fieldName: "Transportation",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "School_Hours",
        type: "text",
        placeholder: "School Hours",
        label: "School Hours",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "School Hours",
        derivedValue: "School_Hours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "School_Hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "School_Hours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Closing_Delays",
        type: "time",
        placeholder: "Delayed Opening",
        label: "Delayed Opening",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Delayed Opening",
        derivedValue: "Closing_Delays=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Closing_Delays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Closing_Delays",
        showOrHideFields: [],
        fieldName: "Closing_Delays",
        fieldType: "time",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Early_Dismissal_time",
        type: "time",
        placeholder: "Early Dismissal Time",
        label: "Early Dismissal Time",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Early Dismissal Time",
        derivedValue: "Early_Dismissal_time=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Early_Dismissal_time",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Early_Dismissal_time",
        showOrHideFields: [],
        fieldName: "Early_Dismissal_time",
        fieldType: "time",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "principal_name",
        type: "text",
        placeholder: "School Principal",
        label: "School Principal",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "School Principal",
        derivedValue: "principal_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "principal_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "principal_name",
        showOrHideFields: [],
        capitalizeTableText: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "principal_email",
        type: "email",
        placeholder: "Principal Email",
        label: "Principal Email",
        width: "150px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Principal Email",
        derivedValue: "principal_email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "principal_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "principal_email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [

      {
        name: "district_name",
        type: "relateAutoComplete",
        placeholder: "School District",
        label: "School District",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "district_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        // isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "District",
        searchApi: "districts",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "district_id",
        type: "text",
        placeholder: "District Code",
        label: "District Code",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 30,
        derivedValue: "district_id=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isAddFieldDisable: true,
        isEditFieldDisable: true,
        disabled: true,
      },
      {
        name: "Name",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "Name=Name=Name=undefined",
        sortable: true,
        isClickable: true,
        filter: true,
        id: "Name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Address",
        type: "text",
        placeholder: "Address Line1",
        label: "Street Address",
        width: "200px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "Address=Address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        type: "textarea",
        placeholder: "City",
        label: "City",
        width: "200px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "textarea",
        placeholder: "State",
        label: "State",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "state=state=State=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "pincode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "pincode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pincode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "website",
        type: "WebSite",
        placeholder: "Website",
        label: "Web Address",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "website=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "website",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "WebSite",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "School_Hours",
        type: "text",
        placeholder: "School Hours",
        label: "School Hours",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "School_Hours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "School_Hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Closing_Delays",
        type: "time",
        placeholder: "Delayed Opening",
        label: "Delayed Opening",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "Closing_Delays=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "Closing_Delays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "Closing_Delays",
        fieldType: "time",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Early_Dismissal_time",
        type: "time",
        placeholder: "Early Dismissal Time",
        label: "Early Dismissal Time",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "Early_Dismissal_time=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "Early_Dismissal_time",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "Early_Dismissal_time",
        fieldType: "time",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Transportation",
        type: "text",
        placeholder: "Transportation Line",
        label: "Transportation Line",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "Transportation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Transportation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        formatPhoneNumber: true,
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "Transportation",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Attendance_Line",
        type: "text",
        placeholder: "Attendance Line",
        label: "Attendance Line",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Attendance Line",
        derivedValue: "Attendance_Line=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Attendance_Line",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        formatPhoneNumber: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Attendance_Line",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Main_Number",
        type: "text",
        placeholder: "Phone Number",
        label: "Main Office",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "Main_Number=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Main_Number",
        formatPhoneNumber: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Nurse",
        type: "text",
        placeholder: "Nurse",
        label: "Nurse",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "Nurse=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Nurse",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,

      },
      {
        name: "principal_name",
        type: "text",
        placeholder: "School Principal",
        label: "School Principal",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue: "principal_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "principal_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "principal_email",
        type: "email",
        placeholder: "Principal Email",
        label: "Principal Email",
        width: "150px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "principal_email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "principal_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "pta_president_name",
        type: "text",
        placeholder: "PTA/PTO/HSA President",
        label: "PTA/PTO/HSA President",
        width: "130px",
        addFormOrder: 31,
        editFormOrder: 31,
        derivedValue: "pta_president_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pta_president_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        name: "pta_president_email",
        type: "email",
        placeholder: "President Email",
        label: "President Email",
        width: "150px",
        addFormOrder: 32,
        editFormOrder: 32,
        derivedValue: "pta_president_email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pta_president_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "SchoolGrades",
        type: "dropDown",
        placeholder: "School Grades",
        label: "School Grades",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        derivedValue: "SchoolGrades=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "SchoolGrades",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        options: schoolGrades,
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        fieldName: "SchoolGrades",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "studentFormFields",
        type: "dropDown",
        placeholder: "Student Fields",
        label: "Student Fields",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        derivedValue: "studentFormFields=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "studentFormFields",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        options: studentFields,
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "pta_types",
        type: "dropDown",
        placeholder: "PTA/PTO/HSA Dues",
        label: "PTA/PTO/HSA Dues",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        options: ptaDues,
        dependentTextField: [
          { "Individual": ['Individual_dues'] },
          { "Family": ['Family_dues'] },
          { "Teacher": ['Teacher_dues'] }
        ],

        derivedValue: "pta_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "pta_types",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "septaFee",
        type: "text",
        placeholder: "Special Education,e.g.SEPTA,Dues",
        label: "Special Education,e.g.SEPTA,Dues",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        derivedValue: "septaFee=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "septaFee",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "supporting_donations",
        type: "dropDown",
        placeholder: "Supporting Donations",
        label: "Supporting Donations",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "supporting_donations=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "supporting_donations",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        options: supportingDonations,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Family_dues",
        type: "number",
        placeholder: "Family Amount",
        label: "Family Amount",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 26,
        derivedValue: "Family_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Family_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Individual_dues",
        type: "number",
        placeholder: "Individual Amount",
        label: "Individual Amount",
        width: "120px",
        addFormOrder: 27,
        editFormOrder: 27,
        derivedValue: "Individual_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Individual_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Teacher_dues",
        type: "number",
        placeholder: "Teacher Amount",
        label: "Teacher Amount",
        width: "120px",
        addFormOrder: 28,
        editFormOrder: 28,
        derivedValue: "Teacher_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Teacher_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "hasFoundation",
        type: "radio",
        placeholder: "Has Foundation",
        label: "Has Foundation",
        width: "120px",
        addFormOrder: 29,
        editFormOrder: 29,
        derivedValue: "hasFoundation=hasFoundation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "hasFoundation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "True", value: true },
          { label: "False", value: false },
        ],
        dependentTextField: [{ "true": ['foundation_Name', 'foundation_Email', 'paypal_client_id', 'paypal_client_secret'] }],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        show: false,
        value: "",
        type: "text",
        name: "foundation_Name",
        label: "Foundation Name",
        id: "Foundation Name",
        placeholder: "Foundation Name",
        required: false,
      }, {
        show: false,
        value: "",
        type: "email",
        name: "foundation_Email",
        label: "Foundation Email",
        id: "Foundation Email",
        placeholder: "Foundation Email",
        required: false,
      }, {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_id",
        label: "Paypal Client id",
        id: "Paypal Client id",
        placeholder: "Paypal Client id",
        required: false,
      }, {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_secret",
        label: "Paypal Client Secret",
        id: "Paypal Client Secret",
        placeholder: "Paypal Client Secret",
        required: false,
      },
      {
        name: "multiFile",
        fieldName: "multiFile",
        type: "fileUpload",
        placeholder: "MultiFile",
        label: "School Logo",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "multiFile=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "multiFile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: false,
        required: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "fileUpload",
        imagePath: "Files",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        isMultiple: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Name",
        type: "text",
        placeholder: "School Name",
        label: "School Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "School Name",
        derivedValue: "Name=Name=Name=undefined",
        sortable: true,
        isClickable: true,
        filter: true,
        id: "Name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "district_name",
        type: "relateAutoComplete",
        placeholder: "District Name",
        label: "District Name",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "District Name",
        derivedValue: "district_name=district_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "District",
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "District",
        searchApi: "districts",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "district_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "District_name",
        type: "text",
        placeholder: "District_name",
        label: "District_name",
        header: "District name",
        width: 110,
        parentField: "district_name",
        id: "District_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "District_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Address",
        type: "textarea",
        placeholder: "Address Line1",
        label: "Street Address",
        width: "200px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Street Address",
        derivedValue: "Address=Address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "textarea",
        placeholder: "City",
        label: "City",
        width: "200px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "City",
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "textarea",
        placeholder: "State",
        label: "State",
        width: "200px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "State",
        derivedValue: "state=state=State=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pincode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Zip Code",
        derivedValue: "pincode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pincode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "pincode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "website",
        type: "WebSite",
        placeholder: "Website",
        label: "Web Address",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Web Address",
        derivedValue: "website=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "website",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "WebSite",
        textAlign: "Center",
        show: true,
        field: "website",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "School_Hours",
        type: "text",
        placeholder: "School Hours",
        label: "School Hours",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "School Hours",
        derivedValue: "School_Hours=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "School_Hours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "School_Hours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Closing_Delays",
        type: "time",
        placeholder: "Delayed Opening",
        label: "Delayed Opening",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Delayed Opening",
        derivedValue: "Closing_Delays=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "Closing_Delays",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Closing_Delays",
        showOrHideFields: [],
        fieldName: "Closing_Delays",
        fieldType: "time",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Early_Dismissal_time",
        type: "time",
        placeholder: "Early Dismissal Time",
        label: "Early Dismissal Time",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Early Dismissal Time",
        derivedValue: "Early_Dismissal_time=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "Early_Dismissal_time",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Early_Dismissal_time",
        showOrHideFields: [],
        fieldName: "Early_Dismissal_time",
        fieldType: "time",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Transportation",
        type: "number",
        placeholder: "Transportation Line",
        label: "Transportation Line",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Transportation Line",
        derivedValue: "Transportation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Transportation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Transportation",
        showOrHideFields: [],
        fieldName: "Transportation",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Attendance_Line",
        type: "number",
        placeholder: "Attendance Line",
        label: "Attendance Line",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Attendance Line",
        derivedValue: "Attendance_Line=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Attendance_Line",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Attendance_Line",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Main_Number",
        type: "number",
        placeholder: "Phone Number",
        label: "Main Office",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Main Office",
        derivedValue: "Main_Number=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Main_Number",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Main_Number",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Nurse",
        type: "text",
        placeholder: "Nurse",
        label: "Nurse",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Nurse",
        derivedValue: "Nurse=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Nurse",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Nurse",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "principal_name",
        type: "text",
        placeholder: "School Principal",
        label: "School Principal",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "School Principal",
        derivedValue: "principal_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "principal_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "principal_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "principal_email",
        type: "email",
        placeholder: "Principal Email",
        label: "Principal Email",
        width: "150px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Principal Email",
        derivedValue: "principal_email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "principal_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "principal_email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "SchoolGrades",
        type: "multiSelect",
        placeholder: "School Grades",
        label: "School Grades",
        width: "120px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "School Grades",
        derivedValue: "SchoolGrades=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "SchoolGrades",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "multiSelect",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "SchoolGrades",
        showOrHideFields: [],
        fieldName: "SchoolGrades",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "studentFormFields",
        type: "dropDown",
        placeholder: "Student Fields",
        label: "Student Fields",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Student Fields",
        derivedValue: "studentFormFields=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "studentFormFields",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "studentFormFields",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pta_dues",
        type: "dropDown",
        placeholder: "PTA/PTO/HSA Dues",
        label: "PTA/PTO/HSA Dues",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "PTA/PTO/HSA Dues",
        derivedValue: "pta_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "pta_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,

        fieldType: "dropDown",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "pta_dues",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        dependentTextField: [
          { "Individual": ['Individual_dues'] },
          { "Family": ['Family_dues'] },
          { "Teacher": ['Teacher_dues'] }
        ],
      },
      {
        name: "septaFee",
        type: "text",
        placeholder: "Special Education,e.g.SEPTA,Dues",
        label: "Special Education,e.g.SEPTA,Dues",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Special Education,e.g.SEPTA,Dues",
        derivedValue: "septaFee=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "septaFee",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "septaFee",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "supporting_donations",
        type: "dropDown",
        placeholder: "Supporting Donations",
        label: "Supporting Donations",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "Supporting Donations",
        derivedValue: "supporting_donations=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "supporting_donations",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "supporting_donations",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Family_dues",
        type: "number",
        placeholder: "Family Amount",
        label: "Family Amount",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 26,
        header: "Family Amount",
        derivedValue: "Family_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Family_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Family_dues",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Individual_dues",
        type: "number",
        placeholder: "Individual Amount",
        label: "Individual Amount",
        width: "120px",
        addFormOrder: 27,
        editFormOrder: 27,
        header: "Individual Amount",
        derivedValue: "Individual_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Individual_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Individual_dues",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Teacher_dues",
        type: "number",
        placeholder: "Teacher Amount",
        label: "Teacher Amount",
        width: "120px",
        addFormOrder: 28,
        editFormOrder: 28,
        header: "Teacher Amount",
        derivedValue: "Teacher_dues=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Teacher_dues",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Teacher_dues",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hasFoundation",
        type: "radio",
        placeholder: "Has Foundation",
        label: "Has Foundation",
        width: "120px",
        addFormOrder: 29,
        editFormOrder: 29,
        header: "Has Foundation",
        derivedValue: "hasFoundation=hasFoundation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "hasFoundation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "hasFoundation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "district_id",
        type: "text",
        placeholder: "District Code",
        label: "District Code",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 30,
        header: "District Code",
        derivedValue: "district_id=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "district_id",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pta_president_name",
        type: "text",
        placeholder: "PTA/PTO/HSA President",
        label: "PTA/PTO/HSA President",
        width: "130px",
        addFormOrder: 31,
        editFormOrder: 31,
        header: "PTA/PTO/HSA President",
        derivedValue: "pta_president_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pta_president_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "pta_president_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pta_president_email",
        type: "email",
        placeholder: "President Email",
        label: "President Email",
        width: "150px",
        addFormOrder: 32,
        editFormOrder: 32,
        header: "President Email",
        derivedValue: "pta_president_email=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pta_president_email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "pta_president_email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };


  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();


  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }

    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.schools}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {

          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }

        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}

          printRequried={false}
          actionsTypes={false}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.schools}
          globalSearch={"District / School Code / School Name"}
          displayName="School"
          type="Schools"
          routeTo={apiCalls.schools}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.schools?.toLowerCase()}
          apiUrl={apiCalls.schools}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="schools"
          apiUrl={apiCalls.schools}
        />
      ) : null}
    </span>
  );
};

export default Schools;
