import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Departments from './components/Departments';

const departments = (props,{ t }) => (
  <Container>
    <Departments {...props} />
  </Container>
);

departments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(departments);
