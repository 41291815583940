const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employees: "employees",
  districts: "districts",
  schools: "schools",
  classRooms: "classes",
  directorys: "directorys",
  departments: "departments",
  faculties: "faculties",
  groups: "groups",
  addMembers: "addMembers",
  groupEmails: "templates",
  news: "news",
  payments: "payments",
  events: "events",
  stores: "stores",
  orders: "orders",
  products: "products",
  orderSummaryReports: "orderSummaryReports",
  refundDetails: "orders/cancelled_products",
  ordersByStores: "orders/orders_by_store",
  ordersByProducts: "orders/orders_by_products",
  ordersByStoreAndProducts: "orders/orders_by_store_products",
  ecommercePayments: "ecommercepayments",
  ordersSummaryByGrades: "orders/orders_by_grade",
  ordersSummaryByClasses: "orders/orders_by_class",
  familyData: "activities",
  registeredFamilies: "activities",
  lastLogins: "activities",
  auditLogs: "activities",
  children: "children",
  classes: 'classes',
  vAndDMembers: "vAndDMembers",
  carts: "carts",
  advertisements: "advertisements",
  families: "families",
  childs: "childs",
  committees: "committees",
  eventsignups: "eventsignups",
  members: "members",
  paymentIntents: "paymentIntents",
  reports: "reports",
  states: "states",
  volunteers: "volunteers",
  wishlists: "wishlists",
  zipcodes: "zipcodes",
  users: "users",
  pendingCarts: "carts/get_pending_cart",
  employees: 'employees',
  getMembersToAdd:"groups/getMembersToAdd",
  updatePaymet:"payments/adminMultiPaymentUpdate"
};
export default apiCalls;
