import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RegisteredFamilies from './components/RegisteredFamilies';

const registeredFamilies = (props,{ t }) => (
  <Container>
    <RegisteredFamilies {...props} />
  </Container>
);

registeredFamilies.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(registeredFamilies);
