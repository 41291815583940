import React, { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import DoInputField from '../../Form/Fields/DoInputField';
import DoSelectField from '../../Form/Fields/DoSelectField';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import DoDateField from '../../Form/Fields/DoDateField';
import DoRadioButtons from '../../Form/Fields/DoRadioButtons';
import Loader from "../../App/Loader";
import fetch from "../../../config/service";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";


let NewUserModal = (props) => {

  let [isLoading, setIsLoading] = useState(false);
  let [newFormFields, setNewFormFields] = useState(props.newFormFields);

  useEffect(() => {
    const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
    if (modal) {
      modal.style.zIndex = '300';
    }

    loadFormValues();
  }, []);

  const loadFormValues = () => {
    Object.keys(props.item).forEach((key) => {
      setValue(key, props.item[key])
    });
  }

  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm();

  const getDropdown = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            />)}
        />
      </div>
    );
  }

  const getDefault = (i, item) => {

    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    );
  }
  const getDates = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="date"
              formType={props.formType}

            />)}
        />
      </div>
    );
  }
  const getRadio = (i, item) => {
    return (
      <div key={i}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              markReq={item.required}
              options={item.options}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
            />)}
        />
      </div>
    )
  }
  const getAutoComplete = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={props.type}
              searchApi={item.searchApi}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              item={item}
              getValues={getValues}
            />)}
        />
      </div>
    )
  }
  const getTime = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="time"
              timeOnly={true}
            />)}
        />
      </div>
    )
  }

  const getItemField = (i, item) => {
    return item.type === "dropDown"
      ? getDropdown(i, item)
      : item.type === "date"
        ? getDates(i, item)
        : item.type === 'relateAutoComplete'
          ? getAutoComplete(i, item)
          : item.type === 'autoComplete'

            ? getAutoComplete(i, item)
            : item.type === 'time' ?
              getTime(i, item)
              : item.type === 'radio' ?
                getRadio(i, item)
                : getDefault(i, item);
  }

  const getItemField1 = () => {
    return props.newFormFields.map((item, i) => {
      return getItemField(i, item);
    });
  }

  const handleUserData = async values => {
    await setIsLoading(true);

    let recordId = props.recordId;
    let url = `${props.apiUrl}/${recordId}`;
    values.entityType = props.entityType;

    fetch("PUT", url, values).then(async response => {
      if (response && response.respCode && response.respMessage) {
        showToasterMessage(response.respMessage, "success");
      } else if (response && response.errorMessage) {
        showToasterMessage(response.errorMessage, "error");
      }
      await setIsLoading(false);
      await cancelReset("submit");
    });
  };

  const submit = async values => {
    await handleUserData(values);
  };

  const cancelReset = async type => {
    await reset;
    await props.cancelReset(type);
  };

  return (
    <>
      <Modal
        isOpen={props.openNewUserModal}
        toggle={cancelReset}
        centered
        className={`modal-dialog modal-dialog-centered modal-dialog--primary  modal-dialog--header`}
      >
        <ModalHeader className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            type="button"
            onClick={() => cancelReset()}
          />
          <p className="bold-text  modal__title"> {"User Details"} </p>
        </ModalHeader>
        <ModalBody className="p-2">
          <Loader loader={isLoading} />

          <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="row mx-1 mt-3">
              {/* map function */}
              {getItemField1()}

              <div className="col-sm-12 text-center pt-3">
                <div>
                  <Button
                    outline
                    color="primary"
                    type="buttom"
                    onClick={() => cancelReset()}
                  >
                    Cancel
                  </Button>

                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

    </>
  )
}

export default NewUserModal;