import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = (props) => {
  const { changeToDark, changeToLight, onClick, menuList } = props;

  const hideSidebar = () => {
    onClick();
  };

  const getIcon = (menu) => {
    if (menu.iconType) {
      if (menu.iconType === 'icon') {
        return menu.iconName ? menu.iconName : 'user';
      }
      //Not using for now
      //  else if (menu.iconType === 'letters') {
      //   if (menu.iconSub) {
      //     return menu.iconLetters ? menu.iconLetters : menu.displayTitle.substring(0, 2);
      //   }
      //   return menu.displayTitle.substring(0, 2);
      // }
    } else {
      return menu.displayTitle.substring(0, 2);
    }
  };

  return (
    <div className="sidebar__content"
      style={menuList && menuList.length > 14 ? { "height": 40 * menuList.length } : { "height": 'calc(100vh - 60px)' }}
    >
      <ul className="sidebar__block">
        {menuList && menuList.length > 0
          ? menuList.map((item, index) => {
            if (item.submenus && item.submenus.length > 0) {
              return (
                <SidebarCategory
                  key={index}
                  title={item.displayTitle === 'Settings' ? 'Settings Menu' : item.displayTitle}
                  icon={getIcon(item)}
                  iconType={item.iconType}
                >
                  {item.submenus.map((sitem, sindex) => (
                    <SidebarLink
                      key={sindex}
                      title={sitem.displayTitle}
                      icon={getIcon(sitem)}
                      onClick={hideSidebar}
                      route={sitem.route}
                      isSubmenu={true}
                    />
                  ))}
                </SidebarCategory>
              );
            } else {
              return (
                <SidebarLink
                  key={index}
                  icon={getIcon(item)}
                  title={item.displayTitle}
                  route={item.route}
                  onClick={hideSidebar}
                  iconType={item.iconType}
                  isSubmenu={false}
                />
              );
            }
          })
          : null}
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  menuList: PropTypes.array.isRequired,
};

export default SidebarContent;
