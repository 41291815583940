import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EcommercePayments from './components/EcommercePayments';

const ecommercePayments = (props,{ t }) => (
  <Container>
    <EcommercePayments {...props} />
  </Container>
);

ecommercePayments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ecommercePayments);
