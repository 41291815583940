import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import TimePicker from "rc-time-picker";
import moment from "moment";

const DoTimePickerField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const placeholder = props.placeholder;
    const selectionMode = props.selectionMode;
    const showButtonBar = props.showButtons;
    let field = props.field;
    let timeOnly = props.timeOnly;
    let formType = props.formType;

    const markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column doInput">
            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>

            <TimePicker
                // showButtonBar={showButtonBar ? true : false}
                // selectionMode={selectionMode ? selectionMode : 'single'}
                inputId={field.name}
                value={field.value ? moment(field.value) : null}
                onChange={field.onChange}
                dateFormat="dd/mm/yy"
                placeholder={placeholder}
                // timeOnly={timeOnly}
                showIcon
                use12Hours={true}
                showSecond={false}
                format="h:mm a"
            />

            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    );
};

export default DoTimePickerField;
