import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const OrderSummaryReports = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions(
      "Orders Summary Report"
    );
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "order_id",
        type: "text",
        placeholder: "Order Id",
        label: "Order Id",
        header: "Order Id",
        width: 110,
        id: "order_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        sortable: true,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        filter: 'true',
        field: "order_id",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "store_name",
        type: "text",
        placeholder: "Store",
        label: "Store",
        header: "Store",
        width: 110,
        id: "store",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "store_name",
        mobile: true,
        filter: 'true',
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        capitalizeTableText: true,

      },
      {
        name: "ordered_cost",
        type: "text",
        placeholder: "Cost",
        label: "Cost",
        header: "Cost",
        width: 110,
        id: "ordered_cost",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "ordered_cost",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "qty",
        type: "text",
        placeholder: "Qty",
        label: "Qty",
        header: "Qty",
        width: 110,
        id: "qty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "qty",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',


      },
      {
        name: "isFoundationOrder",
        type: "text",
        placeholder: "isFoundationOrder",
        label: "isFoundationOrder",
        header: "Is Foundation Order",
        width: 110,
        id: "isFoundationOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "isFoundationOrder",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',


      },
      {
        name: "status",
        type: "text",
        placeholder: "status",
        label: "status",
        header: "Status",
        width: 110,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "status",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',
        fieldType: "Badge",
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },


      },
      {
        name: "family",
        type: "text",
        placeholder: "Family",
        label: "Family",
        header: "Family",
        width: 110,
        id: "family",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "family",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "user_name",
        type: "text",
        placeholder: "Customer",
        label: "Customer",
        header: "Customer",
        width: 110,
        id: "user_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "user_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        capitalizeTableText: true,
        filter: 'true',

      },
      {
        name: "address1",
        type: "text",
        placeholder: "Address",
        label: "Address",
        header: "Address",
        width: 110,
        id: "address1",
        capitalizeTableText: true,
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "address1",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',


      },
      {
        name: "userEmail",
        type: "text",
        placeholder: "Address",
        label: "Email",
        header: "Email",
        width: 110,
        id: "userEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "userEmail",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "child_name",
        type: "text",
        placeholder: "Child Name",
        label: "Child Name",
        header: "Child Name",
        width: 110,
        id: "child_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "child_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        header: "Phone",
        width: 110,
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "phone",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "child_grade",
        type: "text",
        placeholder: "Grade",
        label: "Grade",
        header: "Grade",
        width: 110,
        id: "child_grade",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "child_grade",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "child_class_name",
        type: "text",
        placeholder: "Class",
        label: "Class",
        header: "Class",
        width: 110,
        id: "child_class_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "child_class_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        sortable: true,
        filter: 'true',

      },
      {
        name: "date",
        type: "text",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Date",
        derivedValue: "date=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',

      },
      {
        name: "comments",
        type: "text",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Comments",
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',

      },

      {
        name: "order_code",
        type: "text",
        placeholder: "OrderCode",
        label: "OrderCode",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Order Code",
        derivedValue: "orderCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "order_code",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "order_code",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',

      },
      {
        name: "approvalCode",
        type: "text",
        placeholder: "Approval Code",
        label: "Approval Code",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Approval Code",
        derivedValue: "approvalCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "approvalCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "approvalCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',

      },
      {
        name: "payment",
        type: "text",
        placeholder: "Payment",
        label: "Payment",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Payment",
        derivedValue: "payment=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "payment",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        capitalizeTableText: true,
        textAlign: "Center",
        show: true,
        field: "payment",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',

      },
      {
        name: "total",
        type: "text",
        placeholder: "Total",
        label: "Total",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "Total",
        derivedValue: "total=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "total",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "total",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',

      },
      {
        name: "product_name",
        type: "text",
        placeholder: "Product Name",
        label: "Product Name",
        header: "Product Name",
        width: 110,
        id: "product_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "product_name",
        capitalizeTableText: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        filter: 'true',
        sortable: true,

      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        globalSearchField: "true",
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "store",
        type: "text",
        placeholder: "Store",
        label: "Store",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "store=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "store",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "product",
        type: "text",
        placeholder: "Product",
        label: "Product",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "product=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "product",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "cost",
        type: "text",
        placeholder: "Cost",
        label: "Cost",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "cost=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cost",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "qty",
        type: "text",
        placeholder: "Qty",
        label: "Qty",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "qty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "qty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "isFoundationOrder",
        type: "text",
        placeholder: "Is Foundation Order",
        label: "Is Foundation Order",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "isFoundationOrder=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "isFoundationOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        type: "text",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "family",
        type: "text",
        placeholder: "Family",
        label: "Family",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "family=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "family",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "coustomer",
        type: "text",
        placeholder: "Coustomer",
        label: "Coustomer",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "coustomer=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "coustomer",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "child",
        type: "text",
        placeholder: "Child",
        label: "Child",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "child=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "child",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "grade",
        type: "text",
        placeholder: "Grade",
        label: "Grade",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "grade=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "grade",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "class",
        type: "text",
        placeholder: "Class",
        label: "Class",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue: "class=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "date",
        type: "text",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "date=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        type: "text",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "orderCode",
        type: "text",
        placeholder: "OrderCode",
        label: "OrderCode",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        derivedValue: "orderCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "orderCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "approvalCode",
        type: "text",
        placeholder: "Approval Code",
        label: "Approval Code",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        derivedValue: "approvalCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "approvalCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "payment",
        type: "text",
        placeholder: "Payment",
        label: "Payment",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        derivedValue: "payment=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "payment",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "total",
        type: "text",
        placeholder: "Total",
        label: "Total",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "total=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "total",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: true,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "store",
        type: "text",
        placeholder: "Store",
        label: "Store",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Store",
        derivedValue: "store=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "store",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "store",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "product",
        type: "text",
        placeholder: "Product",
        label: "Product",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Product",
        derivedValue: "product=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "product",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "product",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cost",
        type: "text",
        placeholder: "Cost",
        label: "Cost",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Cost",
        derivedValue: "cost=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cost",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "cost",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "qty",
        type: "text",
        placeholder: "Qty",
        label: "Qty",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Qty",
        derivedValue: "qty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "qty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "qty",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isFoundationOrder",
        type: "text",
        placeholder: "Is Foundation Order",
        label: "Is Foundation Order",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Is Foundation Order",
        derivedValue: "isFoundationOrder=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "isFoundationOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "isFoundationOrder",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        type: "text",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "family",
        type: "text",
        placeholder: "Family",
        label: "Family",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Family",
        derivedValue: "family=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "family",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "family",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "coustomer",
        type: "text",
        placeholder: "Coustomer",
        label: "Coustomer",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Coustomer",
        derivedValue: "coustomer=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "coustomer",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "coustomer",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Address",
        derivedValue: "address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "child",
        type: "text",
        placeholder: "Child",
        label: "Child",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Child",
        derivedValue: "child=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "child",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "child",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone",
        label: "Phone",
        width: "130px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Phone",
        derivedValue: "phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "grade",
        type: "text",
        placeholder: "Grade",
        label: "Grade",
        width: "130px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Grade",
        derivedValue: "grade=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "grade",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "grade",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "class",
        type: "text",
        placeholder: "Class",
        label: "Class",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Class",
        derivedValue: "class=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "class",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "date",
        type: "text",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Date",
        derivedValue: "date=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        type: "text",
        placeholder: "Comments",
        label: "Comments",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Comments",
        derivedValue: "comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "orderCode",
        type: "text",
        placeholder: "OrderCode",
        label: "OrderCode",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "OrderCode",
        derivedValue: "orderCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "orderCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "orderCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "approvalCode",
        type: "text",
        placeholder: "Approval Code",
        label: "Approval Code",
        width: "130px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Approval Code",
        derivedValue: "approvalCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "approvalCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "approvalCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "payment",
        type: "text",
        placeholder: "Payment",
        label: "Payment",
        width: "130px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Payment",
        derivedValue: "payment=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "payment",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "payment",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "total",
        type: "text",
        placeholder: "Total",
        label: "Total",
        width: "130px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "Total",
        derivedValue: "total=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "total",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "total",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };


  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();


  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    // this.setState({
    //   isLoading: true
    // });
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.orderSummaryReports}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={false}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }

          printRequried={false}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },


          ]}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.orderSummaryReports}
          globalSearch={"Store / Email"}
          displayName="Order Summary Report"
          type="OrderSummaryReports"
          routeTo={apiCalls.orderSummaryReports}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.orderSummaryReports?.toLowerCase()}
          apiUrl={apiCalls.orderSummaryReports}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="orderSummaryReports"
          apiUrl={apiCalls.orderSummaryReports}
        />
      ) : null}
    </span>
  );
};

export default OrderSummaryReports;
