import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Districts = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [stateNames, setStateName] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([])

  const params = useParams();
  useEffect(() => {
    getSettingsFromServer()
    let screenPermissions = RolePermissions.screenPermissions("District");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };


  let getSettingsFromServer = async () => {
    let filterCriteria = {},
      url;
    filterCriteria.sortfield = "sequenceNo";
    filterCriteria.direction = "asc";
    url = `settings`;
    fetchMethodRequest("GET", url)
      .then(async (res) => {
        if (res && res.settings[0]) {
          let stateName = res.settings[0].stateNames;
          setStateName(stateName)
          let payment_types = res.settings[0].payment_types
          setPaymentTypes(payment_types)
          
        }
      })
      .catch((err) => {
        return err;
      }
      );
  }
  
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
     
      {
        name: "name",
        type: "text",
        placeholder: "District Name",
        label: "District Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        fieldType: "Link",
        header: "District Name",
        derivedValue: "name=undefined",
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        capitalizeTableText: true,
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "name",
        showOrHideFields: [],
        fieldName: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "payment_types",
        type: "text",
        placeholder: "Payment Types",
        label: "Payment Types",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Payment",
        derivedValue: "payment_types=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "payment_types",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "payment_types",
        fieldName: 'payment_types',
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "district_id",
        type: "text",
        placeholder: "District Code",
        label: "District Code",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "District Code",
        derivedValue: "district_id=undefined",
        sortable: true,
        filter: true,
        id: "district_id",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "district_id",
        showOrHideFields: [],
        fieldName: "district_id",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preload",
        type: "text",
        placeholder: "Preload",
        label: "Preload",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Preload",
        derivedValue: "preload=preload=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "preload",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        capitalizeTableText: true,
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "true", value: true },
          { label: "false", value: false },
        ],
        
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "preload",
        showOrHideFields: [],
        label0: "true",
        value0: "true",
        label1: "false",
        value1: "false",
        fieldName: "preload",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "state_name",
        type: "text",
        placeholder: "State Name",
        label: "State Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "State Name",
        derivedValue: "state_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "left",
        show: true,
        field: "state_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pincode",
        type: "number",
        placeholder: "Pin code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Zip Code",
        derivedValue: "pincode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pincode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        sortable: true,
        field: "pincode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "isDateFormatRequired",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: false,
        sortable: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "state_name",
        type: "dropDown",
        placeholder: "State Name",
        label: "State Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "state_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        options: stateNames,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "name",
        type: "text",
        placeholder: "District Name",
        label: "District Name",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "name=undefined",
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "name",
        fieldType: "text",
        mobile: true,
        required: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "pincode",
        type: "number",
        placeholder: "Pin code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "pincode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pincode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "preload",
        type: "radio",
        placeholder: "Preload",
        label: "Preload",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "preload=preload=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "preload",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "True", value: "true" },
          { label: "False", value: "false" },
        ],
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        label0: "true",
        value0: "true",
        label1: "false",
        required: true,
        value1: "false",
        fieldName: "preload",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "payment_types",
        type: "dropDown",
        placeholder: "Payment Types",
        label: "Payment Types",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "payment_types=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        required: true,
        actionsNumber: [],
        id: "payment_types",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        options:
          [
            { label: "Offline", value: 'Offline' },
            { label: "Paypal", value: 'Paypal' },
          ],
        displayOptionsInActions: false,

        dependentTextField: [{ "Paypal": ['paypal_client_id', 'paypal_client_secret'] }],
        name1: "Paypal",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        show: true,
        showOrHideFields: [],
        required: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

      {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_id",
        label: "Paypal Client Id",
        id: "clientId",
        placeholder: "Client Id",
        name1: "Paypal",
        fieldName: 'paypal_client_id',

      },
      {
        show: false,
        value: "",
        type: "text",
        name: "paypal_client_secret",
        label: "Paypal Secret Key",
        id: "secretKey",
        placeholder: "Secret Key",
        fieldName: 'paypal_client_secret',
        name1: "Paypal",
       
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
   
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "District Name",
        label: "District Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "District Name",
        derivedValue: "name=undefined",
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "name",
        showOrHideFields: [],
        fieldName: "name",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state_name",
        type: "dropDown",
        placeholder: "State Name",
        label: "State Name",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "State Name",
        derivedValue: "state_name=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "state_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "pincode",
        type: "number",
        placeholder: "Pin code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Zip Code",
        derivedValue: "pincode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "pincode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "pincode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "payment_types",
        type: "dropDown",
        placeholder: "Payment Types",
        label: "Payment Types",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Payment Types",
        derivedValue: "payment_types=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "payment_types",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        isMultiSelect: true,
        textAlign: "Center",
        show: true,
        field: "payment_types",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "preload",
        type: "radio",
        placeholder: "Preload",
        label: "Preload",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Preload",
        derivedValue: "preload=preload=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "preload",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "true", value: "true" },
          { label: "false", value: "false" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "preload",
        showOrHideFields: [],
        label0: "true",
        value0: "true",
        label1: "false",
        value1: "false",
        fieldName: "preload",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
 
  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();


  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.districts}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }

          printRequried={false}
          actionsTypes={false}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath["districts"]}
          globalSearch={
            "State Name / District Name / Zip Code"}
          displayName="District"
          type="Districts"
          routeTo={apiCalls["districts"]}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls["districts"].toLowerCase()}
          apiUrl={apiCalls.districts}
          selectedId={params.id}
       
        />
      }
      {isOpenFormModal ? (
        <FormModal
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="districts"
          apiUrl={apiCalls.districts}
        />
      ) : null}
    </span>
  );
};

export default Districts;
