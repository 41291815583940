import React, { useState } from "react";
import { Modal, CardBody, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DoInputField from "../../Form/Fields/DoInputField";
import apiCalls from "../../../config/apiCalls";
import fetchMethodRequest from "../../../config/service";
import showToasterMessage from "../../UI/ToasterMessage/toasterMessage";
const PaymentApproveModal = (props) => {
    const [paymentType, setPaymentType] = useState('Cash');

    const schema = yup.object().shape({
    });


    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            payment_method: 'Cash',
            cheque: '',
        }
    });

    const cancelPayment = () =>{
        props.closeDeleteModal()
    }

    const submit = async (values) => {
        let method, apiUrl;
        method = "POST";
        let body = {
            "selectedIds": props.selectedRowsId,
            "payment_method": values.payment_method,
            "cheque": values.cheque

        }
        apiUrl = `${apiCalls.updatePaymet}`;
        return fetchMethodRequest(method, apiUrl, body)
            .then(async (response) => {
                if (response) {
                    showToasterMessage(response.respMessage, "success");
                    props.closeDeleteModal()
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, "error");
                }
            })
            .catch((err) => {
                return err;
            });
    };

    return (
        <Modal isOpen={props.openPaymentModal} centered className='userPwdResetModal'>
            <CardBody className='p-2'>
                <h4 className='pb-3'>Select Payment Method</h4>
                <form className="form" onSubmit={handleSubmit(submit)}>
                    <div className="form__form-group-field mb-2 col-12 align_checkBox pb-2">
                        <Controller
                            name="payment_method"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <label className="radio-inline mr-3">
                                        <input
                                            type="radio"
                                            {...field}
                                            value="Cash"
                                            checked={field.value === 'Cash'}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setPaymentType(e.target.value);
                                            }}
                                        /> Cash
                                    </label>
                                    <label className="radio-inline">
                                        <input
                                            type="radio"
                                            {...field}
                                            value="Cheque"
                                            checked={field.value === 'Cheque'}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setPaymentType(e.target.value);
                                            }}
                                        /> Cheque
                                    </label>
                                </>
                            )}
                        />
                    </div>
                    {paymentType === 'Cheque' && (
                        <div className="form__form-group-field mb-2 col-12 align_checkBox pb-2">
                            <Controller
                                name={'cheque'}
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoInputField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        label={'Cheque Number'}
                                        field={field}
                                        type={"text"}
                                        fieldState={fieldState}
                                        errors={errors}
                                        // defVal={''}
                                        placeholder="Cheque Number"
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className="col-12" style={{ margin: 'auto', textAlign: 'center' }}>
                        <Button color="primary" type='submit'>Submit</Button>
                        <Button color="secondary" type='button' onClick={cancelPayment}>Cancel</Button>
                    </div>
                </form>
            </CardBody>
        </Modal>
    );
}
export default PaymentApproveModal;
