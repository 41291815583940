

import React, { useEffect, useState } from 'react';
import fetchMethodRequest from '../../config/service';
// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import validate from '../Validations/validate';
// Loader
import Loader from '../App/Loader';
// Calendar
//session expiry modal
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
import { th } from 'date-fns/locale';
import store from '../App/store';

import DoInputField from '../Form/Fields/DoInputField';
import DoRadioButtons from '../Form/Fields/DoRadioButtons';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import DoTextareaField from '../Form/Fields/DoTextareaField';
import { Card, CardBody, CardHeader } from 'reactstrap';
import DoSelectField from '../Form/Fields/DoSelectField';

// const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const SettingsForm = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [schema, setSchema] = useState({});
  const [messages, setMessages] = useState()
  const [headerStyle, setHeaderStyle] = useState([])
  const [detailsStyle, setDetailsStyle] = useState([])
  const [options, setOptions] = useState(
    [
      { label: 'ddMMYYY', value: 'DD/MM/YYYY' },
      { label: 'MMDDYYY', value: 'MM/DD/YYYY' },
      { label: 'MMM DD YYYY', value: 'MMM DD YYYY' },
      { label: 'DD MMM YYYY', value: 'DD MMM YYYY' },
      { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
      { label: 'MM/DD/YYYY HH:mm A', value: 'MM/DD/YYYY HH:mm A' },
      { label: 'MMM DD YYYY HH:mm A', value: 'MMM DD YYYY HH:mm A' },
      { label: 'hh:mm A, MM-DD-YYYY', value: 'hh:mm A, MM-DD-YYYY' },
      { label: 'MM/DD/YYYY HH:mm', value: 'MM/DD/YYYY HH:mm' },
      { label: 'YYYY-MM-DD HH:mm:ss', value: 'YYYY-MM-DD HH:mm:ss' },
      { label: 'YYYY-MM-DD[T]HH:mm:ss.SSS', value: 'YYYY-MM-DD[T]HH:mm:ss.SSS' },
      { label: 'YYYY-MM-DD[T]00:00:00Z', value: 'YYYY-MM-DD[T]00:00:00Z' },
      { label: 'MMM YYYY', value: 'MMM YYYY' },
      { label: 'MMM  DD, YYYY', value: 'MMM  DD, YYYY' },
    ]

  )


  const enableMailOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const disableLoginOpt = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const enableUserDelete = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  const enableTerminalLogs = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]

  useEffect(() => {
    getSettingsData();
  }, [])

  let {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const watchEmailSourceType = watch('emailSourceType', 'sendGrid');
  const getSettingsData = () => {
    setIsLoading(true);
    let filterCriteria = {};
    filterCriteria['criteria'] = [];
    fetchMethodRequest('GET', `settings?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.respCode && response.settings && response.settings[0]) {
        setFormValues({ ...response.settings[0] })
        setHeaderStyle(response?.settings[0]?.headerStyle)
        setDetailsStyle(response?.settings[0]?.detailsStyle)

      }
    })
  }

  const setFormValues = (settings) => {
    if (settings.Admin && typeof settings.Admin === 'object') {
      setValue('GoogleClientId', settings.Admin.GoogleClientId);
      setValue('GooglesecretKey', settings.Admin.GooglesecretKey);
    }
    setValue('sendGridApiKey', settings.sendGridApiKey);
    setValue('sendGridEmail', settings.sendGridEmail);
    setValue('emailSourceType', settings.emailSourceType);
    setValue('enableMails', settings.enableMails);
    setValue('disableMultipleLogin', settings.disableMultipleLogin);
    setValue('enableUserDelete', settings.enableUserDelete)
    setValue('enableTerminalLogs', settings.enableTerminalLogs)
    setValue('nodeMailerHost', settings.nodeMailerHost);
    setValue('nodeMailerPass', settings.nodeMailerPass);
    setValue('nodeMailerUser', settings.nodeMailerUser);
    setValue('expireTokenTimeInMin', settings.expireTokenTimeInMin);
    setValue('adminExpireTokenTimeInMin', settings.adminExpireTokenTimeInMin);
    setValue('_id', settings._id);
    setValue('dateFormat', settings.dateFormat)
    setValue('pincodeLength', settings.pincodeLength)
    setValue('blockingIP_times', settings.blockingIP_times)
    setValue('AndroidVersion', settings.AndroidVersion)
    setValue('headerTitleStyle', settings.headerTitleStyle)
    setValue('detailsTitleStyle', settings.detailsTitleStyle)
    setValue('i18n', JSON.stringify(settings['i18n'], null, 2));
    setValue('westport_PTA_permission_text', settings.westport_PTA_permission_text)
    setValue('terms_and_services_text', settings.terms_and_services_text)
    setIsLoading(false);
  }

  const onUpdate = (data) => {
    let formData = { ...data };
    formData.Admin = {};
    formData.Admin.GoogleClientId = data.GoogleClientId;
    formData.Admin.GooglesecretKey = data.GooglesecretKey;
    if (data.emailSourceType === 'nodeMailer') {
      delete formData.sendGridApiKey;
    } else {
      delete formData.nodeMailerHost;
      delete formData.nodeMailerPass;
      delete formData.nodeMailerUser;
    }
    delete formData.GoogleClientId;
    delete formData.GooglesecretKey;
    saveDataToServer(formData);
  }

  const saveDataToServer = (formValues) => {
    setIsLoading(true);

    if (formValues) {
      if (formValues['i18n']) {
        let i18nString = JSON.stringify(formValues['i18n']);
        i18nString = i18nString.replace(/,/g, ',\n');
        formValues['i18n'] = i18nString;
      }
      const apiUrl = `settings/${formValues._id}`;
      fetchMethodRequest('PUT', apiUrl, formValues)
        .then((response) => {
          setIsLoading(false);

          if (response && response.respCode) {
            showToasterMessage(response.respMessage, 'success');
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
        });
    }
  };

  const apple = () => {
    console.log('I am refreshed')
  }
  let isSubmitting = false
  return (
    <div style={{ overflowX: 'hidden', height: '88vh' }} onLoad={apple}>
      <Loader loader={isLoading} />
      <div className="card">
        <TabView>
          <TabPanel header="General Settings">
            <form
              onSubmit={handleSubmit(onUpdate)}
            >
              <div className="row create-header py-3 ml-0">
                <h4 className="float-left ">
                  {" "}
                  <b>{"General Settings"}</b>
                </h4>
              </div>
              <div className="form-group">
                <div className="form__form-group-field">
                  <Controller
                    name={'dateFormat'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoSelectField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        label={'Select Date Format'}
                        fieldState={fieldState}
                        errors={errors}
                        options={options}
                        style={{ width: '50rem' }}
                      />)}
                  />
                </div>
                <div className='align_radio_buttions'>
                  <label>Enable Mails </label>
                  <div className='d-flex col-md-6'>
                    <Controller
                      name={'enableMails'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoRadioButtons
                          input={field}
                          id={field.id}
                          name={field.name}
                          field={field}
                          options={enableMailOptions}
                          fieldState={fieldState}
                          errors={errors}
                          type='Setting'
                        />)}
                    />
                  </div>
                </div>
                <div className='align_radio_buttions'>
                  <label>Disable Multiple Login </label>
                  < div className='d-flex'>
                    <Controller
                      name={'disableMultipleLogin'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoRadioButtons
                          input={field}
                          id={field.id}
                          name={field.name}
                          field={field}
                          options={disableLoginOpt}
                          fieldState={fieldState}
                          type='Setting'
                          errors={errors}
                        />)}
                    />
                  </div>
                </div>
                <div className='align_radio_buttions'>
                  <label>Enable Users Delete </label>
                  < div className='d-flex'>
                    <Controller
                      name={'enableUserDelete'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoRadioButtons
                          input={field}
                          id={field.id}
                          name={field.name}
                          field={field}
                          options={enableUserDelete}
                          fieldState={fieldState}
                          type='Setting'
                          errors={errors}
                        />)}
                    />
                  </div>
                </div>
                <div className='align_radio_buttions'>
                  <label>Enable Terminal Logs</label>
                  < div className='d-flex'>
                    <Controller
                      name={'enableTerminalLogs'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoRadioButtons
                          input={field}
                          id={field.id}
                          name={field.name}
                          field={field}
                          options={enableTerminalLogs}
                          fieldState={fieldState}
                          type='Setting'
                          errors={errors}
                        />)}
                    />
                  </div>
                </div>
                <div className='align_radio_buttions'>
                  <Controller
                    name={'pincodeLength'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoInputField
                        input={field}
                        id={field.id}
                        name={field.name}
                        label={'PinCode Length'}
                        field={field}
                        type={"number"}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder={'PinCode Length'}
                        style={{ width: '50rem' }}

                      />)}
                  />
                </div>
                <div className='align_radio_buttions'>
                  <Controller
                    name={'blockingIP_times'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoInputField
                        input={field}
                        id={field.id}
                        name={field.name}
                        label={'Block IP Address'}
                        field={field}
                        type={"text"}
                        fieldState={fieldState}
                        errors={errors}
                        style={{ width: '50rem' }}

                      />)}
                  />
                </div>
                <div className='align_radio_buttions'>
                  <Controller
                    name={'AndroidVersion'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoInputField
                        input={field}
                        id={field.id}
                        name={field.name}
                        label={'Android Version'}
                        field={field}
                        type={"text"}
                        fieldState={fieldState}
                        errors={errors}
                        style={{ width: '50rem' }}

                      />)}
                  />
                </div>
                <div className='align_radio_buttions'>
                  <Controller
                    name={'headerTitleStyle'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoSelectField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        label={'Header Title Style'}
                        fieldState={fieldState}
                        errors={errors}
                        options={headerStyle}
                        style={{ width: '50rem' }}
                      />)}
                  />
                </div>
                <div className='align_radio_buttions'>
                  <Controller
                    name={'detailsTitleStyle'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoSelectField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        label={'Details Tittle Style'}
                        fieldState={fieldState}
                        errors={errors}
                        style={{ width: '50rem' }}
                        options={detailsStyle}
                      />)}
                  />
                </div>
              </div>
              <div className="pl-5 pt-4 float-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {"Update"}
                </button>
              </div>
            </form>
          </TabPanel>
          <TabPanel header="Token Settings">
            <div className="row create-header py-3 ml-0">
              <h4 className="float-left ">
                {" "}
                <b>{"Payment Settings"}</b>
              </h4>
            </div>
            <Controller
              name={'adminExpireTokenTimeInMin'}
              control={control}
              render={({ field, fieldState }) => (
                <DoInputField
                  input={field}
                  id={field.id}
                  name={field.name}
                  label={'Admin Expire Token Time (min)'}
                  field={field}
                  type={"text"}
                  fieldState={fieldState}
                  errors={errors}
                  placeholder={'Admin Expire Token Time'}
                  style={{ width: '50rem' }}
                />)}

            />
            <Controller
              name={'expireTokenTimeInMin'}
              control={control}
              render={({ field, fieldState }) => (
                <DoInputField
                  input={field}
                  id={field.id}
                  name={field.name}
                  label={'Expire Token Time (min)'}
                  field={field}
                  type={"text"}
                  fieldState={fieldState}
                  errors={errors}
                  placeholder={'Expire Token Time'}
                  style={{ width: '50rem' }}

                />)}
            />

          </TabPanel>
          <TabPanel header="Payment Settings">
            <form
              onSubmit={handleSubmit(onUpdate)}
            >
              <div className="row ">
                <div className="col-sm-7 ">
                  <Card>
                    <CardBody className="px-3">
                      <div className="row create-header py-3 ml-0">
                        <h4 className="float-left ">
                          {" "}
                          <b>{"Payment Settings"}</b>
                        </h4>
                      </div>
                      <div className="form__form-group">
                        <Controller
                          name={'westport_PTA_permission_text'}
                          control={control}
                          render={({ field, fieldState }) => (
                            <DoTextareaField
                              input={field}
                              id={field.id}
                              name={field.name}
                              label={'Westport PTA Permission'}
                              field={field}
                              type={"text"}
                              fieldState={fieldState}
                              errors={errors}
                              placeholder={''}
                              style={{ height: '200px', marginTop: '1rem', marginBottom: '1rem' }} // Set the desired height

                            />)}
                        />
                      </div>
                      <div className="form__form-group">
                        <Controller
                          name={'terms_and_services_text'}
                          control={control}
                          render={({ field, fieldState }) => (
                            <DoTextareaField
                              input={field}
                              id={field.id}
                              name={field.name}
                              label={'Terms And Services'}
                              field={field}
                              type={"text"}
                              fieldState={fieldState}
                              errors={errors}
                              placeholder={''}
                              style={{ height: '200px', marginTop: '1rem', marginBottom: '1rem' }} // Set the desired height

                            />)}
                        />
                      </div>
                      <div className="pl-5 pt-4 float-right">
                        <button
                          type="submit"
                          className="btn btn-primary"
                        >
                          {"Update"}
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel header="Messages Settings">
            <form
              onSubmit={handleSubmit(onUpdate)}
            >
              <div className="row ">
                <div className="col-sm-7 ">
                  <Card>
                    <CardBody className="px-3">
                      <div className="row create-header py-3 ml-0">
                        <h4 className="float-left ">
                          {" "}
                          <b>{"Messages Settings"}</b>
                        </h4>
                      </div>
                      <div className="form__form-group">
                        <Controller
                          name={'i18n'}
                          control={control}
                          render={({ field, fieldState }) => (
                            <DoTextareaField
                              input={field}
                              id={field.id}
                              name={field.name}
                              label={'Messages'}
                              field={field}
                              type={"text"}
                              fieldState={fieldState}
                              errors={errors}
                              placeholder={''}
                              style={{ height: '200px', marginTop: '1rem', marginBottom: '1rem' }} // Set the desired height

                            />)}
                        />
                      </div>
                      <div className="pl-5 pt-4 float-right">
                        <button
                          type="submit"
                          className="btn btn-primary"
                        >
                          {"Update"}
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </form>
          </TabPanel>
        </TabView>
      </div>
      {/* </form> */}
    </div >
  );
}




export default SettingsForm;





