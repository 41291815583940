/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter, Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import Districts from '../Cruds/Districts/index';
import Schools from '../Cruds/Schools/index';
import ClassRooms from '../Cruds/ClassRooms/index';
import Directories from '../Cruds/Directories/index';
import Departments from '../Cruds/Departments/index';
import Faculties from '../Cruds/Faculties/index';
import Groups from '../Cruds/Groups/index';
import AddMembers from '../Cruds/AddMembers/index';
import GroupEmails from '../Cruds/GroupEmails/index';
import News from '../Cruds/News/index';
import Payments from '../Cruds/Payments/index';
import Events from '../Cruds/Events/index';
import Stores from '../Cruds/Stores/index';
import Orders from '../Cruds/Orders/index';
import Products from '../Cruds/Products/index';
import OrderSummaryReports from '../Cruds/OrderSummaryReports/index';
import RefundDetails from '../Cruds/RefundDetails/index';
import OrdersByStores from '../Cruds/OrdersByStores/index';
import OrdersByProducts from '../Cruds/OrdersByProducts/index';
import OrdersByStoreAndProducts from '../Cruds/OrdersByStoreAndProducts/index';
import EcommercePayments from '../Cruds/EcommercePayments/index';
import OrdersSummaryByGrades from '../Cruds/OrdersSummaryByGrades/index';
import OrdersSummaryByClasses from '../Cruds/OrdersSummaryByClasses/index';
import FamilyData from '../Cruds/FamilyData/index';
import RegisteredFamilies from '../Cruds/RegisteredFamilies/index';
import LastLogins from '../Cruds/LastLogins/index';
import AuditLogs from '../Cruds/AuditLogs/index';
import Children from '../Cruds/Children/index';
import VAndDMembers from '../Cruds/VAndDMembers/index';
import Carts from '../Cruds/Carts/index';
import Advertisements from '../Cruds/Advertisements/index';
import Families from '../Cruds/Families/index';
import Students from '../Cruds/Students/index';
import Committees from '../Cruds/Committees/index';
import Eventsignups from '../Cruds/Eventsignups/index';
import Members from '../Cruds/Members/index';
import PaymentIntents from '../Cruds/PaymentIntents/index';
import Reports from '../Cruds/Reports/index';
import States from '../Cruds/States/index';
import Volunteers from '../Cruds/Volunteers/index';
import Wishlists from '../Cruds/Wishlists/index';
import Zipcodes from '../Cruds/Zipcodes/index';
import Users from '../Cruds/Users/index';
import PendingCarts from '../Cruds/PendingCarts/index';

// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';
import Verification from '../Landing/LogIn/components/index.jsx';
import GroupEmailTemplate from '../Cruds/CommonModals/GroupEmailTemplate';


const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
        <Routes>
          <Route path='/sampleform'
            element={<SampleForm />}
          ></Route>
          <Route exact
            path="/employees"
            element={
              roles['Administrators'] &&
                (roles['Administrators'] === "Edit" || roles['Administrators'] === "View")
                ? <Employees />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_employees"
            element={
              roles['Administrators'] &&
                (roles['Administrators'] === "Edit")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_employees/:id"
            element={
              roles['Administrators'] &&
                (roles['Administrators'] === "Edit")
                ? <Employees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_employees/:id"
            element={
              roles['Administrators'] &&
                (roles['Administrators'] === "Edit" || roles['Administrators'] === "View")
                ? <Employees />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/districts"
            element={
              roles['District'] &&
                (roles['District'] === "Edit" || roles['District'] === "View")
                ? <Districts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_districts"
            element={
              roles['District'] &&
                (roles['District'] === "Edit")
                ? <Districts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_districts/:id"
            element={
              roles['District'] &&
                (roles['District'] === "Edit")
                ? <Districts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_districts/:id"
            element={
              roles['District'] &&
                (roles['District'] === "Edit" || roles['District'] === "View")
                ? <Districts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/schools"
            element={
              roles['School'] &&
                (roles['School'] === "Edit" || roles['School'] === "View")
                ? <Schools />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_schools"
            element={
              roles['School'] &&
                (roles['School'] === "Edit")
                ? <Schools />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_schools/:id"
            element={
              roles['School'] &&
                (roles['School'] === "Edit")
                ? <Schools />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_schools/:id"
            element={
              roles['School'] &&
                (roles['School'] === "Edit" || roles['School'] === "View")
                ? <Schools />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/classRooms"
            element={
              roles['Class'] &&
                (roles['Class'] === "Edit" || roles['Class'] === "View")
                ? <ClassRooms />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_classRooms"
            element={
              roles['Class'] &&
                (roles['Class'] === "Edit")
                ? <ClassRooms />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_classRooms/:id"
            element={
              roles['Class'] &&
                (roles['Class'] === "Edit")
                ? <ClassRooms />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_classRooms/:id"
            element={
              roles['Class'] &&
                (roles['Class'] === "Edit" || roles['Class'] === "View")
                ? <ClassRooms />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/directories"
            element={
              roles['Directories'] &&
                (roles['Directories'] === "Edit" || roles['Directories'] === "View")
                ? <Directories />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_directories"
            element={
              roles['Directories'] &&
                (roles['Directories'] === "Edit")
                ? <Directories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_directories/:id"
            element={
              roles['Directories'] &&
                (roles['Directories'] === "Edit")
                ? <Directories />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_directories/:id"
            element={
              roles['Directories'] &&
                (roles['Directories'] === "Edit" || roles['Directories'] === "View")
                ? <Directories />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/departments"
            element={
              roles['Departments'] &&
                (roles['Departments'] === "Edit" || roles['Departments'] === "View")
                ? <Departments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_departments"
            element={
              roles['Departments'] &&
                (roles['Departments'] === "Edit")
                ? <Departments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_departments/:id"
            element={
              roles['Departments'] &&
                (roles['Departments'] === "Edit")
                ? <Departments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_departments/:id"
            element={
              roles['Departments'] &&
                (roles['Departments'] === "Edit" || roles['Departments'] === "View")
                ? <Departments />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/faculties"
            element={
              roles['Faculties'] &&
                (roles['Faculties'] === "Edit" || roles['Faculties'] === "View")
                ? <Faculties />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_faculties"
            element={
              roles['Faculties'] &&
                (roles['Faculties'] === "Edit")
                ? <Faculties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_faculties/:id"
            element={
              roles['Faculties'] &&
                (roles['Faculties'] === "Edit")
                ? <Faculties />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_faculties/:id"
            element={
              roles['Faculties'] &&
                (roles['Faculties'] === "Edit" || roles['Faculties'] === "View")
                ? <Faculties />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/groups"
            element={
              roles['Groups'] &&
                (roles['Groups'] === "Edit" || roles['Groups'] === "View")
                ? <Groups />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_groups"
            element={
              roles['Groups'] &&
                (roles['Groups'] === "Edit")
                ? <Groups />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_groups/:id"
            element={
              roles['Groups'] &&
                (roles['Groups'] === "Edit")
                ? <Groups />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_groups/:id"
            element={
              roles['Groups'] &&
                (roles['Groups'] === "Edit" || roles['Groups'] === "View")
                ? <Groups />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/addMembers"
            element={
              roles['Add Members'] &&
                (roles['Add Members'] === "Edit" || roles['Add Members'] === "View")
                ? <GroupEmailTemplate />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_addMembers"
            element={
              roles['Add Members'] &&
                (roles['Add Members'] === "Edit")
                ? <AddMembers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_addMembers/:id"
            element={
              roles['Add Members'] &&
                (roles['Add Members'] === "Edit")
                ? <AddMembers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_addMembers/:id"
            element={
              roles['Add Members'] &&
                (roles['Add Members'] === "Edit" || roles['Add Members'] === "View")
                ? <AddMembers />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/groupEmails"
            element={
              roles['Group Email'] &&
                (roles['Group Email'] === "Edit" || roles['Group Email'] === "View")
                ? <GroupEmails />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_groupEmails"
            element={
              roles['Group Email'] &&
                (roles['Group Email'] === "Edit")
                ? <GroupEmails />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_groupEmails/:id"
            element={
              roles['Group Email'] &&
                (roles['Group Email'] === "Edit")
                ? <GroupEmails />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_groupEmails/:id"
            element={
              roles['Group Email'] &&
                (roles['Group Email'] === "Edit" || roles['Group Email'] === "View")
                ? <GroupEmails />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/news"
            element={
              roles['Newsletter'] &&
                (roles['Newsletter'] === "Edit" || roles['Newsletter'] === "View")
                ? <News />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_news"
            element={
              roles['Newsletter'] &&
                (roles['Newsletter'] === "Edit")
                ? <News />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_news/:id"
            element={
              roles['Newsletter'] &&
                (roles['NeNewsletterws'] === "Edit")
                ? <News />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_news/:id"
            element={
              roles['Newsletter'] &&
                (roles['Newsletter'] === "Edit" || roles['Newsletter'] === "View")
                ? <News />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/payments"
            element={
              roles['Payments'] &&
                (roles['Payments'] === "Edit" || roles['Payments'] === "View")
                ? <Payments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_payments"
            element={
              roles['Newsletter'] &&
                (roles['Newsletter'] === "Edit")
                ? <Payments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_payments/:id"
            element={
              roles['Payments'] &&
                (roles['Payments'] === "Edit")
                ? <Payments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_payments/:id"
            element={
              roles['Payments'] &&
                (roles['Payments'] === "Edit" || roles['Payments'] === "View")
                ? <Payments />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/events"
            element={
              roles['Events'] &&
                (roles['Events'] === "Edit" || roles['Events'] === "View")
                ? <Events />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_events"
            element={
              roles['Events'] &&
                (roles['Events'] === "Edit")
                ? <Events />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_events/:id"
            element={
              roles['Events'] &&
                (roles['Events'] === "Edit")
                ? <Events />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_events/:id"
            element={
              roles['Events'] &&
                (roles['Events'] === "Edit" || roles['Events'] === "View")
                ? <Events />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/stores"
            element={
              roles['Store'] &&
                (roles['Store'] === "Edit" || roles['Store'] === "View")
                ? <Stores />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_stores"
            element={
              roles['Store'] &&
                (roles['Store'] === "Edit")
                ? <Stores />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_stores/:id"
            element={
              roles['Store'] &&
                (roles['Store'] === "Edit")
                ? <Stores />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_stores/:id"
            element={
              roles['Store'] &&
                (roles['Store'] === "Edit" || roles['Store'] === "View")
                ? <Stores />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/orders"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit" || roles['Orders'] === "View")
                ? <Orders />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_orders"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit")
                ? <Orders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_orders/:id"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit")
                ? <Orders />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_orders/:id"
            element={
              roles['Orders'] &&
                (roles['Orders'] === "Edit" || roles['Orders'] === "View")
                ? <Orders />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/products"
            element={
              roles['Products'] &&
                (roles['Products'] === "Edit" || roles['Products'] === "View")
                ? <Products />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_products"
            element={
              roles['Products'] &&
                (roles['Products'] === "Edit")
                ? <Products />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_products/:id"
            element={
              roles['Products'] &&
                (roles['Products'] === "Edit")
                ? <Products />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_products/:id"
            element={
              roles['Products'] &&
                (roles['Products'] === "Edit" || roles['Products'] === "View")
                ? <Products />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/orderSummaryReports"
            element={
              roles['Orders Summary Report'] &&
                (roles['Orders Summary Report'] === "Edit" || roles['Orders Summary Report'] === "View")
                ? <OrderSummaryReports />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_orderSummaryReports"
            element={
              roles['Orders Summary Report'] &&
                (roles['Orders Summary Report'] === "Edit")
                ? <OrderSummaryReports />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_orderSummaryReports/:id"
            element={
              roles['Orders Summary Report'] &&
                (roles['Orders Summary Report'] === "Edit")
                ? <OrderSummaryReports />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_orderSummaryReports/:id"
            element={
              roles['Orders Summary Report'] &&
                (roles['Orders Summary Report'] === "Edit" || roles['Orders Summary Report'] === "View")
                ? <OrderSummaryReports />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/refundDetails"
            element={
              roles['Refund Details'] &&
                (roles['Refund Details'] === "Edit" || roles['Refund Details'] === "View")
                ? <RefundDetails />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_refundDetails"
            element={
              roles['Refund Details'] &&
                (roles['Refund Details'] === "Edit")
                ? <RefundDetails />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_refundDetails/:id"
            element={
              roles['Refund Details'] &&
                (roles['Refund Details'] === "Edit")
                ? <RefundDetails />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_refundDetails/:id"
            element={
              roles['Refund Details'] &&
                (roles['Refund Details'] === "Edit" || roles['Refund Details'] === "View")
                ? <RefundDetails />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/ordersByStores"
            element={
              roles['Orders By Store'] &&
                (roles['Orders By Store'] === "Edit" || roles['Orders By Store'] === "View")
                ? <OrdersByStores />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_ordersByStores"
            element={
              roles['Orders By Store'] &&
                (roles['Orders By Store'] === "Edit")
                ? <OrdersByStores />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_ordersByStores/:id"
            element={
              roles['Orders By Store'] &&
                (roles['Orders By Store'] === "Edit")
                ? <OrdersByStores />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_ordersByStores/:id"
            element={
              roles['Orders By Store'] &&
                (roles['Orders By Store'] === "Edit" || roles['Orders By Store'] === "View")
                ? <OrdersByStores />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/ordersByProducts"
            element={
              roles['Orders By Products'] &&
                (roles['Orders By Products'] === "Edit" || roles['Orders By Products'] === "View")
                ? <OrdersByProducts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_ordersByProducts"
            element={
              roles['Orders By Products'] &&
                (roles['Orders By Products'] === "Edit")
                ? <OrdersByProducts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_ordersByProducts/:id"
            element={
              roles['Orders By Products'] &&
                (roles['Orders By Products'] === "Edit")
                ? <OrdersByProducts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_ordersByProducts/:id"
            element={
              roles['Orders By Products'] &&
                (roles['Orders By Products'] === "Edit" || roles['Orders By Products'] === "View")
                ? <OrdersByProducts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/ordersByStoreAndProducts"
            element={
              roles['Orders By Store And Products'] &&
                (roles['Orders By Store And Products'] === "Edit" || roles['Orders By Store And Products'] === "View")
                ? <OrdersByStoreAndProducts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_ordersByStoreAndProducts"
            element={
              roles['Orders By Store And Products'] &&
                (roles['Orders By Store And Products'] === "Edit")
                ? <OrdersByStoreAndProducts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_ordersByStoreAndProducts/:id"
            element={
              roles['Orders By Store And Products'] &&
                (roles['Orders By Store And Products'] === "Edit")
                ? <OrdersByStoreAndProducts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_ordersByStoreAndProducts/:id"
            element={
              roles['Orders By Store And Products'] &&
                (roles['Orders By Store And Products'] === "Edit" || roles['Orders By Store And Products'] === "View")
                ? <OrdersByStoreAndProducts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/ecommercePayments"
            element={
              roles['Ecommerce Payments'] &&
                (roles['Ecommerce Payments'] === "Edit" || roles['Ecommerce Payments'] === "View")
                ? <EcommercePayments />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_ecommercePayments"
            element={
              roles['Ecommerce Payments'] &&
                (roles['Ecommerce Payments'] === "Edit")
                ? <EcommercePayments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_ecommercePayments/:id"
            element={
              roles['Ecommerce Payments'] &&
                (roles['Ecommerce Payments'] === "Edit")
                ? <EcommercePayments />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_ecommercePayments/:id"
            element={
              roles['Ecommerce Payments'] &&
                (roles['Ecommerce Payments'] === "Edit" || roles['Ecommerce Payments'] === "View")
                ? <EcommercePayments />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/ordersSummaryByGrades"
            element={
              roles['Orders Summary By Grade'] &&
                (roles['Orders Summary By Grade'] === "Edit" || roles['Orders Summary By Grade'] === "View")
                ? <OrdersSummaryByGrades />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_ordersSummaryByGrades"
            element={
              roles['Orders Summary By Grade'] &&
                (roles['Orders Summary By Grade'] === "Edit")
                ? <OrdersSummaryByGrades />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_ordersSummaryByGrades/:id"
            element={
              roles['Orders Summary By Grade'] &&
                (roles['Orders Summary By Grade'] === "Edit")
                ? <OrdersSummaryByGrades />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_ordersSummaryByGrades/:id"
            element={
              roles['Orders Summary By Grade'] &&
                (roles['Orders Summary By Grade'] === "Edit" || roles['Orders Summary By Grade'] === "View")
                ? <OrdersSummaryByGrades />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/ordersSummaryByClasses"
            element={
              roles['Orders Summary By Class'] &&
                (roles['Orders Summary By Class'] === "Edit" || roles['Orders Summary By Class'] === "View")
                ? <OrdersSummaryByClasses />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_ordersSummaryByClasses"
            element={
              roles['Orders Summary By Class'] &&
                (roles['Orders Summary By Class'] === "Edit")
                ? <OrdersSummaryByClasses />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_ordersSummaryByClasses/:id"
            element={
              roles['Orders Summary By Class'] &&
                (roles['Orders Summary By Class'] === "Edit")
                ? <OrdersSummaryByClasses />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_ordersSummaryByClasses/:id"
            element={
              roles['Orders Summary By Class'] &&
                (roles['Orders Summary By Class'] === "Edit" || roles['Orders Summary By Class'] === "View")
                ? <OrdersSummaryByClasses />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/familyData"
            element={
              roles['Family Data'] &&
                (roles['Family Data'] === "Edit" || roles['Family Data'] === "View")
                ? <FamilyData />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_familyData"
            element={
              roles['Family Data'] &&
                (roles['Family Data'] === "Edit")
                ? <FamilyData />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_familyData/:id"
            element={
              roles['Family Data'] &&
                (roles['Family Data'] === "Edit")
                ? <FamilyData />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_familyData/:id"
            element={
              roles['Family Data'] &&
                (roles['Family Data'] === "Edit" || roles['Family Data'] === "View")
                ? <FamilyData />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/registeredFamilies"
            element={
              roles['Registered'] &&
                (roles['Registered'] === "Edit" || roles['Registered'] === "View")
                ? <RegisteredFamilies />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_registeredFamilies"
            element={
              roles['Registered'] &&
                (roles['Registered'] === "Edit")
                ? <RegisteredFamilies />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_registeredFamilies/:id"
            element={
              roles['Registered'] &&
                (roles['Registered'] === "Edit")
                ? <RegisteredFamilies />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_registeredFamilies/:id"
            element={
              roles['Registered'] &&
                (roles['Registered'] === "Edit" || roles['Registered'] === "View")
                ? <RegisteredFamilies />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/lastLogins"
            element={
              roles['Last Logins'] &&
                (roles['Last Logins'] === "Edit" || roles['Last Logins'] === "View")
                ? <LastLogins />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_lastLogins"
            element={
              roles['Last Logins'] &&
                (roles['Last Logins'] === "Edit")
                ? <LastLogins />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_lastLogins/:id"
            element={
              roles['Last Logins'] &&
                (roles['Last Logins'] === "Edit")
                ? <LastLogins />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_lastLogins/:id"
            element={
              roles['Last Logins'] &&
                (roles['Last Logins'] === "Edit" || roles['Last Logins'] === "View")
                ? <LastLogins />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/auditLogs"
            element={
              roles['Audit Logs'] &&
                (roles['Audit Logs'] === "Edit" || roles['Audit Logs'] === "View")
                ? <AuditLogs />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_auditLogs"
            element={
              roles['Audit Logs'] &&
                (roles['Audit Logs'] === "Edit")
                ? <AuditLogs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_auditLogs/:id"
            element={
              roles['Audit Logs'] &&
                (roles['Audit Logs'] === "Edit")
                ? <AuditLogs />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_auditLogs/:id"
            element={
              roles['Audit Logs'] &&
                (roles['Audit Logs'] === "Edit" || roles['Audit Logs'] === "View")
                ? <AuditLogs />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/children"
            element={
              roles['Children'] &&
                (roles['Children'] === "Edit" || roles['Children'] === "View")
                ? <Children />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_children"
            element={
              roles['Children'] &&
                (roles['Children'] === "Edit")
                ? <Children />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_children/:id"
            element={
              roles['Children'] &&
                (roles['Children'] === "Edit")
                ? <Children />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_children/:id"
            element={
              roles['Children'] &&
                (roles['Children'] === "Edit" || roles['Children'] === "View")
                ? <Children />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/vAndDMembers"
            element={
              roles['View And Delete Members'] &&
                (roles['View And Delete Members'] === "Edit" || roles['View And Delete Members'] === "View")
                ? <VAndDMembers />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_vAndDMembers"
            element={
              roles['View And Delete Members'] &&
                (roles['View And Delete Members'] === "Edit")
                ? <VAndDMembers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_vAndDMembers/:id"
            element={
              roles['View And Delete Members'] &&
                (roles['View And Delete Members'] === "Edit")
                ? <VAndDMembers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_vAndDMembers/:id"
            element={
              roles['View And Delete Members'] &&
                (roles['View And Delete Members'] === "Edit" || roles['View And Delete Members'] === "View")
                ? <VAndDMembers />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/carts"
            element={
              roles['Carts'] &&
                (roles['Carts'] === "Edit" || roles['Carts'] === "View")
                ? <Carts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_carts"
            element={
              roles['Carts'] &&
                (roles['Carts'] === "Edit")
                ? <Carts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_carts/:id"
            element={
              roles['Carts'] &&
                (roles['Carts'] === "Edit")
                ? <Carts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_carts/:id"
            element={
              roles['Carts'] &&
                (roles['Carts'] === "Edit" || roles['Carts'] === "View")
                ? <Carts />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/advertisements"
            element={
              roles['Advertisements'] &&
                (roles['Advertisements'] === "Edit" || roles['Advertisements'] === "View")
                ? <Advertisements />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_advertisements"
            element={
              roles['Advertisements'] &&
                (roles['Advertisements'] === "Edit")
                ? <Advertisements />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_advertisements/:id"
            element={
              roles['Advertisements'] &&
                (roles['Advertisements'] === "Edit")
                ? <Advertisements />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_advertisements/:id"
            element={
              roles['Advertisements'] &&
                (roles['Advertisements'] === "Edit" || roles['Advertisements'] === "View")
                ? <Advertisements />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/families"
            element={
              roles['Family'] &&
                (roles['Family'] === "Edit" || roles['Family'] === "View")
                ? <Families />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_families"
            element={
              roles['Family'] &&
                (roles['Family'] === "Edit")
                ? <Families />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_families/:id"
            element={
              roles['Family'] &&
                (roles['Family'] === "Edit")
                ? <Families />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_families/:id"
            element={
              roles['Family'] &&
                (roles['Family'] === "Edit" || roles['Family'] === "View")
                ? <Families />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/students"
            element={
              roles['Students'] &&
                (roles['Students'] === "Edit" || roles['Students'] === "View")
                ? <Students />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_students"
            element={
              roles['Students'] &&
                (roles['Students'] === "Edit")
                ? <Students />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_students/:id"
            element={
              roles['Students'] &&
                (roles['Students'] === "Edit")
                ? <Students />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_students/:id"
            element={
              roles['Students'] &&
                (roles['Students'] === "Edit" || roles['Students'] === "View")
                ? <Students />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/committees"
            element={
              roles['Committees'] &&
                (roles['Committees'] === "Edit" || roles['Committees'] === "View")
                ? <Committees />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_committees"
            element={
              roles['Committees'] &&
                (roles['Committees'] === "Edit")
                ? <Committees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_committees/:id"
            element={
              roles['Committees'] &&
                (roles['Committees'] === "Edit")
                ? <Committees />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_committees/:id"
            element={
              roles['Committees'] &&
                (roles['Committees'] === "Edit" || roles['Committees'] === "View")
                ? <Committees />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/eventsignups"
            element={
              roles['Eventsignups'] &&
                (roles['Eventsignups'] === "Edit" || roles['Eventsignups'] === "View")
                ? <Eventsignups />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_eventsignups"
            element={
              roles['Eventsignups'] &&
                (roles['Eventsignups'] === "Edit")
                ? <Eventsignups />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_eventsignups/:id"
            element={
              roles['Eventsignups'] &&
                (roles['Eventsignups'] === "Edit")
                ? <Eventsignups />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_eventsignups/:id"
            element={
              roles['Eventsignups'] &&
                (roles['Eventsignups'] === "Edit" || roles['Eventsignups'] === "View")
                ? <Eventsignups />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/members"
            element={
              roles['Members'] &&
                (roles['Members'] === "Edit" || roles['Members'] === "View")
                ? <Members />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_members"
            element={
              roles['Members'] &&
                (roles['Members'] === "Edit")
                ? <Members />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_members/:id"
            element={
              roles['Members'] &&
                (roles['Members'] === "Edit")
                ? <Members />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_members/:id"
            element={
              roles['Members'] &&
                (roles['Members'] === "Edit" || roles['Members'] === "View")
                ? <Members />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/paymentIntents"
            element={
              roles['PaymentIntents'] &&
                (roles['PaymentIntents'] === "Edit" || roles['PaymentIntents'] === "View")
                ? <PaymentIntents />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_paymentIntents"
            element={
              roles['PaymentIntents'] &&
                (roles['PaymentIntents'] === "Edit")
                ? <PaymentIntents />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_paymentIntents/:id"
            element={
              roles['PaymentIntents'] &&
                (roles['PaymentIntents'] === "Edit")
                ? <PaymentIntents />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_paymentIntents/:id"
            element={
              roles['PaymentIntents'] &&
                (roles['PaymentIntents'] === "Edit" || roles['PaymentIntents'] === "View")
                ? <PaymentIntents />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/reports"
            element={
              roles['Reports'] &&
                (roles['Reports'] === "Edit" || roles['Reports'] === "View")
                ? <Reports />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_reports"
            element={
              roles['Reports'] &&
                (roles['Reports'] === "Edit")
                ? <Reports />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_reports/:id"
            element={
              roles['Reports'] &&
                (roles['Reports'] === "Edit")
                ? <Reports />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_reports/:id"
            element={
              roles['Reports'] &&
                (roles['Reports'] === "Edit" || roles['Reports'] === "View")
                ? <Reports />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/states"
            element={
              roles['States'] &&
                (roles['States'] === "Edit" || roles['States'] === "View")
                ? <States />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_states"
            element={
              roles['States'] &&
                (roles['States'] === "Edit")
                ? <States />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_states/:id"
            element={
              roles['States'] &&
                (roles['States'] === "Edit")
                ? <States />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_states/:id"
            element={
              roles['States'] &&
                (roles['States'] === "Edit" || roles['States'] === "View")
                ? <States />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/volunteers"
            element={
              roles['Volunteers'] &&
                (roles['Volunteers'] === "Edit" || roles['Volunteers'] === "View")
                ? <Volunteers />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_volunteers"
            element={
              roles['Volunteers'] &&
                (roles['Volunteers'] === "Edit")
                ? <Volunteers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_volunteers/:id"
            element={
              roles['Volunteers'] &&
                (roles['Volunteers'] === "Edit")
                ? <Volunteers />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_volunteers/:id"
            element={
              roles['Volunteers'] &&
                (roles['Volunteers'] === "Edit" || roles['Volunteers'] === "View")
                ? <Volunteers />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/wishlists"
            element={
              roles['Wishlists'] &&
                (roles['Wishlists'] === "Edit" || roles['Wishlists'] === "View")
                ? <Wishlists />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_wishlists"
            element={
              roles['Wishlists'] &&
                (roles['Wishlists'] === "Edit")
                ? <Wishlists />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_wishlists/:id"
            element={
              roles['Wishlists'] &&
                (roles['Wishlists'] === "Edit")
                ? <Wishlists />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_wishlists/:id"
            element={
              roles['Wishlists'] &&
                (roles['Wishlists'] === "Edit" || roles['Wishlists'] === "View")
                ? <Wishlists />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/zipcodes"
            element={
              roles['Zipcodes'] &&
                (roles['Zipcodes'] === "Edit" || roles['Zipcodes'] === "View")
                ? <Zipcodes />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_zipcodes"
            element={
              roles['Zipcodes'] &&
                (roles['Zipcodes'] === "Edit")
                ? <Zipcodes />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_zipcodes/:id"
            element={
              roles['Zipcodes'] &&
                (roles['Zipcodes'] === "Edit")
                ? <Zipcodes />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_zipcodes/:id"
            element={
              roles['Zipcodes'] &&
                (roles['Zipcodes'] === "Edit" || roles['Zipcodes'] === "View")
                ? <Zipcodes />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/users"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit" || roles['Users'] === "View")
                ? <Users />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_users"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit")
                ? <Users />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_users/:id"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit")
                ? <Users />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_users/:id"
            element={
              roles['Users'] &&
                (roles['Users'] === "Edit" || roles['Users'] === "View")
                ? <Users />
                : <ErrorNotFound />
            }
          />
          <Route exact
            path="/pendingCarts"
            element={
              roles['Pending Carts'] &&
                (roles['Pending Carts'] === "Edit" || roles['Pending Carts'] === "View")
                ? <PendingCarts />
                : <ErrorNotFound />
            } />

          <Route
            path="/create_pendingCarts"
            element={
              roles['Pending Carts'] &&
                (roles['Pending Carts'] === "Edit")
                ? <PendingCarts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/edit_pendingCarts/:id"
            element={
              roles['Pending Carts'] &&
                (roles['Pending Carts'] === "Edit")
                ? <PendingCarts />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/view_pendingCarts/:id"
            element={
              roles['Pending Carts'] &&
                (roles['Pending Carts'] === "Edit" || roles['Pending Carts'] === "View")
                ? <PendingCarts />
                : <ErrorNotFound />
            }
          />

          {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

          {/* <Route path="/settings" element={Settings} /> */}
          {/* <Route path="/adminSettings" element={AdminSettings} /> */}
          <Route
            path="/adminSettings"
            element={
              config.displaySettings
                ? <AdminSettings />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/uploadHistories"
            element={
              roles["Uploads"] &&
                (roles["Uploads"] === "Edit" || roles["Uploads"] === "View")
                ? <Uploads />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/activities"
            element={
              roles["Activities"] &&
                (roles["Activities"] === "Edit" || roles["Activities"] === "View")
                ? <Activities />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/roles"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit" || roles["Roles"] === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_roles"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_roles/:id"
            element={
              roles["Roles"] &&
                (roles["Roles"] === "Edit" || roles["Roles"] === "View")
                ? <Roles />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/emailStatuses"
            element={
              roles["Email History"] &&
                (roles["Email History"] === "Edit" || roles["Email History"] === "View")
                ? <EmailStatus />
                : <ErrorNotFound />
            }
          />

          <Route
            path="/templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/create_templates"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/edit_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          <Route
            path="/view_templates/:id"
            element={
              roles["Email Templates"] &&
                (roles["Email Templates"] === "Edit" || roles["Email Templates"] === "View")
                ? <EmailTemplate />
                : <ErrorNotFound />
            }
          />
          {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
        </Routes >
        <Outlet />
      </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />

  </Routes>

);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/dashBoard" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/two_step_verification" element={<Verification />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />} />

        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
