import React from "react";
import { Container } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import InputField from "./InputField";
import PasswordField from "./PasswordField";
import DoDateField from "./DoDateField";
import DoSelectField from "./DoSelectField";
import DoMultiSelectField from "./DoMultiSelectField";
import DoTextareaField from "./DoTextareaField";
import DoCheckboxField from "./DoCheckboxField";
import './SampleForm.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const SampleForm = () => {

    const schema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
    });

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (formData) => {
        console.log(formData);
        alert(JSON.stringify(formData));
    }

    const cities = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

    return (
        <Container>
            <div className="form-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">

                        <div className="col-6">
                            <Controller
                                name="email"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <InputField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        type="email"
                                        fieldState={fieldState}
                                        errors={errors}
                                        placeholder="Email"
                                    />)}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="password"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <PasswordField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        placeholder="Password"
                                    />)}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="date"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoDateField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        placeholder="date"
                                    />)}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="cities"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoSelectField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Select Cities"
                                    />)}
                            />
                        </div>
                        <div className="col-6">
                            <Controller
                                name="citiesMulti"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoMultiSelectField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Select Cities"
                                    />)}
                            />
                        </div>
                        {/* <div className="col-6">
                            <Controller
                                name="Checked"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoCheckboxField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        placeholder="About.."
                                    />)}
                            />

                        </div> */}
                        <div className="col-6">
                            <Controller
                                name="about"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <DoTextareaField
                                        input={field}
                                        id={field.id}
                                        name={field.name}
                                        field={field}
                                        fieldState={fieldState}
                                        errors={errors}
                                        placeholder="About.."
                                    />)}
                            />
                        </div>
                        <div className="col-6 mt-2">
                            <Button type="submit" label="Submit" />
                        </div>
                    </div>
                </form>

            </div>
        </Container>
    )
}

export default SampleForm;