import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Users = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Users");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: false,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 40,
      //   field: "Sno",
      //   header: "Sno",
      //   filter: true,
      //   sortable: false,
      //   placeholder: "Search",
      // },
      {
        show: true,
        textAlign: "left",
        width: 220,
        mobile: false,
        field: "email",
        header: "Email",
        filter: true,
        sortable: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "fname",
        header: "Parent First Name",
        filter: true,
        sortable: true,
        displayInSettings: true,
        capitalizeTableText: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "lname",
        header: "Parent Last Name",
        filter: true,
        sortable: true,
        displayInSettings: true,
        capitalizeTableText: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "display_name",
        header: "Parent Name",
        filter: true,
        sortable: true,
        displayInSettings: true,
        capitalizeTableText: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "city",
        header: "City",
        filter: true,
        sortable: true,
        displayInSettings: true,
        capitalizeTableText: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "district",
        header: "District",
        filter: true,
        sortable: true,
        displayInSettings: true,
        capitalizeTableText: true,

      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 120,
        field: "state",
        header: "State",
        filter: true,
        sortable: true,
        displayInSettings: true,
        capitalizeTableText: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 120,
        field: "zip",
        header: "Zip",
        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 120,
        field: "authentication_type",
        header: "Login Via",
        textCapitalize: true,
        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 180,
        field: "last_login",
        header: "Last Login Date",
        dateFormat: "MM-DD-YYYY hh:mm a",
        fieldType: "Date",
        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 160,
        field: "pwd_created",
        header: "Password Created",
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        mobile: true,
        width: 160,
        field: "failures",
        header: "Failed login #",
        filter: true,
        sortable: true,
        displayInSettings: true,
      },

      {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 160,
        field: "pwd_updated",
        header: "Password Updated",
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 160,
        mobile: false,
        field: "payment_status",
        fieldType: "BoldText",
        header: "Payment Status",
        filter: true,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      }, {
        show: true,
        textAlign: "center",
        mobile: true,
        width: 160,
        field: "workphone",
        header: "Mobile Work",

        filter: true,
        sortable: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 160,
        mobile: false,
        field: "homephone",
        // fieldType: "BoldText",
        header: "Mobile Home",
        filter: true,
        sortable: true,
        textCapitalize: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "left",
        width: 100,
        field: "status",
        mobile: true,
        header: "Status",
        fieldType: "Badge",
        options: config.tabfieldsStatusTypes,
        filter: true,
        sortable: true,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        mobile: false,
        field: "role",
        fieldType: "Role",
        header: "Role",
        filter: true,
        sortable: true,
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 150,
        mobile: false,
        field: "isOtpVerified",
        header: "OTP Verified",
        filter: true,
        sortable: true,
        fieldType: "Badge",
        style: {
          padding: "4px 6px",
          fontSize: 12,
          color: "white",
          textTransform: "capitalize",
          fontWeight: "bold",
        },
        displayInSettings: true,
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 100,
      //   "field": "Actions",
      //   "fieldType": "Actions",
      //   "header": "Actions",
      //   "filter": false,
      //   "sortable": false
      // }
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "family",
        fieldName: "family",
        type: "text",
        placeholder: "Family",
        label: "Family",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "family=family=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "family",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "parent1",
        type: "dropDown",
        placeholder: "Parent1 Type",
        label: "Parent1 Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "parent1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "parent1",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p1_suffix",
        type: "text",
        placeholder: "P1_suffix",
        label: "P1_suffix",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "p1_suffix=undefined",
        sortable: true,
        filter: true,
        id: "p1_suffix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "p1_firstname",
        type: "text",
        placeholder: "Parent1 First Name",
        label: "Parent1 First Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "p1_firstname=p1_firstname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p1_firstname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p1_lastname",
        type: "text",
        placeholder: "Parent1 Last Name",
        label: "Parent1 Last Name",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "p1_lastname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p1_lastname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p1_middlename",
        type: "textarea",
        placeholder: "Parent1 Middle Name",
        label: "Parent1 Middle Name",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "p1_middlename=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p1_middlename",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "parent2",
        type: "dropDown",
        placeholder: "Parent2 Type",
        label: "Parent2 Type",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "parent2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "parent2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p2_suffix",
        type: "text",
        placeholder: "Parent2 Suffix",
        value: "2",
        label: "Parent2 Suffix",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "p2_suffix=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_suffix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p2_firstname",
        type: "text",
        placeholder: "Parent2 First Name",
        label: "Parent2 First Name",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "p2_firstname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_firstname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "p2_firstname",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p2_lastname",
        type: "text",
        placeholder: "Parent2 Last Name",
        label: "Parent2 Last Name",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "p2_lastname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_lastname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "p2_middlename",
        type: "text",
        placeholder: "P2_middlename",
        label: "P2_middlename",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "p2_middlename=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_middlename",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address1",
        type: "textarea",
        placeholder: "Address Line 1",
        label: "Address Line 1",
        width: "200px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "address1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address1",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address2",
        type: "textarea",
        placeholder: "Address Line 2",
        label: "Address Line 2",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "city",
        type: "textarea",
        placeholder: "City",
        label: "City",
        width: "200px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email1",
        type: "email",
        placeholder: "Parent1 Email",
        label: "Parent1 Email",
        width: "150px",
        addFormOrder: 21,
        editFormOrder: 21,
        derivedValue: "email1=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email1",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone1_home",
        type: "number",
        placeholder: "Parent1 Home Phone",
        label: "Parent1 Home Phone",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        derivedValue: "phone1_home=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone1_home",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone1_mobile",
        type: "number",
        placeholder: "Parent1 Mobile Phone",
        label: "Parent1 Mobile Phone",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        derivedValue: "phone1_mobile=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone1_mobile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone1_work",
        type: "number",
        placeholder: "Parent1 Work Phone",
        label: "Parent1 Work Phone",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        derivedValue: "phone1_work=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone1_work",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "parent2",
        type: "email",
        placeholder: "Parent2 Email",
        label: "Parent2 Email",
        width: "150px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "parent2=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "parent2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone2_home",
        type: "number",
        placeholder: "Parent2 Home Phone",
        label: "Parent2 Home Phone",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 26,
        derivedValue: "phone2_home=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone2_home",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone2_mobile",
        type: "number",
        placeholder: "Parent2 Mobile Phone",
        label: "Parent2 Mobile Phone",
        width: "120px",
        addFormOrder: 27,
        editFormOrder: 27,
        derivedValue: "phone2_mobile=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone2_mobile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phone2_work",
        type: "number",
        placeholder: "Parent2 Work Phone",
        label: "Parent2 Work Phone",
        width: "120px",
        addFormOrder: 28,
        editFormOrder: 28,
        derivedValue: "phone2_work=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone2_work",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "birthday",
        type: "date",
        placeholder: "Birth Date",
        label: "Birth Date",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 29,
        derivedValue: "birthday=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "birthday",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss ",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "class_name",
        type: "relateAutoComplete",
        placeholder: "Class Name",
        label: "Class Name",
        width: "150px",
        addFormOrder: 30,
        editFormOrder: 30,
        derivedValue: "class_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1013,
        searchField: "class_name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["class_name"],
        controllerName: "Class Room",
        searchApi: "classRooms",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "district_name",
        type: "relateAutoComplete",
        placeholder: "District Name",
        label: "District Name",
        width: "150px",
        addFormOrder: 31,
        editFormOrder: 31,
        derivedValue: "district_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "District",
        searchApi: "districts",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "school_name",
        type: "relateAutoComplete",
        placeholder: "School Name",
        label: "School Name",
        width: "150px",
        addFormOrder: 32,
        editFormOrder: 32,
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "student",
        type: "dropDown",
        placeholder: "Student Type",
        label: "Student Type",
        width: "130px",
        addFormOrder: 33,
        editFormOrder: 33,
        derivedValue: "student=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "student_firstname",
        type: "text",
        placeholder: "Student First Name",
        label: "Student First Name",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 34,
        derivedValue: "student_firstname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_firstname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "student_lastname",
        type: "text",
        placeholder: "Student Last Name",
        label: "Student Last Name",
        width: "130px",
        addFormOrder: 35,
        editFormOrder: 35,
        derivedValue: "student_lastname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_lastname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "student_vaccination",
        type: "dropDown",
        placeholder: "Vaccination",
        label: "Vaccination",
        width: "130px",
        addFormOrder: 36,
        editFormOrder: 36,
        derivedValue: "student_vaccination=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_vaccination",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "student_nickname",
        type: "textarea",
        placeholder: "Student Nick Name",
        label: "Student Nick Name",
        width: "200px",
        addFormOrder: 37,
        editFormOrder: 37,
        derivedValue: "student_nickname=student_nickname=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_nickname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "student_suffix",
        type: "textarea",
        placeholder: "Student Suffix",
        label: "Student Suffix",
        width: "200px",
        addFormOrder: 38,
        editFormOrder: 38,
        derivedValue: "student_suffix=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_suffix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "home_room",
        type: "textarea",
        placeholder: "Home Room",
        label: "Home Room",
        width: "200px",
        addFormOrder: 39,
        editFormOrder: 39,
        derivedValue: "home_room=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "home_room",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: true,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "family",
        fieldName: "family",
        type: "text",
        placeholder: "Family",
        label: "Family",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Family",
        derivedValue: "family=family=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "family",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        textAlign: "center",
        show: true,
        field: "family",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "parent1",
        type: "dropDown",
        placeholder: "Parent1 Type",
        label: "Parent1 Type",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Parent1 Type",
        derivedValue: "parent1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "parent1",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "parent1",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p1_suffix",
        type: "text",
        placeholder: "P1_suffix",
        label: "P1_suffix",
        width: 110,
        addFormOrder: 2,
        editFormOrder: 2,
        header: "P1_suffix",
        derivedValue: "p1_suffix=undefined",
        sortable: true,
        filter: true,
        id: "p1_suffix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p1_suffix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p1_firstname",
        type: "text",
        placeholder: "Parent1 First Name",
        label: "Parent1 First Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Parent1 First Name",
        derivedValue: "p1_firstname=p1_firstname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p1_firstname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p1_firstname",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p1_lastname",
        type: "text",
        placeholder: "Parent1 Last Name",
        label: "Parent1 Last Name",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Parent1 Last Name",
        derivedValue: "p1_lastname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p1_lastname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p1_lastname",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p1_middlename",
        type: "textarea",
        placeholder: "Parent1 Middle Name",
        label: "Parent1 Middle Name",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Parent1 Middle Name",
        derivedValue: "p1_middlename=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p1_middlename",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p1_middlename",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "parent2",
        type: "dropDown",
        placeholder: "Parent2 Type",
        label: "Parent2 Type",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Parent2 Type",
        derivedValue: "parent2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "parent2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "parent2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p2_suffix",
        type: "text",
        placeholder: "Parent2 Suffix",
        value: "2",
        label: "Parent2 Suffix",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Parent2 Suffix",
        derivedValue: "p2_suffix=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_suffix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p2_suffix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p2_firstname",
        type: "text",
        placeholder: "Parent2 First Name",
        label: "Parent2 First Name",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Parent2 First Name",
        derivedValue: "p2_firstname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_firstname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p2_firstname",
        showOrHideFields: [],
        fieldName: "p2_firstname",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p2_lastname",
        type: "text",
        placeholder: "Parent2 Last Name",
        label: "Parent2 Last Name",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Parent2 Last Name",
        derivedValue: "p2_lastname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_lastname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p2_lastname",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "p2_middlename",
        type: "text",
        placeholder: "P2_middlename",
        label: "P2_middlename",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "P2_middlename",
        derivedValue: "p2_middlename=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "p2_middlename",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "p2_middlename",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address1",
        type: "textarea",
        placeholder: "Address Line 1",
        label: "Address Line 1",
        width: "200px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "Address Line 1",
        derivedValue: "address1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address1",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address1",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address2",
        type: "textarea",
        placeholder: "Address Line 2",
        label: "Address Line 2",
        width: "200px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Address Line 2",
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipCode",
        type: "number",
        placeholder: "Zip Code",
        label: "Zip Code",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Zip Code",
        derivedValue: "zipCode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "zipCode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipCode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "textarea",
        placeholder: "City",
        label: "City",
        width: "200px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "City",
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email1",
        type: "email",
        placeholder: "Parent1 Email",
        label: "Parent1 Email",
        width: "150px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Parent1 Email",
        derivedValue: "email1=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email1",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "email1",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone1_home",
        type: "number",
        placeholder: "Parent1 Home Phone",
        label: "Parent1 Home Phone",
        width: "120px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Parent1 Home Phone",
        derivedValue: "phone1_home=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone1_home",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone1_home",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone1_mobile",
        type: "number",
        placeholder: "Parent1 Mobile Phone",
        label: "Parent1 Mobile Phone",
        width: "120px",
        addFormOrder: 23,
        editFormOrder: 23,
        header: "Parent1 Mobile Phone",
        derivedValue: "phone1_mobile=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone1_mobile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone1_mobile",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone1_work",
        type: "number",
        placeholder: "Parent1 Work Phone",
        label: "Parent1 Work Phone",
        width: "120px",
        addFormOrder: 24,
        editFormOrder: 24,
        header: "Parent1 Work Phone",
        derivedValue: "phone1_work=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone1_work",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone1_work",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "parent2",
        type: "email",
        placeholder: "Parent2 Email",
        label: "Parent2 Email",
        width: "150px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "Parent2 Email",
        derivedValue: "parent2=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "parent2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "parent2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone2_home",
        type: "number",
        placeholder: "Parent2 Home Phone",
        label: "Parent2 Home Phone",
        width: "120px",
        addFormOrder: 26,
        editFormOrder: 26,
        header: "Parent2 Home Phone",
        derivedValue: "phone2_home=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone2_home",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone2_home",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone2_mobile",
        type: "number",
        placeholder: "Parent2 Mobile Phone",
        label: "Parent2 Mobile Phone",
        width: "120px",
        addFormOrder: 27,
        editFormOrder: 27,
        header: "Parent2 Mobile Phone",
        derivedValue: "phone2_mobile=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone2_mobile",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone2_mobile",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone2_work",
        type: "number",
        placeholder: "Parent2 Work Phone",
        label: "Parent2 Work Phone",
        width: "120px",
        addFormOrder: 28,
        editFormOrder: 28,
        header: "Parent2 Work Phone",
        derivedValue: "phone2_work=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone2_work",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone2_work",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "birthday",
        type: "date",
        placeholder: "Birth Date",
        label: "Birth Date",
        width: "130px",
        addFormOrder: 29,
        editFormOrder: 29,
        header: "Birth Date",
        derivedValue: "birthday=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "birthday",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss ",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "birthday",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "class_name",
        type: "relateAutoComplete",
        placeholder: "Class Name",
        label: "Class Name",
        width: "150px",
        addFormOrder: 30,
        editFormOrder: 30,
        header: "Class Name",
        derivedValue: "class_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "class_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1013,
        searchField: "class_name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["class_name"],
        controllerName: "Class Room",
        searchApi: "classRooms",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "class_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Class Room_class_name",
        type: "text",
        placeholder: "Class Room_class_name",
        label: "Class Room_class_name",
        header: "Class Room class_name",
        width: 110,
        parentField: "class_name",
        id: "Class Room_class_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "class_name",
        show: true,
        field: "Class Room_class_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "district_name",
        type: "relateAutoComplete",
        placeholder: "District Name",
        label: "District Name",
        width: "150px",
        addFormOrder: 31,
        editFormOrder: 31,
        header: "District Name",
        derivedValue: "district_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "district_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "District",
        searchApi: "districts",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "district_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "District_name",
        type: "text",
        placeholder: "District_name",
        label: "District_name",
        header: "District name",
        width: 110,
        parentField: "district_name",
        id: "District_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "name",
        show: true,
        field: "District_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "school_name",
        type: "relateAutoComplete",
        placeholder: "School Name",
        label: "School Name",
        width: "150px",
        addFormOrder: 32,
        editFormOrder: 32,
        header: "School Name",
        derivedValue: "school_name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "school_name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1011,
        searchField: "Name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["Name"],
        controllerName: "School",
        searchApi: "schools",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "school_name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "School_Name",
        type: "text",
        placeholder: "School_Name",
        label: "School_Name",
        header: "School Name",
        width: 110,
        parentField: "school_name",
        id: "School_Name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "Name",
        show: true,
        field: "School_Name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "student",
        type: "dropDown",
        placeholder: "Student Type",
        label: "Student Type",
        width: "130px",
        addFormOrder: 33,
        editFormOrder: 33,
        header: "Student Type",
        derivedValue: "student=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "student",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "student_firstname",
        type: "text",
        placeholder: "Student First Name",
        label: "Student First Name",
        width: "130px",
        addFormOrder: 34,
        editFormOrder: 34,
        header: "Student First Name",
        derivedValue: "student_firstname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_firstname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "student_firstname",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "student_lastname",
        type: "text",
        placeholder: "Student Last Name",
        label: "Student Last Name",
        width: "130px",
        addFormOrder: 35,
        editFormOrder: 35,
        header: "Student Last Name",
        derivedValue: "student_lastname=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_lastname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "student_lastname",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "student_vaccination",
        type: "dropDown",
        placeholder: "Vaccination",
        label: "Vaccination",
        width: "130px",
        addFormOrder: 36,
        editFormOrder: 36,
        header: "Vaccination",
        derivedValue: "student_vaccination=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_vaccination",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "student_vaccination",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "student_nickname",
        type: "textarea",
        placeholder: "Student Nick Name",
        label: "Student Nick Name",
        width: "200px",
        addFormOrder: 37,
        editFormOrder: 37,
        header: "Student Nick Name",
        derivedValue: "student_nickname=student_nickname=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_nickname",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "student_nickname",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "student_suffix",
        type: "textarea",
        placeholder: "Student Suffix",
        label: "Student Suffix",
        width: "200px",
        addFormOrder: 38,
        editFormOrder: 38,
        header: "Student Suffix",
        derivedValue: "student_suffix=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "student_suffix",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "student_suffix",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "home_room",
        type: "textarea",
        placeholder: "Home Room",
        label: "Home Room",
        width: "200px",
        addFormOrder: 39,
        editFormOrder: 39,
        header: "Home Room",
        derivedValue: "home_room=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "home_room",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "home_room",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = usersFields
  //   let objusers = {}

  //   objusers["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objusers[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objusers,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    // this.setState({
    //   isLoading: true
    // });
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.users}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={false
          }
          editRequired={false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          // globalSearch={'Display Name/Email'}
          // type='Users'
          // apiUrl={apiCalls.Users}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={[
            // {
            //   name: "Delete",
            //   options: [{ label: "Delete", value: "Delete", show: true, multiple: true }],
            // },
            {
              name: "Active",
              options: [{ label: "Active", value: "Active", show: true, multiple: true }],
            },
            {
              name: "InActive",
              options: [
                { label: "InActive", value: "Inactive", show: true, multiple: true },
              ],
            },
            {
              name: "Invite",
              options: [
                {
                  label: "Invite",
                  value: "Invite",
                  show: true,
                  multiple: true,
                },
              ],
            },
            {
              name: "ResetPassword",
              options: [
                {
                  label: "Change Password",
                  value: "ResetPassword",
                  show: true,
                  multiple: false,
                },
              ],
            },
          ]}

          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.users}
          globalSearch={"Name / Email / Zip"}
          displayName="Users"
          type="Users"
          routeTo={apiCalls.users}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.users?.toLowerCase()}
          apiUrl={apiCalls.users}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="users"
          apiUrl={apiCalls.users}
        />
      ) : null}
    </span>
  );
};

export default Users;
