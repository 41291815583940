import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import config from '../../../../config/config';
// fecth 
import fetch from '../../../../config/service';
// loader
import Loader from '../../../App/Loader';
// show message 
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { getEmailRegex } from '../../../Form/DoValidations';
import ReCAPTCHA from "react-google-recaptcha";
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
const publicIp = require('public-ip');
import { InputText } from 'primereact/inputtext';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [settingsData, setSettingsData] = useState({});
  const [captchaValue, setCaptchaValue] = useState();
  const [isLoginDisabled, setIsLoginDisabled] = useState(config.displayRecaptcha ? true : false);
  const [deviceInfo, setDeviceInfo] = useState({});
  const [email, setEmail] = useState();
  const [issubmit, setIssubmit] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const emailPattern = getEmailRegex();

  useEffect(() => {
    getSettingsData();
    let ipAddr = '';
    publicIp.v4().then((res) => {
      ipAddr = res;
    })
    setDeviceInfo({
      ipAddress: ipAddr,
      browserName: browserName,
      osName: osName,
      osVersion: osVersion,
      deviceType: deviceType
    })
    return () => {

    };
  }, []);



  //handle login user data
  const handleUserPassword = () => {
    setIsLoading(true);
    let userBody = {
      entityType: config.entityType,
      deviceInfo: deviceInfo
    }
    return fetch('POST', `auth/forgotPassword?email=${email}`, userBody)
      .then((response) => {
        if (response) {
          if (response.respCode && response.respCode === 200) {
            // display message
            showToasterMessage(response.respMessage, 'success');
          } else if (response.errorMessage) {
            // display message
            showToasterMessage(response.errorMessage, 'error');
          }
          setIsLoading(false);
        }
      }).catch((err) => {
        return err;
      });
  }
  const getSettingsData = () => {
    let settings;
    fetch('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settings = response.settings[0]
        settings["GooglesecretKey"] = settings["Admin"].GooglesecretKey;
        setSettingsData(settings);
      }
    });
  }

  const onChange = (value) => {
    if (config.displayRecaptcha) {
      setCaptchaValue(value ? value : null);
      setIsLoginDisabled(value ? false : true)
    }
  }

  const checkValidation = (value) => {
    if (!value) {
      setErrMessage('Please enter email');
      return false;
    } else if (value && !emailPattern.test(value)) {
      setErrMessage('Please enter valid email');
      return false;
    } else {
      setErrMessage('');
      return true;
    }
  }


  const submit = (e) => {
    e.preventDefault();
    if (checkValidation(email)) {
      setIssubmit(false);
      handleUserPassword();
    } else {
      setIssubmit(true);
    }
  }



  return (
    <form className="form mt-2" onSubmit={submit}  >
      <Loader loader={isLoading} />
      <div className="form__form-group">
        <span className="form__form-group-label">Email</span>
        <div className="p-inputgroup flex-grow-1">
          <span className="p-inputgroup-addon">
            <i className="pi pi-user"></i>
          </span>
          <InputText type='email' placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value); checkValidation(e.target.value) }} />
        </div>
        {(issubmit && errMessage) && <small className='text-danger'>{errMessage}</small>}
        {config.displayRecaptcha && settingsData && settingsData.GooglesecretKey ?
          < ReCAPTCHA
            sitekey={settingsData && settingsData.GooglesecretKey}
            // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
            onChange={onChange}
            type="image"
            className='recap'
          /> : null}
      </div>
      <div className='d-flex flex-column w-100 justify-content-between align-items-center'>
        <div className="mt-4">
          <Button color="primary"
            disabled={isLoginDisabled}
            size='small'
            className='forgot_Password'
            type='submit'>Submit</Button>
        </div>
        <div className="pt-3 ml-auto">
          <Link to="/log_in" className='Back_login'>Back to Login?</Link>
        </div>
      </div>
    </form>
  )
}

export default ForgotPassword;


