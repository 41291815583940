
import React, { useEffect } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoMultiSelectField = (props) => {
  const id = props.id || props.name;
  const label = props.label || props.name;
  const optionLabel = props.optionLabel;
  const name = props.name;
  const options = props.options || [];
  const placeholder = props.placeholder;
  const maxSelectedLabels = props.maxSelectedLabels;
  const field = props.field || {}; // Provide a default empty object if field is not provided

  const markRequired = () => (
    <FontAwesomeIcon
      color='red'
      icon={faAsterisk}
      style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
    />
  );

  useEffect(() => {
    if (field && field.value !== undefined) {
      onChange({ value: field.value });
    }
  }, [field.value]);

  const onChange = (e) => {
    if (props.screenName === 'Districts' && props.item) {
      props.handleTexfields(e, props.item);
    } else if (props.screenName === 'Schools' && props.item && props.item.dependentTextField && props.item.name === "pta_types") {
      props.handlePtaDues(e, props.item);
    } else if (props.onChange) {
      props.onChange(e.value)
    }
  };
  return (
    <div className="flex flex-column">
      <div className="flex align-center">
        <label htmlFor={id} className="text-capitalize">{label}</label>
        {props.markReq && markRequired()}
      </div>
      <MultiSelect
        id={id}
        name={name}
        value={field.value || []}
        options={options}
        onChange={(e) => field.onChange && field.onChange(e.value)}
        optionLabel={optionLabel}
        placeholder={placeholder}
        maxSelectedLabels={maxSelectedLabels}
        filter={props.filter}
      />
      <small className="text-danger">{props.fieldState?.invalid ? props.errors[props.name]?.message : ''}</small>
    </div>
  );
};

export default DoMultiSelectField;
